import React, { Component } from "react";
import { Grid, Typography, Button, IconButton, TextField, Dialog, MuiThemeProvider } from "@material-ui/core";
import OutlinedSelect from "../Gui/OutlinedSelect";
import ClearIcon from '@material-ui/icons/Clear';
import ConfigurationDialog from "./ConfigurationDialog";
import { MontserratBoldTheme } from "../../themes/style";
import { withTranslation } from 'react-i18next';

class BeaconsActionsAndRules extends Component{

    constructor(){
        super();
        this.state = {
            ind: null,
            open: false,
            whData: null
        }
    }

    beaconChange = (e, i) => {
        this.props.onBeaconChange(e, i);
    };

    openWebhookDialog = (e, i, data) => {
        this.setState({ind: i, whData: data, open:true});
    };

    closeWebhookDialog = () => {
        this.setState({
            open:false, ind:null, whData:null
        })
    };

    submitWebhook = (ind, data) => {
        try{
            this.props.onWebhookChange(ind,data);
        }catch(e){
            console.log("Error during configurations");
            console.log(e);
        }
        this.closeWebhookDialog();
    }

    render(){
        const { t } = this.props
        return(
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                        <Grid item xs={1}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <MuiThemeProvider theme={MontserratBoldTheme}>
                                    <Typography variant="h6" color="secondary">IF</Typography>
                                </MuiThemeProvider>
                            </Grid>
                        </Grid>
                        {/* Beacon */}
                        <Grid item xs={4}>
                            <OutlinedSelect data={this.props.beacons} values={this.props.chosenBeacons} onChange={e => this.beaconChange(e, 0)} label="Beacon" labelWidth={60} value={this.props.chosenBeacons[0]} />
                        </Grid>
                        {/* Actions */}
                        <Grid item xs={7}>
                            {this.props.type === "geofencing" ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <OutlinedSelect data={this.props.geoTriggerData} onChange={this.props.onTriggerChange} label="Trigger" labelWidth={60} value={this.props.trigger} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <OutlinedSelect data={this.props.zones} onChange={this.props.onZoneChange} label="Zone" labelWidth={40} value={this.props.zoneId} />
                                    </Grid>
                                </Grid>
                            ): (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <OutlinedSelect data={this.props.tempTriggerData} label="Action" onChange={this.props.onTriggerChange} labelWidth={40} value={this.props.trigger} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField onChange={this.props.onValueChange} type="number" variant="outlined" label="Val" value={this.props.value}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <OutlinedSelect data={this.props.unitData} label="Unit" onChange={this.props.onUnitChange} labelWidth={35} value={this.props.unit} />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        
                        {/* Beacon arrays */}
                        {this.props.chosenBeacons.map((b, i) =>
                            {
                                if(i !== 0) {
                                    return (
                                        <Grid item xs={12} key={i}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={1}/>
                                                <Grid item xs={4}>
                                                    <Grid container direction="column" alignItems="center" justify="center">
                                                        <MuiThemeProvider theme={MontserratBoldTheme}>
                                                            <Typography variant="h6" color="secondary">OR</Typography>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={7} />

                                                <Grid item xs={1}/>
                                                <Grid item xs={4}>
                                                    <OutlinedSelect data={this.props.beacons} 
                                                        values={this.props.chosenBeacons}
                                                        onChange={e => this.beaconChange(e, i)}
                                                        label="Beacon" labelWidth={60} 
                                                        value={this.props.chosenBeacons[i]} />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton size="medium" onClick={e => this.props.clearBeacon(e, i)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={6} />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                else{
                                    return(
                                        <div key={i}/>
                                    )
                                }
                            } 
                        )}
                        {this.props.chosenBeacons.length !== this.props.beacons.length && this.props.beacons.length > 0 ? (
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={1} />
                                    <Grid item xs={2}>
                                        <Button color="secondary" variant="contained" onClick={this.props.addNewBeacon}>{t("policies.addBeacon")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ):(
                            <div />
                        )}
                        
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                        <Grid item xs={1}>
                            <MuiThemeProvider theme={MontserratBoldTheme}>
                                <Typography variant="h6" color="secondary">THEN</Typography>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={5}>
                            <OutlinedSelect data={this.props.actionData} onChange={e=>this.props.onActionChange(e, 0)} label="Action" labelWidth={40} value={this.props.actions[0].actionType} />
                        </Grid>

                        {/* TO ENABLE IN LATER CYCLES */}
                        {/* {this.props.actions[0].actionType === "sendNotification" ? (
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography variant="h5" color="secondary">TO</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <OutlinedSelect data={[]} label="User" labelWidth={40} value={""} />
                                    </Grid>
                                    <Grid item xs={1}/>
                                </Grid>
                            </Grid>
                        ) :
                        (<div/>)} */}
                       
                       {this.props.actions[0].actionType === "sendEmail" ? (
                            <Grid item xs={5}>
                                <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                                    <Grid item xs={2}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <MuiThemeProvider theme={MontserratBoldTheme}>
                                                <Typography variant="h6" color="secondary">TO</Typography>
                                            </MuiThemeProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <TextField onChange={e=>this.props.onEmailChange(e, 0)} value={this.props.actions[0].reqData} type="email" variant="outlined" label="Email"/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) :
                        (
                            this.props.actions[0].actionType === "webhook" ? (
                                <Grid item xs={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {this.props.actions[0].reqData ? 
                                            (
                                                <Button variant="contained" 
                                                    onClick={e => this.openWebhookDialog(e, 0, this.props.actions[0].reqData)} 
                                                    color="secondary">
                                                        Edit
                                                </Button>
                                            )
                                            : (
                                                <Button variant="contained" 
                                                    onClick={e => this.openWebhookDialog(e, 0, this.props.actions[0].reqData)} 
                                                    color="secondary">
                                                       {t('policies.config')}
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) :
                            (<Grid item xs={5}/>)
                        )}
                        <Grid item xs={1}/>
                       
                        {this.props.actions.map((a, i) =>
                            {
                                if(i !== 0) {                                    
                                    return (
                                        <Grid item xs={12}  key={i}>
                                            <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                                                <Grid item xs={1}>
                                                    <Grid container direction="column" alignItems="center" justify="center"/>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" alignItems="center" justify="center">
                                                        <MuiThemeProvider theme={MontserratBoldTheme}>
                                                            <Typography variant="h6" color="secondary">AND</Typography>
                                                        </MuiThemeProvider>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} />

                                                <Grid item xs={1}/>
                                                <Grid item xs={5}>
                                                    <OutlinedSelect data={this.props.actionData} 
                                                        onChange={e=>this.props.onActionChange(e, i)} 
                                                        label="Action" labelWidth={40} value={this.props.actions[i].actionType} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {a.actionType === "sendEmail" ? (
                                                        <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                                                            <Grid item xs={2}>
                                                                <Grid container direction="column" alignItems="center" justify="center">
                                                                    <MuiThemeProvider theme={MontserratBoldTheme}>
                                                                        <Typography variant="h6" color="secondary">TO</Typography>
                                                                    </MuiThemeProvider>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <TextField onChange={e=>this.props.onEmailChange(e, i)} value={this.props.actions[i].reqData} type="email" variant="outlined" label="Email"/>
                                                            </Grid>
                                                        </Grid> 
                                                    )
                                                    : (<div/>)}
                                                    {a.actionType === "webhook" ? (
                                                        this.props.actions[i].reqData ? (
                                                            <Button variant="contained" 
                                                                onClick={e => this.openWebhookDialog(e, i, this.props.actions[i].reqData)} 
                                                                color="secondary">Edit</Button>
                                                        )
                                                        :(
                                                            <Button variant="contained" 
                                                                onClick={e => this.openWebhookDialog(e, i, this.props.actions[i].reqData)} 
                                                                color="secondary">Configure</Button>
                                                        )
                                                        
                                                    )
                                                    : (<div/>)}
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <IconButton size="medium" onClick={e => this.props.clearAction(e, i)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                else{
                                    return(
                                        <div key={i}/>
                                    )
                                }
                            } 
                        )}
                        
                        <Grid item xs={1} />
                        <Grid item xs={2}>
                            <Button color="secondary" onClick={this.props.addNewAction} variant="contained">{t('policies.action')}</Button>
                        </Grid>

                        <Dialog fullWidth maxWidth="md" onClose={this.closeWebhookDialog} open={this.state.open}>
                            <ConfigurationDialog open={this.state.open} 
                                ind={this.state.ind}
                                data={this.state.whData}
                                submitWebhook={this.submitWebhook}
                                onClose={this.closeWebhookDialog}
                            />
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(BeaconsActionsAndRules);