import { useTranslation } from "react-i18next"
import { Grid, Typography } from "@material-ui/core";
import DataTable from '../Gui/DataTable';
import AddFab from '../Gui/AddFab';
import React from "react";
const PoliciesPanel = (props) =>{

    const { t } = useTranslation();
    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">{t("policies.title")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable data={props.data} name={t("policy", {count: 5})}
                        onItemDelete={props.onItemDelete}
                        onItemClick={props.onItemClick}
                        attributes={props.attributes}
                        headers={props.headers}/>
                </Grid>
            </Grid>
            <AddFab onClick={props.openCreate}/>
        </div>
    )
}
export default PoliciesPanel