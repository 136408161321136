import React  from "react"
import { Grid, TextField } from "@material-ui/core";
import TextMaskCustom from "../Gui/MaskedInput.js"

const DatePicker = (props) => {


      
      return(
            <Grid container spacing={3}>
                  <Grid item xs={6}>
                        <TextField
                              error = { props.isDate ? false : true }
                              helperText = { props.isDate ? "": "Time period in incorrect" }
                              name = "startTime" 
                              id="startTime"
                              label="Time start"
                              type="text"
                              variant="outlined"
                              defaultValue= {props.startTime}
                              onChange = {props.handleChangeStartTime}
                              InputProps={{
                                    inputComponent: TextMaskCustom,
                                  }}
                        />
                  </Grid>
                  <Grid item xs={6}>
                        <TextField
                              error = { props.isDate ? false : true }
                              helperText = { props.isDate ? "": "Time period in incorrect" }
                              name = "endTime" 
                              id="datetime-local"
                              label="Time end"
                              type="text"
                              variant="outlined"
                              defaultValue= {props.endTime}
                              onChange = {props.handleChangeEndTime}
                              InputProps={{
                                    inputComponent: TextMaskCustom
                                  }}
                        />
                  </Grid>
            </Grid>
      )
}

export default DatePicker