import React, {Component} from 'react'
import { Typography } from "@material-ui/core";
import { withTranslation } from 'react-i18next';

class Dashboard extends Component{
    render(){
        const { t } = this.props;
        return(
            <div>
                <Typography variant="h4"> {t("dashboard.title")}</Typography>
                <Typography>{t("dashboard.prompt")}</Typography>
            </div>
        )
    }
}

export default withTranslation()(Dashboard);