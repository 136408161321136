const initialState = {
    gateways: [],
    gatewayTemplates: [],
    notices: [],
    currentGateway: [],
    isOpened: false
}

export default function Gatwewats(state = initialState, action) {

    if(action.type === 'FETCH_GATEWAYS'){
        return {
            ...state,
            gateways: action.payload.data,
            notices: []
        }
    }
    if (action.type === 'FETCH_GATEWAY'){
        return {
            ...state,
            currentGateway: action.payload.data
        }
    }
    if (action.type === 'FETCH_GATEWAY_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewayFetchError"}]
        }
    }
    if (action.type === 'FETCH_GATEWAYS_FAILURE'){
        return {
            ...state,
            gateways: [],
            notices: [{type: "error", notifyKey: "snackbar.gatewaysFetchError"}]
        }
    }

    if(action.type === 'GATEWAY_CLEAR_NOTICES')
    {
        return {
            ...state,
            notices: []
        }
    }
    
    if(action.type === 'FETCH_GATEWAY_TEMPLATES'){
        return {
            ...state,
            gatewayTemplates: action.payload.data
        }
    }

    if (action.type === 'FETCH_GATEWAY_TEMPLATES_FAILURE'){
        return {
            ...state,
            gatewayTemplates: [],
            notices: [{type: "error", notifyKey: "snackbar.gatewaysTemFetchError"}]
        }
    }

    if (action.type === 'GATEWAY_STATUS_SEND'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "Gateway status has been send"}]
        }
    }

    if (action.type === 'GATEWAY_STATUS_SEND_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "Could not send gateway status"}]
        }
    }

    if (action.type === 'POST_GATEWAYS'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.gatewaysPostSuccess"}]
        }
    }

    if (action.type === 'POST_GATEWAYS_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewaysPostError"}]
        }
    }
    if (action.type === 'GATEWAY_STATUS_SEND'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "Gateway Status Checked"}]
        }
    }
    if (action.type === 'DELETE_GATEWAY'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.gatewayDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_GATEWAY_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewayDeleteError"}]
        }
    }

    if (action.type === 'PATCH_GATEWAY'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.gatewayPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_GATEWAY_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewayPatchError"}]
        }
    }

    // Templates
    if(action.type === 'POST_GATEWAY_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.gatewaysTemPostSuccess"}]
        }
    }
    if (action.type === 'POST_GATEWAY_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewaysTemPostError"}]
        }
    }
    if(action.type === 'PATCH_GATEWAY_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.gatewaysTemPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_GATEWAY_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewaysTemPatchError"}]
        }
    }
    if(action.type === 'DELETE_GATEWAY_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.gatewaysTemDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_GATEWAY_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewaysTemDeleteError"}]
        }
    }

    if (action.type === 'FETCH_GATEWAY_TEMPLATE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.gatewayTemFetchError"}]
        }
    }

    return state
}
  