import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const TextMaskCustom = (props) => {
      const { inputRef, ...other } = props;
      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/[2-2]/, /\d/, /\d/,/\d/,"-", /[0-1]/, /[0-9]/,"-", /\d/, /\d/, " ", /[0-2]/, /[0-9]/,":", /[0-5]/, /[0-9]/,":", /[0-5]/, /[0-9]/]}
          placeholderChar={"\u2000"}
          showMask
        />
      );
    }

TextMaskCustom.propTypes = {
inputRef: PropTypes.func.isRequired,
};
export default TextMaskCustom