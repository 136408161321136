import React, { Component } from "react";
import SaveButton from '../Gui/SaveButton';
import { Grid, TextField, MuiThemeProvider, Button } from '@material-ui/core';
import CancelButton from '../Gui/CancelButton';
import IconSelector from '../Gui/IconSelector';
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import IconTextField from '../Gui/IconTextField';
import InformationVariantIcon from 'mdi-react/InformationVariantIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import { connect } from 'react-redux';
import { patchGatewayTemplate, patchReferenceNodeTemplate, patchBeaconTemplate } from '../../actions/apiCalls';
import { fetchBeaconTemplates, fetchGatewayTemplates, fetchReferenceNodeTemplates } from '../../actions/apiCalls';
import axios from "axios";
import { withTranslation } from 'react-i18next';
import { IstokTheme } from "../../themes/style";
import { withSnackbar } from "notistack";

class EditTemplate extends Component{
    
    constructor(){
        super();
        this.state = {
            name: "",
            interval: 0,
            type: "",
            security: "",
            ssid: "",
            password: "",
            icon: "",
            iconUrl: null
        };
    };

    componentDidMount = () => {
        if(this.props.type && this.props.templateId){
            switch(this.props.type){
                case "Gateway":
                    let urlGT = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/gateway-templates/'+this.props.templateId
                    try{
                        axios.get(urlGT).then(res => this.setState({
                            name: res.data.data[0].attributes.name,
                            type: this.props.type,
                            security: "WPA2 Personal/PSK",
                            ssid: res.data.data[0].attributes.ssid,
                            password: res.data.data[0].attributes.password,
                        })).catch(e => {
                            this.props.onfetchError(e, "Gateway");
                            this.props.onClose();
                        });
                    }
                    catch (e){
                        console.log(e)
                    }
                    break;
                case "Beacon":
                    let urlBT = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/beacon-templates/'+this.props.templateId
                    try{
                        axios.get(urlBT).then(res => this.setState({
                            name: res.data.data[0].attributes.name,
                            icon: res.data.data[0].attributes.defaultIcon,
                            iconUrl: res.data.data[0].attributes.defaultIconUrl,
                            type: this.props.type
                        })).catch(e => {
                            this.props.onfetchError(e, "Beacon");
                            this.props.onClose();
                        });
                    }
                    catch (e){
                        console.log(e)
                    }
                    break;
                case "Reference Node":
                    let urlRNT = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/reference-node-templates/'+this.props.templateId
                    try{
                        axios.get(urlRNT).then(res => this.setState({
                            name: res.data.data[0].attributes.name,
                            interval: res.data.data[0].attributes.interval,
                            type: this.props.type
                        })).catch(e => {
                            this.props.onfetchError(e, "Reference Node");
                            this.props.onClose();
                        });
                    }
                    catch (e){
                        console.log(e)
                    }
                    break;
                default:
                    break;
            }
        }
    }

    submitTemplate = () => {
        if(this.props.projectId){
            switch(this.state.type){
                case "Gateway":
                    let dataGT = {
                        name: this.state.name,
                        description: "",
                        ssid: this.state.ssid,
                        password: this.state.password
                    }
                    this.props.patchGatewayTemplate(this.props.projectId, this.props.templateId, dataGT)
                    break;
                case "Beacon":
                    let dataBT = {
                        name: this.state.name,
                        interval: this.state.interval,
                        defaultIcon: this.state.icon,
                        defaultIconUrl: this.state.iconUrl
                    }
                    this.props.patchBeaconTemplate(this.props.projectId, this.props.templateId, dataBT)
                    break;
                case "Reference Node":
                    let dataRNT = {
                        name: this.state.name,
                        description: "",
                        interval: this.state.interval
                    }
                    this.props.patchReferenceNodeTemplate(this.props.projectId, this.props.templateId, dataRNT)
                    break;
                default:
                    break;
            }
            this.props.onClose();
        }
    };

    validateData = () => {
        if(this.state.type === "Gateway"){
            return this.state.name && this.state.ssid && this.state.password ? false: true;
        }
        else{
            return this.state.name ? false: true
        }
    }

    onSecChange = (e) => {
        this.setState({security: e.target.value})
    };

    onNameChange = (e) => {
        this.setState({name: e.target.value})
    };

    onIntervalChange = (e) => {
        this.setState({interval: e.target.value})
    };

    onSSIDChange = (e) => {
        this.setState({ssid: e.target.value})
    };

    onPasswordChange = (e) => {
        this.setState({password: e.target.value})
    };
    
    fileSelectedHandler = (event) => {
        let image = event.target.files[0]
        let reader = new FileReader()
        
        reader.onload = (e) => {
            if(image.size < 100000){
                this.setState({icon: image.name, iconUrl: e.target.result})
            }
            else{
                const { t } = this.props;
                this.props.enqueueSnackbar(t("snackbar.imageTooLarge"),  { 
                    variant: 'error',
                });
            }
        }
        reader.readAsDataURL(event.target.files[0])
    }

    render(){
        const { t } = this.props
        let height = 36;
        const typeData = [
            {id:"Beacon", attributes:{name: t("templates.beaconType")}},
            {id:"Reference Node", attributes:{name: t("templates.referenceNodeType")}},
            {id:"Gateway", attributes:{name: t("templates.gatewayType")}}
        ];
        const securityData = [
            {id:"WPA2 Personal/PSK", attributes:{name: "WPA2 Personal/PSK"}}
        ];

        return(
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitTemplate} disabled={ this.validateData() } />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        {this.state.type === "Gateway" ?
                        (
                            <Grid container spacing={3}>
                                <IconSelector value={this.state.type} data={typeData} labelWidth={40} label={t("templates.type")}
                                icon={<FileDocumentIcon />} disabled={true} />
                                <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("templates.name")} icon={<InformationVariantIcon />} />
                                <IconSelector value={this.state.security} data={securityData} labelWidth={95} label={t("templates.securityType")}
                                    icon={<SettingsIcon />} onChange={this.onSecChange}/>
                                <IconTextField value={this.state.ssid} onChange={this.onSSIDChange} label={t("templates.ssid")} icon={null}/>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1} />
                                        <Grid item xs={11}>
                                            <TextField onChange={this.onPasswordChange} value={this.state.password} type="password" label={t("templates.password")} variant="outlined" fullWidth/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        )
                        :(
                            this.state.type === "Reference Node" ? (
                                <Grid container spacing={3}>   
                                    <IconSelector value={this.state.type} data={typeData} labelWidth={40} label={t("templates.type")}
                                        icon={<FileDocumentIcon />} disabled={true} />
                                    <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("templates.name")} icon={<InformationVariantIcon />}/>
                                </Grid>):(
                                <Grid container spacing={3}>   
                                    <IconSelector value={this.state.type} data={typeData} labelWidth={40} label={t("templates.type")}
                                        icon={<FileDocumentIcon />} disabled={true} />
                                    <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("templates.name")} icon={<InformationVariantIcon />}/>
                                    <Grid item xs={1}/>
                                    <Grid item xs={8}>
                                        <MuiThemeProvider theme={IstokTheme}>
                                            <TextField  InputProps={{style:{height}, readOnly: true}}
                                                label={t("templates.defaultIcon")}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={this.state.icon || ""}
                                                fullWidth disabled variant ="outlined"/>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="raised-button-file"
                                            multiple
                                            type="file"
                                            onChange={this.fileSelectedHandler}
                                        />
                                        <label htmlFor="raised-button-file">
                                            <Button fullWidth variant="contained" color="secondary" component="span">
                                                {t("beacons.uploadBeaconButton")}
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </Grid>
                    <Grid item xs={8} />
                </Grid>
            </form>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        patchGatewayTemplate(projectId, templateId, data) {
            dispatch(patchGatewayTemplate(projectId, templateId, data)).then(() => dispatch(fetchGatewayTemplates(projectId)));
        },
        patchReferenceNodeTemplate(projectId, templateId, data) {
            dispatch(patchReferenceNodeTemplate(projectId, templateId, data)).then(() => dispatch(fetchReferenceNodeTemplates(projectId)));
            ;
        },
        patchBeaconTemplate(projectId, templateId, data) {
            dispatch(patchBeaconTemplate(projectId, templateId, data)).then(() => dispatch(fetchBeaconTemplates(projectId)));
        },
        onfetchError(e, type){
            switch(type){
                case "Gateway":
                    dispatch({type: 'FETCH_GATEWAY_TEMPLATE_FAILURE'});
                    break
                case "Beacon":
                    dispatch({type: 'FETCH_BEACON_TEMPLATE_FAILURE'});
                    break;
                case "Reference Node":
                    dispatch({type: 'FETCH_REFERENCE_NODE_TEMPLATE_FAILURE'});
                    break;
                default:
                    break;
            }
        }
    }
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditTemplate)));