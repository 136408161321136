import React from "react";
import DataTable from '../Gui/DataTable';
import AddFab from "../Gui/AddFab";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const LocationsPanel = (props) => {

    const { t } = useTranslation();
    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">{t("locations.title")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable data={props.data} name={t("location", {count: 5})}
                    onItemDelete={props.onItemDelete}
                    onItemClick={props.onItemClick}
                    attributes={props.attributes}
                    headers={props.headers}
                    deleteWarningMessage={"common.removeLocationWarning"}/>
                </Grid>
            </Grid>
            <AddFab onClick={props.addNewLocation}/>
        </div>
    )
}
export default LocationsPanel