import { useTranslation } from "react-i18next"
import React from "react";
import {Grid, Typography} from "@material-ui/core";
import AddFab from '../Gui/AddFab';
import DataTable from '../Gui/DataTable';

const ZonesPanel = (props) => {

    const { t } = useTranslation();
    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">{t("zones.title")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable data={props.data} name={t("zone", {count: 5})}
                        onItemDelete={props.onItemDelete}
                        onItemClick={props.onItemClick}
                        attributes={props.attributes}
                        headers={props.headers}/>
                </Grid>
            </Grid>
            <AddFab onClick={props.openCreate}/>
        </div>                    
    )
}
export default ZonesPanel