import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
// import RouteList from './RouteList'
import LoginLogout from './LoginLogout'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import MapIcon from '@material-ui/icons/Map';
import HomeIcon from '@material-ui/icons/Home';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SettingsIcon from '@material-ui/icons/Settings'
import RoomIcon from '@material-ui/icons/Room'
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import DocumentIcon from 'mdi-react/FileDocumentIcon'
import AnchorIcon from 'mdi-react/AnchorIcon';
import TimelineIcon from '@material-ui/icons/Timeline';
import PhotoSizeSelectIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import GavelIcon from '@material-ui/icons/Gavel';
import LanguageForm from '../Language/LanguageForm.js'
import Timer from './Timer';

const drawerWidth = 180;

const styles = theme => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginLeft: -15,
		marginRight: 0,
	},
	grow: {
		flexGrow: 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	drawerOpen: {
		paddingTop: '12px',
		borderStyle: 'hidden',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		paddingTop: '12px',
		borderStyle: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
    },
    listItemSelected: {
        background: '#ff6450',
        "&:hover": {
            background: '#ff3a2c',
        },
        "&:focus": {
            background: '#ff6450',
        },
        "& svg":{
            color: '#ffffff'
        },
        "& span":{
            color: '#ffffff'
        },
        borderRadius: "0 15px 15px 0",
    },
    listItem: {
        "&:hover": {
            background: '#f85b51',
            "& svg":{
                color: '#ffffff'
            },
            "& span":{
                color: '#ffffff'
            },
        },
        borderRadius: "0 15px 15px 0"
    }
});

class AppBarDrawer extends Component{

	static propTypes = {
		classes: PropTypes.object.isRequired
	}

	state = {
        open: false
    }
    
    handleDrawerOpenClose = () => {
		if(this.state.open)
			this.setState({ open: false });
		else
			this.setState({ open: true });
	};
    render(){
        const { t } = this.props
        let RouteList = []
        if(this.props.userRole === "admin" || this.props.userRole === "superadmin"){
            RouteList = [
                {label: t('sidebar.Dashboard'), icon: <HomeIcon />, route: '/', id: 0},
                {label: t('sidebar.Locations'), icon: <MapIcon />, route: '/locations', id: 1},
                {label: t('sidebar.Gateways'), icon: <DeviceHubIcon />, route: '/gateways', id: 2},
                {label: t('sidebar.Beacons'), icon: <BluetoothIcon />, route: '/beacons', id: 3},
                {label: t('sidebar.Nodes'), icon: <AnchorIcon />, route: '/reference-nodes', id: 4},
                {label: t('sidebar.Templates'), icon: <DocumentIcon />, route: '/templates', id: 5},
                {label: t('sidebar.Tracking'), icon: <RoomIcon />, route: '/tracking', id: 6},
                {label: t('sidebar.Zones'), icon: <PhotoSizeSelectIcon />, route: '/zones', id: 7},
                {label: t('sidebar.Policies'), icon: <GavelIcon />, route: '/policies', id: 8},
                {label: t('sidebar.Settings'), icon: <SettingsIcon />, route: '/settings', id: 9},
                {label: t('sidebar.TrackingHistory'), icon: <TimelineIcon />, route: '/tracking-history', id: 10}
            ]
        }
        else{
            RouteList = [
                {label: t('sidebar.Dashboard'), icon: <HomeIcon />, route: '/', id: 0},
                {label: t('sidebar.Locations'), icon: <MapIcon />, route: '/locations', id: 1},
                {label: t('sidebar.Beacons'), icon: <BluetoothIcon />, route: '/beacons', id: 3},
                {label: t('sidebar.Tracking'), icon: <RoomIcon />, route: '/tracking', id: 6},
                {label: t('sidebar.Zones'), icon: <PhotoSizeSelectIcon />, route: '/zones', id: 7},
                {label: t('sidebar.Policies'), icon: <GavelIcon />, route: '/policies', id: 8},
                {label: t('sidebar.Settings'), icon: <SettingsIcon />, route: '/settings', id: 9}
            ]
        }
       

        const { classes } = this.props;
        return(
            <div>
                <AppBar position="fixed" color="inherit" className={classNames(classes.appBar)} elevation={0}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpenClose}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: this.state.open,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h5" color="inherit" className={classes.grow} noWrap>
                            MPS
                        </Typography>
                        <Timer minutes={30} popupTime={5}/>                       
                        <LanguageForm />
                        <LoginLogout />
                    </Toolbar>
                    <Divider />
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                    open={this.state.open}>
                    <div className={classes.toolbar} />

                    <List>
                        {RouteList.map((route) => (
                            <ListItem button key={route.id} onClick={e=> this.props.onItemClicked(route.route, route.id)}
                            className={this.props.currentItem === route.id ? (classes.listItemSelected):(classes.listItem)}>
                                <ListItemIcon /*className={this.props.currentItem === route.id ? (classes.item):(null)}*/>{route.icon}</ListItemIcon>
                                <ListItemText primary={route.label}  />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </div>
        )
    }
}

export default withStyles(styles)(withTranslation()(AppBarDrawer));