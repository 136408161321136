import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchBeacons, deleteBeacons } from '../../actions/apiCalls';
import CreateBeacon from './CreateBeacon';
import EditBeacon from "./EditBeacon";
import { withTranslation } from 'react-i18next';
import { Route, Switch } from "react-router-dom";
import BeaconsPanel from './BeaconsPanel.js'

class Beacons extends Component{

    constructor(){
        super()
        this.state = {
            currBeaconId: ""
        };
    };

    componentDidMount(){
        if(this.props.projectId)
            this.props.fetchProjectBeacons(this.props.projectId)
        else
            this.props.history.push('/')
    };

    openCreate = () => {
        this.props.history.push(this.props.match.path + '/create');
    };

    openEdit = (e, beaconId) => {
        this.setState({
            currBeaconId: beaconId
        })
        this.props.history.push(this.props.match.path + '/edit');
    };

    deleteBeacon = (e, beaconId) => {
        e.stopPropagation();
        if (this.props.projectId !== null)
            this.props.deleteBeacon(this.props.projectId, beaconId);
    }

    closeForm = () => {
        this.props.history.push('/beacons');
    };

    render(){
        const { t } = this.props
        const attributes = ["name", "MAC", "battery"]
        const headers = [t('beacons.name'), t('beacons.mac'), t('beacons.battery') + " %"]
        return(
            <div>
                <Switch>
                    <Route exact path={'/beacons'}>
                        <BeaconsPanel data={this.props.beacons} name={t("beacon", {count: 5})}
                            onItemDelete={this.deleteBeacon}
                            onItemClick={this.openEdit}
                            openCreate={this.openCreate}
                            attributes={attributes}
                            headers={headers} 
                        />
                    </Route>
                    <Route path={this.props.match.url + '/create'}>
                        <CreateBeacon onClose={this.closeForm}/>
                    </Route>
                    <Route path={this.props.match.url + '/edit'}>
                        <EditBeacon onClose={this.closeForm} beaconId={this.state.currBeaconId}/>
                    </Route>
                </Switch>
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        beacons: state.beacons.beacons,
        errors: state.beacons.errors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectBeacons(projectId) {
            dispatch(fetchBeacons(projectId)).then(() => console.log("Fetched Beacons"));
        },
        deleteBeacon(projectId, beaconId) {
            dispatch(deleteBeacons(projectId, beaconId)).then(() => dispatch(fetchBeacons(projectId)));
        },
        clearBeaconErrors() {
            dispatch({type: 'BEACONS_CLEAR_ERRORS'})
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Beacons));
