import React, { Component } from "react";
import { Grid, IconButton, TextField } from "@material-ui/core";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'

class IconTextField extends Component{
    render(){
        return(
            <Grid item xs={12}>
                <MuiThemeProvider theme={IstokTheme}>
                    <Grid container spacing={3}>
                        <Grid item xs={1}>
                            <IconButton style={{pointerEvents: 'none'}}>
                                {this.props.icon}
                            </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                            <TextField
                                type = {this.props.type} name = {this.props.name} onChange={this.props.onChange} disabled={this.props.disabled} helperText={this.props.helperText} value={this.props.value} label={this.props.label} variant="outlined" fullWidth onBlur={this.props.onEnd}/>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Grid>
        );
    };
};

export default IconTextField
