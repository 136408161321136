import React, { Component } from "react";
import { Typography, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { RefreshDialog } from "./RefreshDialog";

class Timer extends Component{
    
    intervalVariable;

    state = {
        seconds: 0,
        open: false
    }

    closeDialog = () => { this.setState({open: false}); }

    logoutAndReload = () => {
        clearInterval(this.intervalVariable);
        this.props.logoutUser();
        document.location.reload()
    }

    updateTime = () => {
        if(this.state.seconds <= 0){
            this.logoutAndReload();
        }
        else{
            this.state.seconds === this.state.expiration ? (
                this.setState({ seconds: this.state.seconds - 1, open: true })
            ): (
                this.setState({ seconds: this.state.seconds - 1 })
            )
        }
    }
    // I assumed maximal interval should be 1 hour
    componentDidMount = () => {
        this.setState({seconds: this.props.minutes * 60, expiration: this.props.popupTime * 60})
        this.intervalVariable = setInterval(() => this.updateTime(), 1000)
    }

    componentWillUnmount = () => {
        if(this.intervalVariable)
            clearInterval(this.intervalVariable);
    }

    appendZero = (value) => {
        if(value < 10){
            return "0" + value;
        }
        return value;
    }

    render(){
        let minutes = this.appendZero(Math.floor(this.state.seconds / 60));
        let seconds = this.appendZero(this.state.seconds - minutes * 60);

        return(
            <div>
                <Typography> {minutes}:{seconds} </Typography>
                <Dialog fullWidth maxWidth="sm" open={this.state.open} onClose={this.closeDialog} >
                    <RefreshDialog onClose={this.closeDialog} />    
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
};


const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => { dispatch({type: 'LOGOUT_USER'})}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Timer);