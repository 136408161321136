const initialState = {
    referenceNodes: [],
    referenceNodeTemplates: [],
    notices: [],
}

export default function ReferenceNodesReducer(state = initialState, action) {

    if(action.type === 'FETCH_REFERENCE_NODES'){
        return {
            ...state,
            referenceNodes: action.payload.data,
            notices: []
        }
    }
    if (action.type === 'FETCH_REFERENCE_NODES_FAILURE'){
        return {
            ...state,
            referenceNodes: [],
            notices: [{type: "error", notifyKey: "snackbar.refNodesFetchErrors"}]
        }
    }

    if(action.type === 'FETCH_REFERENCE_NODE_TEMPLATES'){
        return {
            ...state,
            referenceNodeTemplates: action.payload.data
        }
    }
    if(action.type === 'FETCH_REFERENCE_NODE_TEMPLATES_FAILURE'){
        return {
            ...state,
            referenceNodeTemplates: [],
            notices: [{type: "error", notifyKey: "snackbar.refNodesTemFetchErrors"}]
        }
    }

    if (action.type === 'POST_REFERENCE_NODES'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.refNodesPostSuccess"}]
        }
    }
    if (action.type === 'POST_REFERENCE_NODES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodesPostErrors"}]
        }
    }

    if (action.type === 'DELETE_REFERENCE_NODES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.refNodesDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_REFERENCE_NODES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodesDeleteErrors"}]
        }
    }

    if (action.type === 'PATCH_REFERENCE_NODE'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.refNodesPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_REFERENCE_NODE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodesPatchErrors"}]
        }
    }

    if (action.type === 'FETCH_REFERENCE_NODE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodeFetchErrors"}]
        }
    }

    if (action.type === 'REFERENCE_NODE_CLEAR_NOTICES')
    {
        return {
            ...state,
            notices: []
        }
    }

    // RN TEMPLATES
    if (action.type === 'POST_REFERENCE_NODE_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.refNodeTemPostSuccess"}]
        }
    }
    if (action.type === 'POST_REFERENCE_NODE_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodeTemPostError"}]
        }
    }
    if (action.type === 'PATCH_REFERENCE_NODE_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.refNodeTemPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_REFERENCE_NODE_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodeTemPatchError"}]
        }
    }
    if (action.type === 'DELETE_REFERENCE_NODE_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.refNodeTemDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_REFERENCE_NODE_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodeTemDeleteError"}]
        }
    }

    if (action.type === 'FETCH_REFERENCE_NODE_TEMPLATE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.refNodeTemFetchError"}]
        }
    }

    return state
}
  