import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';

class LoginLogoutButton extends Component {
    constructor(){
        super()
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){
        this.props.logoutUser()
        document.location.reload()
    }

    render(){
        const { t } = this.props
        if(this.props.loggedIn)
            return(<div>
                        <Button onClick={this.handleClick}>{t("common.logout")}</Button>
                    </div>
            )
        else
            return(<Link to="/login"><Button>Login</Button></Link>)
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.userLoggedIn
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => { dispatch({type: 'LOGOUT_USER'})}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginLogoutButton));