import './IconMarker.css'

class IconMarker{
    constructor(latlng, imageSrc, map, size, mac) {
        this.id = ""
        this.mac = mac
        this.latlng_ = latlng
        this.size = size
        this.imageSrc = imageSrc
        this.div_ = null
        this.textWindow = null
        this.setMap(map)
    }
    
    prepareContent = () => {
        return '<p><b>ID : </b>' + this.id + ' <b>MAC : </b>' + this.mac +'</p>'
    };

    onAdd = () => {
        if (!this.div_){
            this.div_ = document.createElement('div');
            this.div_.className = "customMarker"
            
            let img = document.createElement("img");
            img.src = this.imageSrc;
            this.div_.appendChild(img);
            
            let panes = this.getPanes();
            panes.overlayMouseTarget.appendChild(this.div_);
            let content = this.prepareContent();
            this.textWindow = new window.google.maps.InfoWindow({
                content: content,
                pixelOffset: this.size
            })
        }
    }

    getMarker = () => {
        return this.div_;
    }

    draw = () => {      
        let self = this;
        let point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
        if (point) {
            this.div_.style.left = point.x + 'px';
            this.div_.style.top = point.y + 'px';
        }
        window.google.maps.event.addDomListener(this.div_, 'click', function() {
            this.textWindow.open(this.map, self);
            window.google.maps.event.trigger(self, 'click');
        }.bind(this));
    }

    onRemove = () => {
        this.div_.parentNode.removeChild(this.div_)
        this.div_ = null
    }

    setPosition = (latlng) => {
        this.latlng_ = new window.google.maps.LatLng(
            latlng.lat,
            latlng.lng
        );

        let point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
        if (point && this.div_) {
            this.div_.style.left = point.x + 'px';
            this.div_.style.top = point.y + 'px';
        }
        if(this.isInfoWindowOpen()){
            let self = this;
            this.textWindow.setMap(null);
            this.textWindow.open(this.map, self);
        }
    }

    getPosition = () => {
        return this.latlng_
    };


    isInfoWindowOpen = () => {
        if(this.textWindow){
            if(typeof this.textWindow.getMap === "function"){
                let map = this.textWindow.getMap()
                return map !== null && typeof map !== "undefined"
            }
        }
        return false;
    }

};

export default IconMarker;