import React, { useEffect, useRef } from "react"
import scriptLoad from '../../scriptLoader'
import axios from 'axios'


const TrackingHistoryMap = (props) =>{

    let markers = useRef([]);
    let beaconPath = useRef();
    let map = useRef();

    const data  = props.queryData();
    
    useEffect(() => {

        const initMap = () => {

            map.current = new window.google.maps.Map(document.getElementById('googleMap'),
                {
                    zoom: 18,
                    center: { lat:  54.370772, lng: 18.614036 },        
                });
        };

        if(!(typeof window.google === 'object' && typeof window.google.maps === 'object')){
            scriptLoad("https://maps.googleapis.com/maps/api/js?key=AIzaSyDu98s_Mngyn_OTUJkL-b01MSMgkn_WlW8&v=3.exp&libraries=geometry,drawing,places").then(res => {initMap()})
        }
        else
        {
            initMap();

        }

    },[]);

    useEffect(() => {
 
            
        const removeMarkers = () => {

            setMapOnAll(null);

        };

        const fetchPosition = (data) => {
            let markerPosition = [];
            let url = process.env.REACT_APP_POSITION_PERSISTENCE_URL;
            axios.post(url + 'position/tracking', data)  
            .then((response) => {
                try{
                    markerPosition = response.data;
                    if(typeof beaconPath.current !== "undefined"){
                        removeLine();
                    };
                    removeMarkers();
                    if(markerPosition.data.length > 0)
                    {
                        addMarker(markerPosition);
                        addTrace(markerPosition);
                    }
                }
                catch(error){
                    return
                }
    
            })    
            .catch(function (error) {
                // handle error
                return
            });
          };
    
        if(Boolean(data)){

            if(Boolean(data.data.attributes.n_values)){
                const interval = setInterval(() => {
                    fetchPosition(data);
                }, 3000);
                return () => clearInterval(interval);
            }
            else if(Boolean(data.data.attributes.startTime) && Boolean(data.data.attributes.endTime)){

                if(data.data.attributes.startTime.length >= 19 && data.data.attributes.endTime.length >= 19){
                    const interval = setInterval(() => {
                        fetchPosition(data);
                    }, 3000);
                    return () => clearInterval(interval);

                }
            }
            else if(Boolean(data.data.attributes.lastTime)){
                const interval = setInterval(() => {
                    fetchPosition(data);
                }, 3000);
                return () => clearInterval(interval);
            }
        }
    },[data])
      
    const addMarker = (markerPosition) => {
     
            markers.current = []
            let marker =  new window.google.maps.Marker({
                position: {lat: markerPosition["data"][0]["latitude"], lng: markerPosition["data"][0]["longitude"]},
                title: markerPosition["data"][0]["id"],
                map: map.current,
            });
            markers.current.push(marker)
         
      };
  
    const addTrace = (markerPosition) => {
  
        let traceMark = []; // array of dict essential to set polyline
        markerPosition["data"].map((k) => 
            traceMark.push({lat: k["latitude"], lng: k["longitude"]})
        );
        beaconPath.current =  new window.google.maps.Polyline({
            path: traceMark,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });

        beaconPath.current.setMap(map.current)

    };
  
    const setMapOnAll = (map) => {

        for (let i = 0; i < markers.current.length; i++){
            markers.current[i].setMap(map);
        }

    };
  
    const removeLine = () => {

        // console.log('REMOVING LINE');
    //   beaconPath.setMap(null);
    //   beaconPath = null;
        beaconPath.current.setMap(null);
    //   beaconPath.current = null;
    };

    return(
        <div>
            <div id="googleMap" style={{width: "100%", height: '80vh'}}/>
        </div>
    )
}
export default TrackingHistoryMap