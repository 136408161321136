import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '@material-ui/core'
import "./Locations.css"
import Icon from '@mdi/react';
import { mdiFloorPlan } from '@mdi/js';
import { gray } from "../../themes/style";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'
import { withTranslation } from 'react-i18next';

class FloorPlanUpload extends Component{

    fileSelectedHandler = (event) => {
        let image = event.target.files[0]
        let reader = new FileReader()
        
        reader.onload = (e) => {
            if(image.size < 2500000){
                this.props.setFloorPlan(image.name, e.target.result)
            }
            else{
                this.props.notify("Image is too large");
            }
        }
        reader.readAsDataURL(event.target.files[0])
    }

    render(){
        let height = 36;
        const { t } = this.props
        return(
            <Grid container spacing={2}>
                <Grid item>
                    <Icon path={mdiFloorPlan}
                        size={1} color={gray} />
                </Grid>
                <Grid item xs={9}>
                    <MuiThemeProvider theme={IstokTheme}>
                        <TextField InputProps={{style:{height}, readOnly: true}}
                                label={t('locations.floorPlan')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={this.props.floorName}
                                fullWidth disabled variant ="outlined"/>
                    </MuiThemeProvider>
                </Grid>

                <Grid item xs={2}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={this.fileSelectedHandler}
                    />
                    <label htmlFor="raised-button-file">
                        <Button fullWidth variant="contained" color="secondary" component="span">
                            {t('locations.uploadPlanButton')}
                        </Button>
                    </label>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(FloorPlanUpload);