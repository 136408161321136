import React from "react";
import { Grid, Typography, DialogContent, TextField, MuiThemeProvider } from "@material-ui/core";
import CancelButton from "../Gui/CancelButton";
import SaveButton from "../Gui/SaveButton";
import { IstokTheme } from "../../themes/style";
import { useTranslation } from 'react-i18next';

export const ChangePassDialog = (props) => {
    let leftPadding = 20;
    const isValid = () =>{
        if(!props.newPassword || !props.copyPassword || !props.oldPassword)
            return false;
        if(props.newPassword !== props.copyPassword)
            return false;
        return true;
    }

    const { t } = useTranslation();
    return(
        <Grid container>
            <form onSubmit={e=>e.preventDefault()} autoComplete="off">
                <Grid item xs={12} className="dialogTitle">
                    <Typography variant="h6" style={{paddingLeft: leftPadding}}> {t("settings.personal.password")} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogContent>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <MuiThemeProvider theme={IstokTheme}>
                                    <TextField value={props.oldPassword} autoComplete="new-password" type="password" label={t("settings.personal.oldPassword")} fullWidth variant="outlined" onChange={props.onOldPassChange} />
                                </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <MuiThemeProvider theme={IstokTheme}>
                                    <TextField value={props.newPassword} autoComplete="new-password" type="password" label={t("settings.personal.newPassword")} fullWidth variant="outlined" onChange={props.onNewPassChange}/>
                                </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <MuiThemeProvider theme={IstokTheme}>
                                    <TextField value={props.copyPassword} autoComplete="new-password" type="password" label={t("settings.personal.rePassword")} fullWidth variant="outlined" onChange={props.onCopyPassChange}/>
                                </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={12}>
                                {!(props.newPassword === props.copyPassword) ? (
                                    <Typography color="error">{t('settings.errors.errorMsg')}</Typography>
                                ): null}
                            </Grid>
                            <Grid item xs={8} />
                            <Grid item xs={2} >
                                <CancelButton onClick={props.onClose} />
                            </Grid>
                            <Grid item xs={2}>
                                <SaveButton disabled={!isValid()} onClick={props.onPassChangeSubmit} />
                            </Grid>
                            <Grid item xs={12}/>
                        </Grid>
                    </DialogContent>
                </Grid>
            </form>
        </Grid>
    )
}