export const isCorrectRole = (role) => {
    if(typeof role === 'string'){
        if(role === 'admin' || role === 'superadmin')
            return true
    }
    else return false
}

export const isUUID = (id) => {
    let res = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
    return res;
}

export const fab = {
    position: 'fixed',
    bottom: 45,
    right: 45,
    background: 'linear-gradient(180deg, #ff6450 0, #ff3a2c)',
    color: '#ffffff'
}

export const uploadFab = {
    position: 'fixed',
    bottom: 45,
    right: 110,
    background: 'linear-gradient(180deg, #ff6450 0, #ff3a2c)',
    color: '#ffffff'
}

export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}