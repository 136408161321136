import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchLocations, fetchReferenceNodes, fetchBeaconTemplates } from '../../actions/apiCalls';
import { fetchBeacons, fetchGateways } from '../../actions/apiCalls';
import IconSelector from "../Gui/IconSelector";
import TrackingMap from "../Map/TrackingMap";
import mqtt from 'mqtt'
import { withSnackbar } from "notistack";
import { fetchZones } from '../../actions/apiCalls';
import OutlinedSelect from "../Gui/OutlinedSelect";
import { withTranslation } from "react-i18next";
import FilterTextFiled from "../Gui/FilterTextField";
import { isEmpty, isUUID } from "../../utils";
import { Loader } from "../Gui/Loader";

class Tracking extends Component {

    constructor(){
        super();
        this.state = {
            floorId: "",
            showDefaultFloor: true,
            beaconTemplateFilterId: "",
            beaconFilterId: "",
            floor: null,
            mapCenter: { lat: 54.370765, lng: 18.613733},
            mapMounted: false,
            _isMounted: false,
            shownBeacons:[],
            shownGateways:[],
            shownZones: [],
            shownRn:[],
            purgePlan: false,
            clearBeacons: false,
            clearDevices: false,
            setCenter: false,
            beaconName: "",
            beaconNames: [],
            centerMapOnFoundBeacon: false
        };
        this.mqttWebsocket = mqtt.connect(process.env.REACT_APP_WSS_URL)
    };

    componentDidMount(){
        if(this.props.projectId){
            this.props.fetchProjectDevices(this.props.projectId);
            this.props.fetchProjectBeaconTemplates(this.props.projectId);
            this.setState({_isMounted: true }, () => this.connectMqtt());
        }
        else
            this.props.history.push('/');
    };
    
    componentDidUpdate = () => {
        if(!isEmpty(this.props.locationsMeta)){
            // Once select default floor
            if(this.state.mapMounted && this.state.showDefaultFloor){
                let defId = this.props.locationsMeta.defaultFloorId
                let e = {};
                e.target = {};
                e.target.value = defId;
                if(isUUID(defId)){
                    this.selectFloor(defId)
                }
            }
        }
    }

    isComponentReady = () => {
        // Component should be ready to render once all the api calls finish.
        return this.props.dataFetched
    }

    onMapMounted = () => {
       this.setState({mapMounted: true})
    };

    componentWillUnmount = () => {
        this.props.allowFetch();
        if (this.mqttWebsocket !== null){
            this.mqttWebsocket.end();
        }
    }

    notify = (message, variant) => {
        this.props.enqueueSnackbar(message, { 
            variant: variant,
        });          
    }

    connectMqtt = () => {
        this.mqttWebsocket.on('connect', function () {
            this.mqttWebsocket.subscribe('beacon/event/status', function (err) {
                if (!err) {
                    this.notify(this.props.t('tracking.success'), "success");
                }
                else{
                    this.notify(this.props.t('tracking.warrning'), "warning");
                }
            }.bind(this))
        }.bind(this))
        this.mqttWebsocket.on('error', function () {
            this.notify(this.props.t('tracking.error'), "error");
        }.bind(this))
        this.mqttWebsocket.on('message', function (topic, message) {
            this.receiveMessage(message.toString())
        }.bind(this))
        this.mqttWebsocket.on('end', function () {
            this.notify(this.props.t('tracking.disconnect'), "info")
        }.bind(this))
    }
    
    receiveMessage = (message) => { 
        // Data must've already been fetched
        if (!this.props.dataFetched)
            return;
        try{
            let messData = JSON.parse(message).data
            let beaconId = messData.id
            let floorId = messData.attributes.floorId
            
            if( this.state.beaconFilterId ){
                if( beaconId !== this.state.beaconFilterId ){
                    return
                }
                else if (this.state.centerMapOnFoundBeacon){
                    this.selectFloor(floorId, {lat: messData.attributes.latitude, lng: messData.attributes.longitude})
                }
            }
            if (floorId === this.state.floorId){
                // Szukamy beaona z wiadomości w beaconach projektu
                let index = this.props.beacons.findIndex(x => x.id === beaconId)
                if ( index !== -1 ){
                    let arr = this.state.shownBeacons.slice(0)

                    if( this.state.beaconTemplateFilterId ){
                        let rel = this.props.beacons[index].relationships
                        let btData = rel.beaconTemplate.data
                        if ( btData.id !== this.state.beaconTemplateFilterId ){
                            return
                        }
                    }

                    // Setting Mac and Icon
                    messData.attributes.MAC = this.props.beacons[index].attributes.MAC;
                    messData.attributes.name = this.props.beacons[index].attributes.name;
                    if (this.props.beacons[index].attributes.defaultIcon && this.props.beacons[index].attributes.defaultIconUrl){
                        messData.attributes.iconUrl = this.props.beacons[index].attributes.defaultIconUrl;
                    }
                    if (this.props.beacons[index].attributes.icon && this.props.beacons[index].attributes.iconUrl){
                        messData.attributes.iconUrl = this.props.beacons[index].attributes.iconUrl;
                    }
                    arr[index] = messData;
                    this.setState({shownBeacons: arr, clearDevices: false, setCenter: false, clearBeacons: false});
                }  
            }
        }
        catch{
            console.log("Error during message recieve")
        }
    }
    
    resetBeacons = () => {
        let length = this.props.beacons.length
        let beaconLocations = new Array(length)
        this.setState({
            shownBeacons: beaconLocations,
            clearBeacons: true
        })
    }

    onCertainBeaconSelect = (uuid) => {
        this.resetBeacons();
        this.setState({
            beaconFilterId: uuid,
            beaconTemplateFilterId: "",
            centerMapOnFoundBeacon: true,
        })
    }

    onCertainBeaconTypeSelect = (e) => {
        this.resetBeacons();
        this.setState({
            beaconTemplateFilterId: e.target.value,
            beaconFilterId: "",
            beaconName: "",
            setCenter: false,
        })
    }

    onFilterNameChange = (name) => {
        let beaconList = this.appendList(name);
        this.setState({
            beaconName: name,
            beaconNames: beaconList,
            setCenter: false,
        })
    };

    appendList = (name) => {
        let beaconData = []
        let newBeaconData = []
        let strLen = name.length;
        this.props.beacons.map((data, key) =>
            beaconData.push(data) 
        )
        for(let i = 0; i < beaconData.length; i++){
            if((beaconData[i].attributes.name.substring(0, strLen).toLowerCase() === name.toLowerCase())){
                newBeaconData.push(beaconData[i]);
                if(beaconData[i].attributes.name.toLowerCase() === name.toLowerCase()){
                    return [beaconData[i]];
                }
            }
        }
        return newBeaconData;
    };

    chooseBeacon = (name) => {
        this.setState({
            beaconName: name,
            setCenter: false,
        });
    };

    clearFilter = () => {
        this.setState({
            beaconTemplateFilterId: "",
            beaconFilterId: "",
            beaconName: "",
            setCenter: false,
        })
    }

    selectFloor = (floorId, optionalCenter = null) => {
        this.resetBeacons();

        let location = this.props.locations.find(location =>
            location.buildings.find(building =>
                building.floors.find(floor =>
                    floor.id === floorId
                )
            )
        )

        let shownZones = this.props.zones.filter(function(zone){
            return zone.relationships.floor.data.id === floorId
        })
        
        let shownGateways = this.props.gateways.filter(function(gateway){
            return gateway.relationships.floor.data.id === floorId
        })

        let shownReferenceNodes = this.props.referenceNodes.filter(function(referenceNode){
            return referenceNode.relationships.floor.data.id === floorId
        })

        if(location){
            let floor = null
            this.props.locations.map(l => l.buildings.map(b => b.floors.map(f=> f.id === floorId ? (floor = f):(null))))
            if(this.state._isMounted){
                if(floor && floor.planUrl && floor.boundsNE && floor.boundsSW){
                    this.setState({floorId: floorId,
                        mapCenter: optionalCenter ? optionalCenter: {lat: location.attributes.latitude, lng: location.attributes.longitude},
                        position:{lat: location.attributes.latitude, lng: location.attributes.longitude}, floor: floor, purgePlan: true, clearDevices: true,
                        shownGateways: shownGateways,
                        shownRn: shownReferenceNodes,
                        shownZones: shownZones,
                        setCenter: true,
                        showDefaultFloor: false,
                        centerMapOnFoundBeacon: false
                    })
                }
                else{
                    this.setState({floorId: floorId,
                        mapCenter: optionalCenter ? optionalCenter: {lat: location.attributes.latitude, lng: location.attributes.longitude},
                        position:{lat: location.attributes.latitude, lng: location.attributes.longitude}, floor: null, purgePlan: true, clearDevices: true,
                        shownGateways: shownGateways,
                        shownRn: shownReferenceNodes,
                        shownZones: shownZones,
                        setCenter: true,
                        showDefaultFloor: false,
                        centerMapOnFoundBeacon: false
                    })
                }
            }
        }
        else{
            this.setState({showDefaultFloor: false, centerMapOnFoundBeacon: false})
        }
    }
    
    onFloorSelect = (e) => {
        this.setState({
            beaconFilterId: "",
            beaconName: "",
            beaconTemplateFilterId: "",
        })
        let floorId = e.target.value;
        this.selectFloor(floorId);
    }
    
    render(){
        const { t } = this.props
        if (!this.isComponentReady()) return( 
          <Loader />)
        
        return(
            <div>        
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <OutlinedSelect value={this.state.beaconTemplateFilterId} data={this.props.beaconTemplates} label={t("tracking.allFilter")} 
                                onChange={this.onCertainBeaconTypeSelect}/>
                            </Grid>
                            <Grid item xs={2}>
                                <FilterTextFiled onBeaconSelect={this.onCertainBeaconSelect} beaconList ={this.state.beaconNames} value={this.state.beaconName} 
                                onNameChange = {this.onFilterNameChange}  onChooseBeacon = {this.chooseBeacon}/>
                            </Grid>
                            <Grid item xs={5}>
                                <Button style={{height: "55px"}} color="secondary" onClick={this.clearFilter} variant="contained">
                                    {t("tracking.clearFilter")}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <IconSelector value={this.state.floorId} data={this.props.locations} label={t("tracking.floors")}
                                onChange={this.onFloorSelect} floors={true}/>
                            </Grid>
                        </Grid>
                    </Grid>{this.state._isMounted ?
                    (<Grid item xs={12}>
                        <TrackingMap mapCenter={this.state.mapCenter} floor={this.state.floor}
                        mapSetCenter={this.state.setCenter}
                        purgeOverlay={this.state.purgePlan}
                        purgeBeacons={this.state.clearBeacons}
                        bea={this.state.shownBeacons}
                        purgeDevices={this.state.clearDevices}
                        rn={this.state.shownRn}
                        gt={this.state.shownGateways}
                        zones={this.state.shownZones}
                        onMapMounted={this.onMapMounted} />
                    </Grid>)
                    : (<Grid item xs={12} />)}
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        locations: state.locations.locations,
        locationsMeta: state.locations.metadata,
        gateways: state.gateways.gateways,
        beacons: state.beacons.beacons,
        dataFetched: state.devices.fetched,
        referenceNodes: state.referenceNodes.referenceNodes,
        beaconTemplates: state.beacons.beaconTemplates,
        zones: state.zones.zones
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectBeaconTemplates(projectId) {
            dispatch(fetchBeaconTemplates(projectId));
        },
        fetchProjectDevices(projectId) {
            dispatch(fetchReferenceNodes(projectId))
            .then(() => dispatch(fetchZones(projectId))
            .then(() => dispatch(fetchBeacons(projectId))
            .then(() => dispatch(fetchGateways(projectId))
            .then(() => dispatch(fetchLocations(projectId))))).then(() => dispatch({type: "DATA_FETCHED"})));
        },
        allowFetch() {
            dispatch({type: "DATA_FETCHED_OUT"});
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withTranslation()(Tracking)));