const initialState = {
   fetched: false
}

export default function DataFetchReducer(state = initialState, action) {

    switch(action.type){
        case "DATA_FETCHED":
            return {
                ...state,
                fetched: true
            };
        case "DATA_FETCHED_OUT":
            return {
                ...state,
                fetched: false
            };
        default: return state;
    }
}