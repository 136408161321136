import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Route, Switch, withRouter } from 'react-router-dom'
import Login from '../Login/Login'
import Locations from '../Locations/Locations'
import Dashboard from '../Dashboard/Dashboard'
import { connect } from 'react-redux'
import AppBarDrawer from './AppBarDrawer'
import Gateways from '../Gateways/Gateways'
import Beacons from '../Beacons/Beacons'
import Templates from '../Templates/Templates'
import Tracking  from '../Tracking/Tracking'
import TrackingHistory from '../Tracking/TrackingHistory.js'
import ReferenceNodes from '../ReferenceNodes/ReferenceNodes'
import Settings from '../Settings/Settings'
import Zones from '../Zones/Zones'
import Policies from '../Policies/Policies';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import { refreshToken, fetchProjects, getGatewayStatus } from '../../actions/apiCalls'

const styles = theme => ({
	root: {
		display: 'flex',
		background: '#FFFFFF',
		minHeight: '100vh'
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	}
});

class App extends Component {

	state = {
		currentItem: 0,
		ready: false,
	}

	onListItemClicked = (route, id) => {
		this.setState({currentItem: id});
		this.props.history.push(route);
	}

	componentDidMount = () => {
		// We want the dashboard to be the starting page always
		this.props.history.push('/')
		 // On App start we check for token existance and expirety
		let token = sessionStorage.getItem('token')
		let projId = sessionStorage.getItem('selectedProject')
		// this.interval = setInterval(() => (this.props.sendGatewayStatus()), 1000 * 60 * 60); //trgger send update from UI
		if(token){
			// TODO PRZETESTUJ JESCZE Z BŁĘDAMI
			// Request a new token
			let res = refreshToken(token)
			res.then(r => {	
				let data = r.data.data;
				let newToken = data.token;
				let username = data.username;
				let email = data.email;
				let language = data.language;
				let isSuperAdmin = data.isSuperAdmin
				let userData = {
					data: {
						token: newToken,
						username: username,
						email: email,
						language: language,
						isSuperAdmin: isSuperAdmin
					}
				};
				this.props.authorizeRefreshedUser(userData, projId);
				this.setState({ready: true})
			}).catch(e => {
				sessionStorage.clear();
				this.setState({ready: true})
			})
		}else{
			sessionStorage.clear();
			this.setState({ready: true})
		}
	}
	
	componentDidUpdate = () => {
		let infoData;
		switch(true){
			case this.props.logError.length > 0:
				infoData = this.props.logError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearLogErrors();
				break;
			case this.props.locError.length > 0:
				infoData = this.props.locError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearLocErrors();
				break;
			case this.props.gatewaysError.length > 0:
				infoData = this.props.gatewaysError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearGatewaysError();
				break;
			case this.props.beaconsError.length > 0:
				infoData = this.props.beaconsError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearBeaconsError();
				break;
			case this.props.refNodesError.length > 0:
				infoData = this.props.refNodesError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearRefNodesError();
				break;
			case this.props.zoneError.length > 0:
				infoData = this.props.zoneError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearZoneError();
				break;
			case this.props.projError.length > 0:
				infoData = this.props.projError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearProjError();
				break;
			case this.props.policieError.length > 0:
				infoData = this.props.policieError[0]
				this.notify(this.props.t(infoData.notifyKey), infoData.type)
				this.props.clearPolicyError();
				break;
			default:
				break;
		}
	}
	//used for timInterval trigger
	// componentWillUnmount() {
	// 	clearInterval(this.interval);
	//   }

	notify = (message, type) => {
        this.props.enqueueSnackbar(message,  { 
            variant: type,
        });
    }

	render() {
		const { classes } = this.props
		if(this.state.ready) 
			if (!this.props.loggedIn)
				return (
					<Login />
				)
			else {
				return (
					<div className={classes.root}>
						<CssBaseline />
						<AppBarDrawer onItemClicked={this.onListItemClicked} currentItem={this.state.currentItem} userRole= {this.props.userRole}/>
						<div className={classes.content}>
							<div className={classes.toolbar} />
							<Switch>
								<Route exact path='/' component={Dashboard}></Route>
								<Route path='/locations' component={Locations}></Route>
								<Route path='/gateways' component={Gateways}></Route>
								<Route path='/beacons' component={Beacons}></Route>
								<Route path='/reference-nodes' component={ReferenceNodes}></Route>
								<Route path='/templates' component={Templates}></Route>
								<Route path='/tracking' component={Tracking}></Route>
								<Route path='/settings' component={Settings}></Route>
								<Route path='/zones' component={Zones}></Route>
								<Route path='/policies' component={Policies}></Route>
								<Route path='/tracking-history' component={TrackingHistory}></Route>
							</Switch>
						</div>
					</div>
				);
			}
		else{
			return <div />
		}
	}
}

const mapStateToProps = (state) => {
	return {
		loggedIn: state.login.userLoggedIn,
		logError: state.login.errors,
		locError: state.locations.notices,
		gatewaysError: state.gateways.notices,
		beaconsError: state.beacons.notices,
		refNodesError: state.referenceNodes.notices,
		projError: state.projects.notices,
		zoneError: state.zones.notices,
		policieError: state.policies.notices,
		userRole: state.projects.currentRole

	}
}

const mapDispatchToProps = (dispatch) => {
	return{
		clearLogErrors(){
            dispatch({type: 'LOGINS_CLEAR_ERRORS'})
		},
		clearLocErrors(){
			dispatch({type: 'LOCATION_CLEAR_NOTICES'})
		},
		clearGatewaysError(){
			dispatch({type: 'GATEWAY_CLEAR_NOTICES'})
		},
		clearBeaconsError(){
			dispatch({type: 'BEACONS_CLEAR_NOTICES'})
		},
		clearRefNodesError(){
			dispatch({type: 'REFERENCE_NODE_CLEAR_NOTICES'})
		},
		clearProjError(){
			dispatch({type: 'PROJECTS_CLEAR_NOTICES'})
		},
		clearZoneError(){
			dispatch({type: 'ZONES_CLEAR_NOTICES'})
		},
		clearPolicyError(){
			dispatch({type: 'POLICIES_CLEAR_NOTICES'})
		},
		sendGatewayStatus(){
			dispatch(getGatewayStatus())
		},
		authorizeRefreshedUser: (data, projectId) => {
			dispatch({type: 'LOGIN_USER', payload: {data: data }})
			dispatch(fetchProjects()).then(r => dispatch({type: 'SET_PREVOUSLY_ACTIVE_PROJECT', payload: projectId}))
		},
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(withSnackbar(withTranslation()(App)))));