const initialState = {
    userProjects: [],
    firmwares: [],
    currentProjectId: null,
    currentRole: "",
    notices: []
}

export default function Projects(state = initialState, action) {
    if(action.type === 'SELECT_PROJECT'){
        if(state.currentProjectId !== action.payload.projectId) {
            sessionStorage.setItem('selectedProject', action.payload.projectId)
            return {
                ...state,
                currentProjectId: action.payload.projectId,
                currentRole: action.payload.role,
                notices: [{type: "info", notifyKey: "snackbar.selectActiveProject"}]
            }
        }
        else return { ...state };
    }
    if(action.type === 'FETCH_PROJECTS_FIRST'){
        let projects = action.payload.data.data
        if( projects.length > 0 ){
            sessionStorage.setItem('selectedProject', projects[0].id)
            return {
                ...state,
                currentProjectId: projects[0].id,
                currentRole: projects[0].attributes.role,
                userProjects: projects 
            }
        }
        else
            return {
                ...state,
                userProjects: projects
            }
    }
    if(action.type === 'SET_PREVOUSLY_ACTIVE_PROJECT'){
        let proj = state.userProjects.find(project => project.id === action.payload)
        if(proj){
            return { ...state, currentProjectId: proj.id, currentRole: proj.attributes.role}
        }
        else{
            return { ...state }
        }
    }
    if(action.type === 'FETCH_PROJECTS'){
        let projects = action.payload.data
        return {
            ...state,
            userProjects: projects,
            notices: []
        }
    }
    if(action.type === 'FETCH_PROJECTS_FAILURE'){
        return {
            ...state,
            userProjects: [],
            currentProjectId: null,
            notices: [{type: "error", notifyKey: "snackbar.projectsFetchError"}]
        }
    }
    if (action.type === 'FETCH_PROJECT_FAILURE')
    {
        return{
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.projectFetchError"}]
        }
    }

    if (action.type === 'POST_PROJECTS')
    {
        return{
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.projectPostSuccess"}]
        }
    }
    if (action.type === 'POST_PROJECTS_FAILURE')
    {
        return{
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.projectPostError"}]
        }
    }

    if (action.type === 'DELETE_PROJECTS')
    {
        return{
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.projectDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_PROJECTS_FAILURE')
    {
        return{
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.projectDeleteError"}]
        }
    }


    if (action.type === 'PATCH_PROJECT')
    {
        return{
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.projectPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_PROJECT_FAILURE')
    {
        return{
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.projectPatchError"}]
        }
    } 

    if (action.type === 'FETCH_FIRMWARE'){
        let firmwares = action.payload.data;
        return{
            ...state,
            firmwares: firmwares
        }
    }

    if (action.type === 'FETCH_FIRMWARE_FAILURE'){
        return{
            ...state,
            firmwares: [],
            notices: [{type: "error", notifyKey: "snackbar.firmwareFetchError"}]
        }
    }
    
    if (action.type === 'PROJECTS_CLEAR_NOTICES')
    {
        return{
            ...state,
            notices: []
        }
    }
    return state
}
  