import React, { Component } from "react";
import IconTextField from '../Gui/IconTextField';
import IconSelector from '../Gui/IconSelector';
import SaveButton from '../Gui/SaveButton';
import { Grid } from '@material-ui/core';
import CancelButton from '../Gui/CancelButton';
import { connect } from 'react-redux';
import { fetchReferenceNodeTemplates, fetchLocations, fetchGateways, postReferenceNode, fetchReferenceNodes } from '../../actions/apiCalls';
import AnchorIcon from "mdi-react/AnchorIcon";
import TagTextOutlineIcon from "mdi-react/TagTextOutlineIcon";
import PlaceIcon from "@material-ui/icons/Place";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import DevicesMap from '../Map/DevicesMap';
import { withTranslation } from 'react-i18next';

class CreateReferenceNode extends Component{

    constructor(){
        super();
        this.state={
            name:"",
            mac:"",
            floor: null,
            floorId: "",
            templateId: "",
            mapCenter: { lat: 54.370765, lng: 18.613733},
            position: { lat: 54.370765, lng: 18.613733},
            refreshMap: false,
            shownGateways:[],
            shownReferenceNodes: []
        }
        this._mounted = false;
    }

    onNameChange = (e) =>{
        this.setState({name: e.target.value, refreshMap:false})
    }

    onMacChange = (e) =>{
        this.setState({mac: e.target.value, refreshMap:false})
    }

    onPositionChange = (pos) => {
        this.setState({position: pos, refreshMap: false})
    }

    onFloorSelect = (e) => {
        let floorId = e.target.value
        
        let location = this.props.locations.find(location =>
            location.buildings.find(building =>
                building.floors.find(floor =>
                    floor.id === floorId
                )
            )
        )

        let shownGateways = this.props.gateways.filter(function(gateway){
            return gateway.relationships.floor.data.id === floorId
        })

        let shownReferenceNodes = this.props.referenceNodes.filter(function(referenceNode){
            return referenceNode.relationships.floor.data.id === floorId
        })

        if(location){
            let floor = null
            this.props.locations.map(l => l.buildings.map(b => b.floors.map(f=> f.id === floorId ? (floor = f):(null))))
            if(this._mounted){
                if(floor && floor.planUrl && floor.boundsNE && floor.boundsSW){
                    this.setState({
                        floorId: floorId,
                        mapCenter:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                        position:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                        floor: floor,
                        refreshMap: true,
                        shownGateways: shownGateways,
                        shownReferenceNodes: shownReferenceNodes
                    })
                }
                else{
                    this.setState({
                        floorId: floorId,
                        mapCenter:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                        position:{lat: location.attributes.latitude, lng: location.attributes.longitude}, 
                        floor: null, 
                        refreshMap: true,
                        shownGateways: shownGateways,
                        shownReferenceNodes: shownReferenceNodes
                    })
                }
            }
        }
    }

    onTemplateChange = (e) => {
        this.setState({templateId: e.target.value, refreshMap:false})
    }

    componentDidMount = () => { 
        try{
            if(this.props.projectId){
                this.props.fetchProjectLocations(this.props.projectId);
                this.props.fetchProjectReferenceNodes(this.props.projectId);
                this.props.fetchProjectReferenceNodeTemplates(this.props.projectId)
                this.props.fetchProjectGateways(this.props.projectId)
                this._mounted = true;
            }
            else
                this.props.onClose();
        }
        catch(e){
            console.log(e)
        }
    }

    submitReferenceNode = () => {
        let data = {
            name: this.state.name,
            MAC: this.state.mac,
            latitude: this.state.position.lat,
            longitude: this.state.position.lng,
            templateId: this.state.templateId,
            floorId: this.state.floorId
        }
        if (this.props.projectId){
            this.props.postReferenceNode(this.props.projectId, data);
        }
        this.props.onClose();
    }

    render(){
        const { t } = this.props
        return(
            this._mounted ? (
                <form onSubmit={e => e.preventDefault()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                                <Grid item>
                                    <CancelButton onClick={this.props.onClose}/>
                                </Grid>
                                <Grid item>
                                    <SaveButton onClick={this.submitReferenceNode} 
                                    disabled={this.state.name && this.state.mac.length === 12 && this.state.position && this.state.floorId && this.state.templateId ? false: true} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={3}>
                                <IconTextField onChange={this.onNameChange} label={t("referenceNodes.name")} icon={<AnchorIcon />}/>
                                <IconTextField onChange={this.onMacChange} helperText={t("referenceNodes.macInfo")} label={t("referenceNodes.mac")} icon={<TagTextOutlineIcon />}/>
                                <IconSelector value={this.state.floorId} data={this.props.locations} labelWidth={65} label={t("referenceNodes.location")}
                                    icon={<PlaceIcon />} onChange={this.onFloorSelect} floors={true}/>
                                <IconSelector value={this.state.templateId} data={this.props.referenceNodeTemplates} labelWidth={165} label={t("referenceNodes.configurationTemplate")}
                                icon={<FileDocumentIcon />} onChange={this.onTemplateChange}/>
                   
                            </Grid> 
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={12}>
                                <DevicesMap mapCenter={this.state.mapCenter} 
                                refreshMap={this.state.refreshMap} 
                                floor={this.state.floor}
                                position={this.state.position} 
                                gateways={this.state.shownGateways}
                                referenceNodes={this.state.shownReferenceNodes}
                                onChange={this.onPositionChange} /> {/* <- This is a new version */}
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                ):(
                    <form onSubmit={e => e.preventDefault()}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                                    <Grid item>
                                        <CancelButton onClick={this.props.onClose}/>
                                    </Grid>
                                    <Grid item>
                                        <SaveButton disabled={true} />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                        </Grid>
                    </form>
                )
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        referenceNodes: state.referenceNodes.referenceNodes,
        referenceNodeTemplates: state.referenceNodes.referenceNodeTemplates,
        locations: state.locations.locations,
        gateways: state.gateways.gateways,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectLocations(projectId) {
            dispatch(fetchLocations(projectId))
        },
        fetchProjectReferenceNodes(projectId){
            dispatch(fetchReferenceNodes(projectId))
        },
        fetchProjectReferenceNodeTemplates(projectId){
            dispatch(fetchReferenceNodeTemplates(projectId))
        },
        fetchProjectGateways(projectId){
            dispatch(fetchGateways(projectId))
        },
        postReferenceNode(projectId, data){
            dispatch(postReferenceNode(projectId, data)).then(() => dispatch(fetchReferenceNodes(projectId)));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateReferenceNode));