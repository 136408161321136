import React, { Component } from 'react'
import scriptLoad from '../../scriptLoader'
import resizeIcon from './ResizeIcon.svg'
import positionChangeIcon from './PositionChange.svg'
import RotatableOverlay from './RotatableOverlay'

class LocationMap extends Component{
    
    constructor(){
        super()
        this.rotation = 0
        this.map = null
        this.markerCenter = null
        this.markerNE = null
        this.markerSW = null
        this.overlay = null
        this.floorOnMap = false
        this.prevPosition = null
        this.slider = null
        this.addSlider = true
    }
    
    componentDidMount(){
        if(!(typeof window.google === 'object' && typeof window.google.maps === 'object')){
            scriptLoad("https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_KEY+"&v=3.exp&libraries=geometry,drawing,places").then(res => this.initMap())
        }
        else{
            this.initMap()
        }

    }

    createRotationSlider = () => {
        let slider = document.createElement('input');
        slider.type = 'range';
        slider.min=0;
        slider.max=360;
        slider.value=this.props.floor.rotation;
        return slider
    }

    initMap = () => {
        this.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 19,
              center: this.props.mapCenter
            }
        )
    }

    getCenterFromMarkers = (markerNE, markerSW) => {
        let neLat = markerNE.lat()
        let neLng = markerNE.lng()
        
        let swLat = markerSW.lat()
        let swLng = markerSW.lng()

        return {lat: (neLat + swLat)/2, lng: (neLng + swLng)/2}
    }

    getPositionDifference = (newPosition, prevPosition) => {
        let nLat = newPosition.lat()
        let nLng = newPosition.lng()
        
        let pLat = prevPosition.lat()
        let pLng = prevPosition.lng()
        return {lat: (nLat - pLat), lng: (nLng - pLng)}
    }

    handleMarkerDrag = () => {
        this.overlay.setMap(null)
        let neLat = this.markerNE.getPosition().lat()
        let neLng = this.markerNE.getPosition().lng()

        let swLat = this.markerSW.getPosition().lat()
        let swLng = this.markerSW.getPosition().lng()

        let imageBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(swLat, swLng),
            new window.google.maps.LatLng(neLat, neLng)
        )
        
        let centerPosition = this.getCenterFromMarkers(this.markerNE.getPosition(), this.markerSW.getPosition())
        if(this.markerCenter)
            this.markerCenter.setPosition(centerPosition)

        this.overlay = new RotatableOverlay(imageBounds, this.props.floor.planUrl, this.map, this.props.floor.rotation)
        this.overlay.onAdd()
    }

    moveMarkersByDiff = (latLngDiff) => {
        let neLat = this.markerNE.getPosition().lat() + latLngDiff.lat
        let neLng = this.markerNE.getPosition().lng() + latLngDiff.lng

        let swLat = this.markerSW.getPosition().lat() + latLngDiff.lat
        let swLng = this.markerSW.getPosition().lng() + latLngDiff.lng

        this.markerNE.setPosition({lat: neLat, lng: neLng})
        this.markerSW.setPosition({lat: swLat, lng: swLng})
    }

    createDefaultNESW = () => {
        let bounds = this.map.getBounds()
        let ne = {lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng()}
        let sw = {lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng()}
        
        let center = this.getCenterFromMarkers(bounds.getNorthEast(), bounds.getSouthWest())
        ne = ({'lat': (ne.lat + center.lat)/2, lng: (ne.lng + center.lng)/2})
        sw = ({'lat': (sw.lat + center.lat)/2, lng: (sw.lng + center.lng)/2})
        return {ne: ne, sw: sw}
    }

    buildCustomOverlayView = () => {
        // Libraries do not work i need to create custom OverlayView
        RotatableOverlay.prototype = new window.google.maps.OverlayView()
        
        let ne = null
        let sw = null
        if(this.props.floor.boundsNE && this.props.floor.boundsSW ){
            let neLat = this.props.floor.boundsNE.lat
            let neLng = this.props.floor.boundsNE.lng
            let swLat = this.props.floor.boundsSW.lat
            let swLng = this.props.floor.boundsSW.lng
            if (neLat && neLng && swLat && swLng){
                ne = {lat: this.props.floor.boundsNE.lat, lng: this.props.floor.boundsNE.lng}
                sw = {lat: this.props.floor.boundsSW.lat, lng: this.props.floor.boundsSW.lng}
            }
            else{
                let bounds = this.createDefaultNESW();
                ne = bounds.ne;
                sw = bounds.sw;
            }
        }else{
            let bounds = this.createDefaultNESW();
            ne = bounds.ne;
            sw = bounds.sw;
        }
        
        let imageBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(sw.lat, sw.lng),
            new window.google.maps.LatLng(ne.lat, ne.lng)
        )

        this.overlay = new RotatableOverlay(imageBounds, this.props.floor.planUrl, this.map, this.props.floor.rotation);

        this.markerNE = new window.google.maps.Marker({
            position: ne,
            icon: {url: resizeIcon},
            map: this.map,
            draggable: true
        })

        this.markerSW = new window.google.maps.Marker({
            position: sw,
            icon: {url: resizeIcon},
            map: this.map,
            draggable: true
        })

        if(this.markerNE && this.markerSW){
            let centerPosition = this.getCenterFromMarkers(this.markerNE.getPosition(), this.markerSW.getPosition())

            this.markerCenter = new window.google.maps.Marker({
                position: centerPosition,
                icon: {url: positionChangeIcon},
                map: this.map,
                draggable: true
            })
            this.prevPosition = this.markerCenter.getPosition()
            new window.google.maps.event.addListener(this.markerCenter, 'drag', function(){
                let newPosition = this.markerCenter.getPosition()
                let latLngDiff = this.getPositionDifference(newPosition, this.prevPosition)

                if(this.markerNE && this.markerSW){
                    this.moveMarkersByDiff(latLngDiff)
                    this.handleMarkerDrag()
                }

                this.prevPosition = newPosition
            }.bind(this))

            new window.google.maps.event.addListener(this.markerCenter, 'dragend', function(){
                if(this.markerNE && this.markerSW){
                    let centerPosition = this.getCenterFromMarkers(this.markerNE.getPosition(), this.markerSW.getPosition())
                    this.markerCenter.setPosition(centerPosition)
                }
                this.prevPosition = this.markerCenter.getPosition()
                this.inputBounds();
            }.bind(this))
        }
        

        new window.google.maps.event.addListener(this.markerNE, 'drag', function(){
            this.handleMarkerDrag()
            this.prevPosition = this.markerCenter.getPosition()
        }.bind(this))

        new window.google.maps.event.addListener(this.markerNE, 'dragend', function(){
            this.handleMarkerDrag()
            this.prevPosition = this.markerCenter.getPosition()
            this.inputBounds();
        }.bind(this))

        new window.google.maps.event.addListener(this.markerSW, 'drag', function(){
            this.handleMarkerDrag()
            this.prevPosition = this.markerCenter.getPosition()
        }.bind(this))

        new window.google.maps.event.addListener(this.markerSW, 'dragend', function(){
            this.handleMarkerDrag()
            this.prevPosition = this.markerCenter.getPosition()
            this.inputBounds();
        }.bind(this))
        
        this.floorOnMap = true
    }

    inputRotation = (e) => {
        this.props.changeRotation(e.target.value)
    }

    inputBounds = () => {
        let ne = this.markerNE.getPosition();
        let sw = this.markerSW.getPosition();
        this.props.changeBounds(ne.lat(), ne.lng(), sw.lat(), sw.lng());
    }

    purge = () => {
        this.floorOnMap= false
        if(this.markerNE){
            this.markerNE.setMap(null)
            this.markerNE = null
        }
        if(this.markerSW){
            this.markerSW.setMap(null)
            this.markerSW = null
        }
        if(this.markerCenter){
            this.markerCenter.setMap(null)
            this.markerCenter = null
        }
        if(this.overlay)
            this.overlay.setMap(null)
    }

    componentDidUpdate(){
        if(this.map){
            if(this.props.centerChanged)
                this.map.setCenter(new window.google.maps.LatLng(this.props.mapCenter))
            if(this.props.floor){
                if(this.floorOnMap && this.props.purge){
                    this.purge()
                }
                if(this.props.floor.planUrl && !this.floorOnMap){
                    if(this.addSlider){
                        this.slider = this.createRotationSlider();
                        this.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(this.slider);
                        this.slider.addEventListener("input", this.inputRotation)
                        this.addSlider = false
                    }
                    this.slider.value=this.props.floor.rotation;
                    this.buildCustomOverlayView()
                }
                else{
                    if(this.slider){
                        this.map.controls[window.google.maps.ControlPosition.TOP_CENTER].pop(this.slider)
                        this.slider = null
                        this.addSlider = true
                    }
                }
            }
            else{
                this.purge();
                if(this.slider){
                    this.map.controls[window.google.maps.ControlPosition.TOP_CENTER].pop(this.slider)
                    this.slider = null
                    this.addSlider = true
                }
            }
        }
    }

    render(){
        return(
            <div>
                <div id="googleMap" style={{width: "100%", height: '750px'}}/>
            </div>
        )
    }
}

export default LocationMap


