import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchBeaconTemplates, fetchGatewayTemplates, fetchReferenceNodeTemplates } from '../../actions/apiCalls';
import { deleteBeaconTemplates, deleteGatewayTemplates, deleteReferenceNodeTemplates } from '../../actions/apiCalls';
import CreateTemplate from './CreateTemplate';
import EditTemplate from "./EditTemplate";
import { withTranslation } from 'react-i18next';
import { Route, Switch } from "react-router-dom";
import TemplatePanel from './TemplatesPanel.js'

class Templates extends Component{

    constructor(){
        super()
        this.state = {
            templateId: "",
            currTemplateType: ""
        };
    };

    componentDidMount(){
        if(this.props.projectId)
            this.props.fetchTemplates(this.props.projectId)
        else
            this.props.history.push('/')
    };

    openCreate = () => {
        this.props.history.push(this.props.match.url + '/create')
    };

    openEdit = (e, templateId, type) => {
        this.props.history.push(this.props.match.url+'/edit')
        switch (type){
            case "Reference Node":
                this.setState({templateId: templateId, currTemplateType: "Reference Node"})
                    break;
            case "Beacon":
                this.setState({templateId: templateId, currTemplateType: "Beacon"})
                break;
            case "Gateway":
                this.setState({templateId: templateId, currTemplateType: "Gateway"})
                break;
            default:
                break;
        };
    };

    deleteBeacon = (e, templateId, type) => {
        e.stopPropagation();
        switch (type){
            case "Reference Node":
                this.props.deleteReferenceNodeTemplates(this.props.projectId, templateId);
                    break;
            case "Beacon":
                this.props.deleteBeaconTemplates(this.props.projectId, templateId);
                break;
            case "Gateway":
                this.props.deleteGatewayTemplates(this.props.projectId, templateId);
                break;
            default:
                break;
        };
    };

    closeForm = () => {
        this.props.history.push('/templates')
    };

    render(){
        const { t } = this.props
        const attributes = ["name", "type"];
        const headers = [t("templates.name"), t("templates.type")];
        const data = [];
        const referenceNodeTemplates = this.props.referenceNodeTemplates;
        const gatewayTemplates = this.props.gatewayTemplates;
        const beaconTemplates = this.props.beaconTemplates;

        referenceNodeTemplates.map(rnt => {
            rnt.attributes.type = "Reference Node"
            return data.push(rnt)
        })
        gatewayTemplates.map(gt => {
            gt.attributes.type = "Gateway"
            return data.push(gt)
        })
        beaconTemplates.map(bea => {
            bea.attributes.type = "Beacon"
            return data.push(bea)
        })

        return(
            <div>
                <Switch>
                    <Route exact path={'/templates'}>
                        <TemplatePanel data={data} name={t('template', {count: 5})}
                            onItemDelete={this.deleteBeacon}
                            onItemClick={this.openEdit}
                            openCreate={this.openCreate}
                            attributes={attributes}
                            headers={headers}
                        />
                    </Route>
                    <Route path={this.props.match.url+'/create'}>
                        <CreateTemplate onClose={this.closeForm}/>
                    </Route>
                    <Route path={this.props.match.url+'/edit'}>
                        <EditTemplate onClose={this.closeForm} templateId={this.state.templateId} type={this.state.currTemplateType}/>
                    </Route>
                </Switch>
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        beaconTemplates: state.beacons.beaconTemplates,
        referenceNodeTemplates: state.referenceNodes.referenceNodeTemplates,
        gatewayTemplates: state.gateways.gatewayTemplates
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTemplates(projectId) {
            dispatch(fetchBeaconTemplates(projectId))
            .then(() => dispatch(fetchGatewayTemplates(projectId)))
            .then(() => dispatch(fetchReferenceNodeTemplates(projectId)));
        },
        deleteReferenceNodeTemplates(projectId, templateId){
            dispatch(deleteReferenceNodeTemplates(projectId, templateId)).then(
                () => dispatch(fetchReferenceNodeTemplates(projectId)
            ));
        },
        deleteBeaconTemplates(projectId, templateId){
            dispatch(deleteBeaconTemplates(projectId, templateId)).then(
                () => dispatch(fetchBeaconTemplates(projectId)
            ));
        },
        deleteGatewayTemplates(projectId, templateId){
            dispatch(deleteGatewayTemplates(projectId, templateId)).then(
                () =>  dispatch(fetchGatewayTemplates(projectId)
            ));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Templates));
