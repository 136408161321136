import React, { Component } from "react";
import { Grid, TextField } from "@material-ui/core";
import SaveButton from "../Gui/SaveButton";
import CancelButton from "../Gui/CancelButton";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'
import { withTranslation } from 'react-i18next';

class NewBuildingForm extends Component {

    constructor(){
        super();
        this.state = {
            disabled: true,
            name: ""
        };
    };

    handleBulidingNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    submitBuilding = () => {
        this.props.submit(this.state.name);
        this.props.closeForm();
    };

    render(){
        const { t } = this.props
        return(
            <Grid item xs={12}>
                <Grid container alignItems="flex-start" justify="flex-end"  spacing={3}>
                    <Grid item xs={1}/>
                    <Grid item xs={11}>
                        <MuiThemeProvider theme={IstokTheme}>
                            <TextField label={t("locations.name")} onChange={this.handleBulidingNameChange} fullWidth variant="outlined" />
                        </MuiThemeProvider>
                    </Grid>
                    <Grid item>
                        <CancelButton onClick={this.props.closeForm}/>
                    </Grid>
                    <Grid item>
                        <SaveButton disabled={!this.state.name.length > 0} onClick={this.submitBuilding}/>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
};

export default withTranslation()(NewBuildingForm);