const pl = {
    "location_0": "lokacja",
    "location_1": "lokacje",
    "location_2": "lokacji",
    "gateway_0": "gateway" ,
    "gateway_1": "gateway'e" ,
    "gateway_2": "gateway'ów" ,
    "beacon_0": "beacon",
    "beacon_1": "beacon'y",
    "beacon_2": "beacon'ów",
    "refNode_0": "węzeł referencyjny",
    "refNode_1": "węzły referencyjne",
    "refNode_2": "węzłów referencyjnych",
    "template_0": "wzorzec",
    "template_1": "wzorce",
    "template_2": "wzorców",
    "zone_0": "strefa",
    "zone_1": "strefy",
    "zone_2": "stref",
    "policy_0": "zasada",
    "policy_1": "zasady",
    "policy_2": "zasad",
    "common": {
        "Welcome to React": "AAA KURWAAAt",
        "title": "MPS",
        "logout": "Wyloguj",
        "saveButton": "ZAPISZ",
        "cancelButton": "ANULUJ",
        "deleteButton": "KASUJ",
        "warrning": "Ostrzeżenie",
        "deletePrompt": "Czy na pewno chcesz skasować ten obiekt?",
        "deleteProjectPrompt": "Skasowanie projektu skasuje wszystkie powiązane z nim obiekty. Czy na pewno chesz to zrobić?",
        "disconnectProjectPrompt": "Czy na pewno chcesz przestać brać udział w projekcie?",
        "removeTemplateWarning": "Skasowanie wzorca skasuje wszystkie powiązane z nim obiekty. Czy na pewno chcesz to zrobić?",
        "removeLocationWarning": "Usunięcie lokacji skasuje również wszystkie znajdujące się w niej budynki, piętra i urządzenia. Czy na pewno chcesz to zrobić?",
        "removeBuildingFloorWarning": "Skasowanie budynków bądź pięter skasuje wszystkie powiązane z nimi urzadzenia. Czy na pewno chcesz to zrobić?",
        "rpp": "Wiersze na strone"
    },
    "sidebar": {
        "Dashboard": "Nawigacja",
        "Locations": "Lokacje",
        "Gateways": "Gateway'e",
        "Beacons": "Beacon'y",
        "Nodes": "Węzły",
        "Templates": "Wzorce",
        "Tracking": "Śledzenie",
        "Zones": "Strefy",
        "Policies": "Zasady",
        "Settings": "Ustawienia",
        "TrackingHistory": "Historia"
    },
    "settings":{
        "projects":{
            "projects": "Projekty",
            "projectName": "Nazwa projektu"
        },
        "role":{
            "role": "Rola",
            "regularUser": "Zwykły użytkownik",
            "admin": "Admin"
        },
        "personal":{
            "personal": "Użytkownik",
            "language": "Język",
            "username": "Użytkownik",
            "changePassword": "Zmień hasło",
            "password": "Hasło",
            "oldPassword": "Stare hasło",
            "newPassword": "Nowe hasło",
            "rePassword": "Powtórz hasło"
        },
        "firmwareVersion":{
            "firmwareVersion": "Wersja oprogramowania"
        },
        "errors":{
            "errorMsg": "Nowe hasło i powtórzone nie są takie same."
        }
    },
    "createProject":{
        "projName": "Nazwa projektu",
        "projDescription": "Opis projektu",
        "locAlgorithm": "Algorytm lokalizacji",
        "addUser": "Dodaj użytkownika",
        "firmwareVersion": "Wersja oprogramowania"
    },
    "algorithm":{
        "esparOnly": "Tylko Espar",
        "refNodes": "Węzły referencyjne"
    },
    "zones":{
        "title": "Strefy",
        "name": "Nazwa",
        "location": "Lokacja"
    },
    "policies": {
        "title": "Zasady",
        "name": "Nazwa",
        "location": "Lokacja",
        "type": "Typ",
        "temperature": "Temperatura",
        "sendEmail": "Wyślij wiadomość",
        "soundAlert": "Komunikat dźwiękowy",
        "above": "Powyżej",
        "below": "Poniżej",
        "enters": "Wchodzi",
        "leaves": "Opuszcza",
        "celsius": "Celsjusze",
        "fahrenheit": "Fahrenheit'y",
        "action": "Dodaj akcje",
        "addBeacon": "Dodaj beacon",
        "config": "Konfiguruj",
        "webhook": {
            "newWebhook": "Nowy WebHook",
            "contType": "Rodzaj zawartości",
            "addHeader": "Dodaj nagłówek",
            "header": "Nagłówek",
            "headerText": "Tekst nagłówka"
        }
    },
    "beacons": {
        "title": "Beacon'y",
        "empty": "Brak dostępnych beacon'ów",
        "name": "Nazwa",
        "mac": "MAC",
        "macInfo": "MAC musi składać się z 12 znaków",
        "configurationTemplate": "Wzorzec beacon'a",
        "icon": "Ikona",
        "uploadBeaconButton": "WGRAJ",
        "battery": "Bateria"
    },
    "dashboard": {
        "title": "Nawigacja",
        "prompt": "By kontynuować wybierz lub utwórz projekt w ustawieniach",
        "slogan": "Pozycjonowanie wewnątrz nigdy nie było tak proste",
    },
    "gateways": {
        "title": "Gateway'e",
        "empty": "Brak dostępnych gateway'ów",
        "name": "Nazwa",
        "location": "Lokacja",
        "configurationTemplate": "Wzorzec konfiguracyjny",
        "building": "Budynek",
        "floor": "Piętro",
        "bleFirmware": "Oprogramowanie BLE",
        "firmware": "Oprogramowanie",
        "status": "Status",
        "latitude": "Szerokość geograficzna",
        "longitude": "Wysokość geograficzna",
        "updateWithMac": "Wyślij Filtry Mac Do Gateway'ów "
    },
    "locations": {
        "title": "Lokacje",
        "empty": "Brak dostępnych lokacji",
        "name": "Nazwa",
        "address": "Adres",
        "description": "Opis",
        "newBuildingButton": "Dodaj budynek",
        "uploadPlanButton": "UPLOAD",
        "newFloorTitle": "Nowe pietro dla ",
        "updateFloorTitle": "Edycja pietra dla ",
        "floorNr": "Nr pietra",
        "floorPlan": "Plan pietra",
        "floors": "Piętra",
        "devices": "Urządzenia"
    },
    "login": {
        "title": "MPS",
        "prompt": "Zaloguj się",
        "username": "Użytkownik",
        "password": "Hasło",
        "signIn": "ZALOGUJ SIĘ",
        "signUp": "Zarejestruj się",
        "forgotPassword": "Zapomniałeś/aś hasła",
        "noAccount": "Nie masz konta"
    },
    "register": {
        "userName": "Nazwa Użytkownika",
        "emailAdress": "Adres Email",
        "register": "Zarejestruj", 
        "errors": {
            "fiveChars": "Co najmniej 5 znaków",
            "emailFormula": "W adresie emial brakuje @"
        }
    },
    "referenceNodes": {

        "title": "Węzły referencyjne",
        "empty": "Brak dostępnych węzłów referencyjnych",
        "name": "Nazwa",
        "mac": "MAC",
        "macInfo": "MAC musi składać się z 12 znaków",
        "location": "Lokacja",
        "configurationTemplate": "Wzorzec konfiguracyjny",
    },
    
    "templates": {
        "title": "Wzorce urządzeń",
        "empty": "Brak dostępnych wzorców urządzeń",
        "type": "Typ",
        "name": "Nazwa",
        "securityType": "Typ zabezpieczeń",
        "ssid": "SSID",
        "password": "Hasło",
        "interval": "Interwał (ms)",
        "gatewayType": "Gateway",
        "beaconType": "Beacon",
        "defaultIcon": "Ikona domyślna",
        "beaconTemplateName": "Typ beacona",
        "referenceNodeType": "Węzeł referencyjny"
    },
    "tracking": {
        "floors": "Piętra",
        "singleFilter": "Znajdź Beacon",
        "allFilter": "Znajdź Beacon'y typu",
        "clearFilter": "Wyczyść filtrowanie",
        "success": "Udane połączenie z serwerem WebSocket",
        "disconnect": "Odłączono od serwera Websocket",
        "warrning": "Nie można połączyć się z serwerem WebSocket ",
        "error": "Przy próbie połączenia z serwerem WebSocket wystąpił błąd ",
        "beacon": "Nazwa beacon'a"
    },
    "snackbar": {
        "imageTooLarge": "Wgrano za duzą ikonę, maksimum to 100kb.",
        // Locations
        "locationFetchError": "Nie udało się pobrać dostępnych lokacji",
        "locationFetchSingleError": "Nie udało sie pobrać wybranej lokacji",
        "locationPostSuccess": "Utworzono nowa lokację",
        "locationPostError": "Nie udało sie utworzyć nowej lokacji",
        "locationDeleteSuccess": "Lokacja została skasowana",
        "locationDeleteError": "Nie udało się skasować lokacji",
        "locationPutSuccess": "Uaktutalniono lokację",
        "locationPutError": "Nie udalo się uaktutalnić lokacji",
        // Gateways 
        "gatewayFetchError": "Nie udało sie pobrać wybranego gateway'a",
        "gatewaysFetchError": "Nie udało się pobrać dostępnych gateway'ów",
        "gatewaysTemFetchError": "Nie udało się pobrać wzorców gateway'ów",
        "gatewaysPostSuccess": "Stworzono nowy gateway",
        "gatewaysPostError": "Nie udało się stworzyć nowego gateway'a",
        "gatewayDeleteSuccess": "Gateway został skasowany",
        "gatewayDeleteError": "Nie udało się skasować gateway'a",
        "gatewayPatchSuccess": "Uaktualniono gateway",
        "gatewayPatchError": "Nie udało się uaktualnić gateway'a",
        "gatewaysUploadMacSuccess": "Uaktualniono listą MAC dla gateway'ów ",
        "gatewaysUploadMacError": "Nie udało się uaktualnić listy MAC dla gateway'ów",
        // GT
        "gatewaysTemPostSuccess": "Stworzono nowy wzorca gateway'a",
        "gatewaysTemPostError": "Nie udało się stworzyć nowego wzorca gateway'a",
        "gatewaysTemPatchSuccess": "Uaktualniono wzorzec gateway'a",
        "gatewaysTemPatchError": "Nie udało się uaktualnić wzorca",
        "gatewaysTemDeleteSuccess": "Skasowano wzorzec gateway'a",
        "gatewaysTemDeleteError": "Nie udało się skasować wzorca",
        "gatewayTemFetchError": "Nie udało sie pobrać wybranego wzorca",
        // Beacons
        "beaconsFetchError": "Nie udało się pobrać dostępnych beacon'ów",
        "beaconFetchError": "Nie udało sie pobrać wybranego beacon'a",
        "beaconsTemFetchError": "Nie udało się pobrać wzorców beacon'ów",
        "beaconsPostSuccess": "Stworzono nowy beacon",
        "beaconsPostError":  "Nie udało się stworzyć nowego beacon'a",
        "beaconsDeleteSuccess": "Beacon został skasowany",
        "beaconsDeleteError": "Nie udało się skasować beacon'a",
        "beaconsPatchSuccess": "Uaktualniono beacon'a",
        "beaconsPatchError": "Nie udało się uaktualnić beacon'a",
        // BT
        "beaconTemPostSuccess": "Stworzono nowy wzorzec beacon'a",
        "beaconTemPostError": "Nie udało się stworzyć nowego wzorca beacon'a",
        "beaconTemPatchSuccess": "Uaktualniono wzorzec beacon'a",
        "beaconTemPatchError": "Nie udało się uaktualnić wzorca",
        "beaconTemDeleteSuccess": "Skasowano wzorzec beacon'a",
        "beaconTemDeleteError": "Nie udało się skasować wzorca",
        "beaconTemFetchError": "Nie udało sie pobrać wybranego wzorca",
        // Reference nodes
        "refNodesFetchErrors": "Nie udało się pobrać dostępnych węzłów referencyjnych",
        "refNodeFetchErrors": "Nie udało sie pobrać wybranego węzła referencyjnego",
        "refNodesTemFetchErrors": "Nie udało się pobrać wzorców węzłów",
        "refNodesPostSuccess": "Stworzono nowy węzeł referencyjny",
        "refNodesPostErrors": "Nie udało się stworzyć węzła referencyjnego",
        "refNodesDeleteSuccess": "Węzeł referencyjny został skasowany",
        "refNodesDeleteErrors": "Nie udało się skasować węzła referencyjnego",
        "refNodesPatchSuccess": "Uaktualniono węzeł referencyjny",
        "refNodesPatchErrors":  "Nie udało się uaktualnić węzła referencyjnego",
        // RNT
        "refNodeTemPostSuccess":  "Stworzono nowy wzorzec węzła referencyjnego",
        "refNodeTemPostError":  "Nie udało się stworzyć nowego wzorca węzła",
        "refNodeTemPatchSuccess": "Uaktualniono wzorzec węzła'a",
        "refNodeTemPatchError": "Nie udało się uaktualnić wzorca węzła",
        "refNodeTemDeleteSuccess": "Skasowano wzorzec węzła",
        "refNodeTemDeleteError": "Nie udało się skasować wzorca",
        "refNodeTemFetchError": "Nie udało sie pobrać wybranego wzorca",
        // Zones
        "zonesFetchError": "Nie udało się pobrać dostępnych stref",
        "zoneFetchError": "Nie udało sie pobrać wybranej strefy",
        "zonesPostSuccess": "Stworzono nową strefę",
        "zonesPostError": "Nie udało się stworzyć nowej strefy",
        "zoneDeleteSuccess": "Strefa została skasowana",
        "zoneDeleteError": "Nie udało się skasować wybranej strefy",
        "zonePatchSuccess": "Uaktualniono strefę",
        "zonePatchError": "Nie udało się uaktualnić strefy",
        // Policies
        "policiesFetchError": "Nie udało się pobrać dostępnych zasad",
        "policyFetchError": "Nie udało sie pobrać wybranej zasady",
        "policyPostSuccess": "Stworzono nową zasadę",
        "policyPostError": "Nie udało się stworzyć nowej zasady",
        "policyDeleteSuccess": "Zasada została skasowana",
        "policyDeleteError": "Nie udało się skasować wybranej zasady",
        "policyPatchSuccess": "Uaktualniono zasadę",
        "policyPatchError": "Nie udało się uaktualnić zasady",
        // Projects
        "selectActiveProject": "Wybrano nowy projekt",
        "projectsFetchError": "Nie udało się pobrać dostępnych projektów",
        "projectFetchError": "Nie udało się pobrać wybranego projektu",
        "projectPostSuccess": "Stworzono nowy projekt",
        "projectPostError": "Nie udało się stworzyć nowego projektu",
        "projectDeleteSuccess": "Skasowano projekt",
        "projectDeleteError": "Nie udało się skasować wybranego projektu",
        "projectPatchSuccess": "Uaktualniono projekt",
        "projectPatchError": "Nie udało się uaktualnić projektu",
        // Login Patch
        "loginConnection": "Wystąpił błąd połączenia",
        "updateUserPassword": "Zmieniono hasło",
        "updateUserPasswordError": "Nie udalo się uaktualnić hasła"
    },
    "refresh": {
        "token": "ODŚWIEŻ",
        "header": "Twoja sesja niedługo wygaśnie",
        "flavorText": "Czy chcesz odświeżyć swoją sesje?"
    }
    
};

export default pl;