import React, {Component} from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import CancelButton from '../Gui/CancelButton';
import { DialogContent, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

class RegisterForm extends Component{
    constructor(){
        super()
        this.state = {
            textFieldUserName: '',
            textFieldPassword: '',
            textFieldEmailAdress: '',
            errorsNetwork: [],
            confirmMessage: false
        };
    
    }

    handleSubmit = (e) =>{

        e.preventDefault()

        let url = process.env.REACT_APP_API_URL+'users/'
        let data = {            
            username: this.state.textFieldUserName,
            password: this.state.textFieldPassword,
            email: this.state.textFieldEmailAdress
        }
        axios.post(url, data)
        .then(function (response){
            this.setState({
                confirmMessage: true
            })
            setTimeout(function(){

                this.props.handleClose();
            }.bind(this), 3000)
            
            
        }.bind(this))
        .catch(function(error){

            this.setState({
                errorsNetwork: [error.message],
                confirmMessage: false
            },)
        
        }.bind(this));
    }

    handleTextFildChangeLogin = (e) =>
    {
        this.setState({
            textFieldUserName: e.target.value
        })

    }

    handleTextFieldChangePassword = (e) =>
    {
        this.setState({
            textFieldPassword: e.target.value
        })
    }

    handleTextFieldChangeEmailAdress = (e) =>
    {
        this.setState({
            textFieldEmailAdress: e.target.value,
        })    
    }

    validateEmail = () =>
    {
        const re = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
        let boolEmail = re.test(String(this.state.textFieldEmailAdress).toLowerCase())

        return !boolEmail
    }

    validateUserName = () => {

        let name = this.state.textFieldUserName
        if(name.length >= 5){

            return false
        }
        else{

            return true
        }
    }

    validatePassword = () => {
        let password = this.state.textFieldPassword
        if(password.length >= 5){

            return false
        }
        else{

            return true
        }

    }
    render(){
        
        let isUserValid = this.validateUserName();
        let isPasswordValid = this.validatePassword();
        let isEmailValid = this.validateEmail();
        const { t } = this.props;
        return(
        <DialogContent>
            <form>
            <TextField value = {this.state.textFieldUserName} onChange = {this.handleTextFildChangeLogin}
                error = {isUserValid ? true : false}
                helperText= {isUserValid ? t("register.errors.fiveChars") : ""}
                autoFocus
                margin="dense"
                id="name"
                label={t("register.userName")}
                type="name" 
                fullWidth
            />
            <TextField value = {this.state.textFieldPassword} autoComplete="new-password" onChange = {this.handleTextFieldChangePassword}
                    autoFocus
                    error = {isPasswordValid ? true : false}
                    helperText= {isPasswordValid ? t("register.errors.fiveChars") : ""}
                    margin="dense"
                    id="password"
                    label={t("login.password")}
                    type="password"
                    fullWidth
                />
                <TextField value = {this.state.handleTextFieldChangeEmailAdress} onChange = {this.handleTextFieldChangeEmailAdress } 
                    error = {isEmailValid ? true : false}
                    helperText= {isEmailValid ? t('register.errors.emailFormula') : ""}
                    autoFocus
                    margin="dense"
                    id="email"
                    label={t("register.emailAdress")}
                    type="email"
                    fullWidth
                />
            <CancelButton onClick = {this.props.handleClose} />
            <Button color="secondary" variant="contained" onClick = {this.handleSubmit} disabled = {isUserValid ? isPasswordValid : true ? isEmailValid : true} >
                {t('register.register')}
            </Button>
            <Typography color="error" > {this.state.confirmMessage ?  "Succeed !" : this.state.errorsNetwork }</Typography>   
            </form> 
        </DialogContent>         
        );
    }
}
export default withTranslation()(RegisterForm);