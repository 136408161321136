import React, { Component } from 'react';
import scriptLoad from '../../scriptLoader';
import RotatableOverlay from './RotatableOverlay';

const yellow = "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
const blue = "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"

class DevicesMap extends Component{
    
    constructor(){
        super();
        this.map = null;
        this.overlay = null;
        this.mainMarker = null;
        this.rnMarkers = [];
        this.gatewayMarkers = [];
    }
    
    componentDidMount(){
        if(!(typeof window.google === 'object' && typeof window.google.maps === 'object')){
            scriptLoad("https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_KEY+"&v=3.exp&libraries=geometry,drawing,places").then(res => this.initMap())
        }
        else{
            this.initMap()
        }

    }

    initMap = () => {
        this.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 19,
              defaultCenter: this.props.mapCenter,
              center: this.props.mapCenter
            }
        )
        if(this.map){
            new window.google.maps.event.addListenerOnce(this.map, 'idle', function(){
                if(this.props.onMapMounted)
                    this.props.onMapMounted(); 
            }.bind(this));
        }
    }

    placeFloor = () => {
        if(this.props.floor && this.map){
            RotatableOverlay.prototype = new window.google.maps.OverlayView()

            if(this.props.floor.boundsNE && this.props.floor.boundsSW){
                let ne = {lat: this.props.floor.boundsNE.lat, lng: this.props.floor.boundsNE.lng}
                let sw = {lat: this.props.floor.boundsSW.lat, lng: this.props.floor.boundsSW.lng}

                let imageBounds = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(sw.lat, sw.lng),
                    new window.google.maps.LatLng(ne.lat, ne.lng)
                )
                this.overlay = new RotatableOverlay(imageBounds, this.props.floor.planUrl, this.map, this.props.floor.rotation);
            } 
        }
    }

    purge = () => {
        if(this.overlay){
            this.overlay.setMap(null);
            this.overlay=null;
        }
        if(this.mainMarker){
            this.mainMarker.setMap(null);
            this.mainMarker=null;
        }
        this.gatewayMarkers.map(gatewayMarker => 
            gatewayMarker.setMap(null)
        );
        this.gatewayMarkers = [];
        this.rnMarkers.map(rnMarker => 
            rnMarker.setMap(null)
        );
        this.rnMarkers = []
    }

    placeMarkers = () => {
        
        this.mainMarker = new window.google.maps.Marker({
            position: this.props.position,
            map: this.map,
            draggable: true
        })        
        this.mainMarker.setAnimation(window.google.maps.Animation.BOUNCE)

        new window.google.maps.event.addListener(this.mainMarker, 'dragend', function(){
            let newPosition = this.mainMarker.getPosition()
            let pos = {lat: newPosition.lat(), lng: newPosition.lng()}
            this.props.onChange(pos)
        }.bind(this))

        for(let i = 0; i < this.props.referenceNodes.length; i++){
            
            let marker =  new window.google.maps.Marker({
                position: { lat: this.props.referenceNodes[i].attributes.latitude, lng: this.props.referenceNodes[i].attributes.longitude },
                map: this.map,
                icon: {url: blue}
            })
            this.rnMarkers.push(marker)
        }

        for(let i = 0; i < this.props.gateways.length; i++){
        
            let marker =  new window.google.maps.Marker({
                position: { lat: this.props.gateways[i].attributes.latitude, lng: this.props.gateways[i].attributes.longitude },
                map: this.map,
                icon: {url: yellow}
            })
            this.gatewayMarkers.push(marker)
        }
    }

    componentDidUpdate(){
        if(this.props.refreshMap){
            if(this.map){
                if(this.props.isValidLat && this.props.isValidLng)
                    this.map.setCenter(this.props.mapCenter);
                    this.purge();
                    this.placeFloor();
                    this.placeMarkers();
            }
        }
    }

    render(){
        return(
            <div>
                <div id="googleMap" style={{width: "100%", height: '750px'}}/>
            </div>
        )
    }
}

export default DevicesMap


