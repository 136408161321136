import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import App from './components/Layout/App';
import * as serviceWorker from './serviceWorker';
// import logger from 'redux-logger'
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Theme } from './themes/style'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import './i18n.js'


const middleware = applyMiddleware(thunk /*, logger*/)
// const store = createStore(rootReducer,middleware)

const store = createStore(rootReducer, composeWithDevTools(
    middleware,
    // other store enhancers if any
  ));

ReactDOM.render(
        <Provider store = { store }>
            <MuiThemeProvider theme={Theme}>
                <SnackbarProvider maxSnack={5}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SnackbarProvider>
            </MuiThemeProvider>
        </Provider>,
        document.getElementById('root')
    );

serviceWorker.unregister();
