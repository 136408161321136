import React, { Component } from "react";
import { Grid, TextField, InputAdornment, IconButton, Dialog, Menu, MenuItem } from "@material-ui/core";
import DomainIcon from '@material-ui/icons/Domain';
import "./Locations.css";
import Icon from '@mdi/react';
import { mdiStairs, mdiFloorPlan } from '@mdi/js';
import { gray, green } from "../../themes/style";
import MoreIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import uuidv4 from "uuid/v4";
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import FloorDialog from "./FloorDialog";
import EditFloorDialog from "./EditFloorDialog";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteDialog from '../Gui/DeleteDialog';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'

class BuildingFloors extends Component{

    constructor(){
        super();
        this.state ={
            open: false,
            openEdit: false,
            openDeleteDialog: false,
            showFloors: true,
            anchor: null,
            anchorBuilding: null,
            floor: null,
            currentFloorId: "",
            currentBuildingId: "",
            currentType: ""
        };
    }

    openCloseFloors = () =>{
        this.setState({showFloors: !this.state.showFloors})
    }

    selectFloor = (bId, floor) => {
        this.props.selectFloor(bId, floor);
    };

    openDialog = () => {
        this.setState({open: true});
    };

    openDeleteDialog = () => {
        this.setState({openDeleteDialog: true});
    };

    openEditDialog = () => {
        this.setState({openEdit: true});
    };

    closeDialog = () => {
        this.setState({open: false, openEdit: false, openDeleteDialog: false, floor: null});
    };

    submitFloor = (nr, name, planName, planUrl) => {
        // For new Floors
        let floor = {
            "id": uuidv4(),
            "nr": Number.parseInt(nr),
            "name": name,
            "plan": planName,
            "planUrl": planUrl,
            "rotation": 0,
            "boundsNE": {},
            "boundsSW": {},
            "defaultFloor": false
        };
        this.props.addFloorToBuilding(this.props.building.id, floor)
        this.closeDialog();
    };

    submitEditFloor = (id, nr, name, planName, planUrl) => {
        let floor = {
            "id": id,
            "nr": Number.parseInt(nr),
            "name": name,
            "plan": planName,
            "planUrl": planUrl
        };
        this.props.editBuildingFloor(this.props.building.id, floor)
        this.closeDialog();
    };
    
    openDelEdit = (e, fId, bId, type) => {
        // e.stopPropagation();
        let floor = this.props.building.floors.find(function(el){
            return el.id === fId
        })
        this.setState({currentFloorId: fId, currentBuildingId: bId, currentType: type, floor: floor}, this.setState({anchor: e.target}));
    };

    openDelEditBuilding = (e, fId, bId, type) => {
        e.stopPropagation();
        let floor = this.props.building.floors.find(function(el){
            return el.id === fId
        })
        this.setState({currentFloorId: fId, currentBuildingId: bId, currentType: type, floor: floor}, this.setState({anchorBuilding: e.target}));
    };

    closeDelEdit = () => {
        this.setState({currentFloorId: "", currentBuildingId: "", currentType: ""}, this.setState({anchor: null, anchorBuilding: null}));
    };
    
    // Użyj tej fuknkcji do kasowania pieter/buddynków
    deleteFloorOrBuilding = () => {
        this.props.removeFloorBuilding(this.state.currentType, this.state.currentFloorId, this.state.currentBuildingId);
        this.closeDelEdit();
        this.closeDialog();
    };

    editFloorOrBuilding = () => {
        if(this.state.currentType === "floor")
            this.setState({openEdit: true}, this.closeDelEdit());
        else{
            this.closeDelEdit();
        }
    };

    render(){
        return(
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <TextField disabled defaultValue={this.props.building.name} fullWidth
                        InputProps={{
                            startAdornment : (
                                <InputAdornment position="start">
                                    <DomainIcon color="action" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton onClick={this.openDialog}>
                                        <AddCircleIcon color="action"/>
                                    </IconButton>
                                    <IconButton onClick={e => this.openDelEditBuilding(e, null, this.props.building.id, "building")}>
                                        <MoreIcon color="action"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        variant="outlined"
                        classes={{root: 'GrayTextField'}} />
                    </Grid>
                    <MuiThemeProvider theme={IstokTheme}>
                    {this.props.building.floors.map(f=>
                        <Grid container key={f.id}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                            <TextField onClick={e => this.selectFloor(this.props.building.id, f)} value={"Nr: "+f.nr +" - "+f.name} fullWidth
                                InputProps={{
                                    startAdornment : (
                                        <InputAdornment position="start">
                                            <Icon path={mdiStairs}
                                                size={1} color={gray} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton onClick={e => {this.props.selectDefaultFloor(f.id)}}>
                                                <CheckIcon color = {f.defaultFloor ? "primary" : "inherit"} /> 
                                            </IconButton>
                                            <IconButton style={{pointerEvents: 'none'}}>
                                                <Icon path={mdiFloorPlan}
                                                    size={1} color={f.plan ? green: gray} />
                                            </IconButton>
                                            <IconButton onClick={e => this.openDelEdit(e, f.id, this.props.building.id, "floor")}>
                                                <MoreIcon color="action"/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                variant="outlined"
                                classes={this.props.selectedFloor ? (this.props.selectedFloor.id === f.id ? ({root: 'SelectedTextField'}):(null)): (null)} />
                            </Grid>
                        </Grid>
                    )}
                    </MuiThemeProvider>
                </Grid>
                <Dialog fullWidth maxWidth="md" onClose={this.closeDialog} open={this.state.open}>
                    <FloorDialog buildingName={this.props.building.name} onClose={this.closeDialog} open={this.state.open}
                        submitFloor={this.submitFloor}/>
                </Dialog>
                {/* EDIT */}
                <Dialog fullWidth maxWidth="md" onClose={this.closeDialog} open={this.state.openEdit}>
                    <EditFloorDialog buildingName={this.props.building.name} onClose={this.closeDialog} open={this.state.openEdit} floor={this.state.floor}
                        submitFloor={this.submitEditFloor}/>
                </Dialog>
                
                <Dialog fullWidth maxWidth="sm" onClose={this.closeDialog} open={this.state.openDeleteDialog}>
                    <DeleteDialog onDialogClose={this.closeDialog} submitDelete={this.deleteFloorOrBuilding} deleteWarningMessage={"common.removeBuildingFloorWarning"}/>
                </Dialog>

                <Menu anchorEl={this.state.anchor} open={Boolean(this.state.anchor)} onClose={this.closeDelEdit}>
                    <MenuItem onClick={this.openDeleteDialog}>
                        <DeleteIcon color="secondary" />                
                    </MenuItem>
                    <MenuItem onClick={this.editFloorOrBuilding}> 
                        <EditIcon color="primary" />
                    </MenuItem>
                </Menu>
                <Menu anchorEl={this.state.anchorBuilding} open={Boolean(this.state.anchorBuilding)} onClose={this.closeDelEdit}>
                    <MenuItem onClick={this.openDeleteDialog}>
                        <DeleteIcon color="secondary" />                
                    </MenuItem>
                </Menu>
            </Grid>
        );
    };
};

export default BuildingFloors;
