import React, {Component} from "react"
import { connect } from 'react-redux';
import { fetchPolicies, deletePolicy } from '../../actions/apiCalls';
import CreatePolicy from "./CreatePolicy";
import EditPolicy from "./EditPolicy";
import { withTranslation } from 'react-i18next';
import PoliciesPanel from './PoliciesPanel.js'
import { Route, Switch } from "react-router-dom";


class Policies extends Component{
    
    constructor(){
        super();
        this.state = {
            currentPolicyId: ""
        }
    };
    
    componentDidMount(){
        if (this.props.projectId === null)
            this.props.history.push('/');
        // Fetch
        if (this.props.projectId !== null)
            this.props.fetchProjectPolicies(this.props.projectId);
    };
    deletePolicy = (e, policyId) => {
        e.stopPropagation();
        if(this.props.projectId)
            this.props.deleteProjectPolicy(this.props.projectId, policyId);
    }

    openEdit = (e, policyId) => {
        this.setState({currentPolicyId: policyId});
        this.props.history.push(this.props.match.url + '/edit')
    }

    openCreate = () => {
        this.props.history.push(this.props.match.url + '/create')
    }

    onClose = () => {
        this.setState({currentPolicyId: ""});
        this.props.history.push('/policies')
    }

    render(){
        const { t } = this.props
        const attributes = ["name", "type"]
        const headers = [t("policies.name"), t("policies.type")]
        return(
            <div>
                <Switch>
                    <Route exact path={'/policies'}>
                        <PoliciesPanel data={this.props.policies} name={t("policy", {count: 5})}
                            onItemDelete={this.deletePolicy}
                            onItemClick={this.openEdit}
                            openCreate={this.openCreate}
                            attributes={attributes}
                            headers={headers}
                        />
                    </Route>
                    <Route path={this.props.match.url + '/create'}>
                        <CreatePolicy onClose={this.onClose}/>    
                    </Route>
                    <Route path={this.props.match.url + '/edit'}>
                        <EditPolicy policyId={this.state.currentPolicyId} onClose={this.onClose} />
                    </Route>
                </Switch>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        policies: state.policies.policies,
        errors: state.policies.errors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectPolicies(projectId) {
            dispatch(fetchPolicies(projectId))
        },
        deleteProjectPolicy(projectId, policyId) {
            dispatch(deletePolicy(projectId, policyId)).then(() => dispatch(fetchPolicies(projectId)));
        },
        clearPoliciesErrors()
        {
            dispatch({type: 'POLICIES_CLEAR_ERRORS'})
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Policies));