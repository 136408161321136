import React, { Component } from "react";
import { Button, Grid, Typography, DialogContent, DialogActions } from "@material-ui/core";
import CancelButton from "./CancelButton";
import './Dialog.css'
import { withTranslation } from 'react-i18next';

class DeleteDialog extends Component{
    render(){
        const { t } = this.props
        return(
            <Grid container>
                <Grid item xs={12} className="dialogTitle">
                    <Typography variant="body1" style={{paddingTop: 5}} component="h6">{t('common.warrning')}</Typography>
                </Grid>
                <Grid item xs={12}>
                <DialogContent>
                    <Grid item xs={12}>
                        {this.props.deleteWarningMessage ?
                            <Typography variant="body1" component="p">{t(this.props.deleteWarningMessage)}</Typography>
                            :<Typography variant="body1" component="p">{t('common.deletePrompt')}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={8} />
                        <Grid item>
                            <CancelButton onClick={this.props.onDialogClose} />     
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={this.props.submitDelete}>{t('common.deleteButton')}</Button>
                        </Grid>
                        <Grid item xs={12}/>
                    </Grid>
                </DialogActions>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(DeleteDialog);