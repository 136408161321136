const initialState = {
    locations: [],
    metadata: {},
    currentLoc: [],
    notices: []
}

export default function Locations(state = initialState, action) {
    if (action.type === 'FETCH_LOCATIONS'){
        return {
            ...state,
            locations: action.payload.data,
            notices: []
        }
    }
    if (action.type === 'FETCH_LOCATIONS_META'){
        return {
            ...state,
            metadata:  action.payload.metadata,
            notices: []
        }
    }
    if (action.type === 'FETCH_LOCATIONS_FAILURE'){
        return {
            ...state,
            locations: [],
            metadata: {},
            notices: [{type: "error", notifyKey: "snackbar.locationFetchError"}]
        }
    }
    if(action.type === 'LOCATION_CLEAR_NOTICES')
    {
        return{
            ...state,
            notices: []
        }
    }
    if (action.type === 'FETCH_LOCATION_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.locationFetchSingleError"}]
        }
    }

    if (action.type === 'DELETE_LOCATION'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.locationDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_LOCATION_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.locationDeleteError"}]
        }
    }

    if (action.type === 'POST_LOCATIONS_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.locationPostError"}]
        }
    }
    if (action.type === 'POST_LOCATIONS'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.locationPostSuccess"}]
        }
    }

    if (action.type === 'UPDATE_LOCATION'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.locationPutSuccess"}]
        }
    }
    if (action.type === 'UPDATE_LOCATION_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.locationPutError"}]
        }
    }
    if (action.type === 'SELECT_PROJECT'){
        return {
            ...state,
            metadata: {}
        }
    }
    
    return state
}
  