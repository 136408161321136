import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchGateways, deleteGateway } from '../../actions/apiCalls';
import CreateGateway from "./CreateGateway";
import EditGateway from "./EditGateway";
import { withTranslation } from 'react-i18next';
import GatewayPanel from "./GatewayPanel.js"
import { Route, Switch,  } from "react-router-dom";
import axios from 'axios';
import { withSnackbar } from 'notistack';

class Gateways extends Component{

    constructor(){
        super()
        this.state = {
            currGatewayId: "",
            gateway: null,
            checked: false
        };
        this._interval = null
    };
    
    componentDidMount(){
        if(this.props.projectId){
            this.props.fetchProjectGateways(this.props.projectId);
            this._interval = setInterval( () => 
            {   
                this.props.fetchProjectGateways(this.props.projectId);
            }, 5000 );
        }
        else
            this.props.history.push('/');
    };
    
    componentWillUnmount = () => {
        if(this._interval)
            clearInterval(this._interval);
    }

    deleteGateway = (e, gatewayId) => {
        e.stopPropagation();
        if (this.props.projectId !== null)
            this.props.deleteGateway(this.props.projectId, gatewayId);
    }

    addNewGateway = () => {
        this.props.history.push(this.props.match.path + '/create');
    };

    updateGatewaysWithBeaonsMac = () => {
        let url = process.env.REACT_APP_API_URL+'projects/' + this.props.projectId +'/gateways/update-with-mac'
        axios.get(url).then(res => {
            if (res !== null){
                this.notify(this.props.t('snackbar.gatewaysUploadMacSuccess'), 'success')
            }
        }
            ).catch(e => {
                this.notify(this.props.t("snackbar.gatewaysUploadMacError"), 'error')
            });

    }

    editGateway = (e, gatewayId) => {
        this.setState({
            currGatewayId: gatewayId
        })
        this.props.history.push(this.props.match.path + '/edit');
    };

    sendEmailMessage = (e, gatewayId) => {
        let url = process.env.REACT_APP_API_URL+'projects/' + this.props.projectId +'/gateways/gateway-status-update/' + gatewayId.id
        axios.post(url, {})
          .catch(function (error) {
            console.log(error);
          });
          this.props.fetchProjectGateways(this.props.projectId);
    }
    closeForm = () => {
        this.props.history.push('/gateways');
    };

    notify = (message, type) => {
        this.props.enqueueSnackbar(message,  { 
            variant: type,
        });
    }


    render(){
        const { t } = this.props
        const attributes = ["name", "location", "building", "floor", "bleFirmwareVersion", "firmwareVersion", "bootloaderVersion", "status"]
        const headers = [t("gateways.name"), t("gateways.location"), t("gateways.building"), t("gateways.floor"), "BLE firmware", "Firmware", "Bootloader", "Status", "Notify"]
        return(
                <div>
                        <Switch>
                            <Route exact path={"/gateways"}>
                                <GatewayPanel headers={headers} attributes={attributes}
                                        uploadMac={this.updateGatewaysWithBeaonsMac}
                                        data={this.props.gateways}
                                        onItemDelete={this.deleteGateway}
                                        onItemClick={this.editGateway}
                                        addNewGateway={this.addNewGateway}
                                        sendMessage={this.sendEmailMessage}
                                />
                            </Route>
                            <Route path={this.props.match.url + "/create"}>
                                <CreateGateway onClose={this.closeForm}/>
                            </Route>
                            <Route path={this.props.match.url + "/edit"}>
                                <EditGateway onClose={this.closeForm} gatewayId={this.state.currGatewayId} 
                                    gateway={this.state.gateway}
                                    locations={this.props.locations}
                                    gateways={this.props.gateways}
                                    gatewayTemplates={this.props.gatewayTemplates} />
                            </Route>
                        </Switch>
                </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        gateways: state.gateways.gateways,
        locations: state.locations.locations,
        gatewayTemplates: state.gateways.gatewayTemplates,
        errors: state.gateways.errors,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectGateways(projectId) {
            dispatch(fetchGateways(projectId));
        },
        deleteGateway(projectId, gatewayId) {
            dispatch(deleteGateway(projectId, gatewayId)).then(() => dispatch(fetchGateways(projectId)));
        },
        clearGatewayErrors(){
            dispatch({type: 'GATEWAY_CLEAR_ERRORS'})
        }

    }
};


export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Gateways)));
