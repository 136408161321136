import axios from "axios";

export function fetchProjects() {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_PROJECTS', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_PROJECTS_FAILURE', payload: error });
        }
    };
};

export function deleteProject(projectId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_PROJECTS', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_PROJECTS_FAILURE', payload: error });
        }
    }
};

export function patchProject(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_PROJECT', res});
        }
        catch (error) {
            return dispatch({ type: 'PATCH_PROJECT_FAILURE', payload: error });
        }
    }
};

export function postProject(data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_PROJECTS', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_PROJECTS_FAILURE', payload: error });
        }
    }
};

export function postGatewayTemplate(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateway-templates'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_GATEWAY_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_GATEWAY_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function patchReferenceNode(projectId, referenceNodeId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-nodes/'+referenceNodeId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_REFERENCE_NODE', res});
        }
        catch (error) {
            return dispatch({ type: 'PATCH_REFERENCE_NODE_FAILURE', payload: error });
        }
    }
};

export function patchGatewayTemplate(projectId, templateId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateway-templates/'+templateId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_GATEWAY_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'PATCH_GATEWAY_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function deleteGatewayTemplates(projectId, templateId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateway-templates/'+templateId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_GATEWAY_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_GATEWAY_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function postReferenceNodeTemplate(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-node-templates'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_REFERENCE_NODE_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_REFERENCE_NODE_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function patchReferenceNodeTemplate(projectId, templateId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-node-templates/'+templateId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_REFERENCE_NODE_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'PATCH_REFERENCE_NODE_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function deleteReferenceNodeTemplates(projectId, templateId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-node-templates/'+templateId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_REFERENCE_NODE_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_REFERENCE_NODE_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function postBeaconTemplate(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacon-templates/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_BEACON_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_BEACON_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function patchBeaconTemplate(projectId, templateId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacon-templates/'+templateId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_BEACON_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'PATCH_BEACON_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function deleteBeaconTemplates(projectId, templateId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacon-templates/'+templateId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_BEACON_TEMPLATES', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_BEACON_TEMPLATES_FAILURE', payload: error });
        }
    }
};

export function fetchReferenceNodes(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-nodes'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_REFERENCE_NODES', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_REFERENCE_NODES_FAILURE', payload: error });
        }
    };
};

export function postReferenceNode(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-nodes'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_REFERENCE_NODES', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_REFERENCE_NODES_FAILURE', payload: error });
        }
    }
};

export function deleteReferenceNode(projectId, referenceNodeId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-nodes/'+referenceNodeId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_REFERENCE_NODES', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_REFERENCE_NODES_FAILURE', payload: error });
        }
    }
};

export function fetchBeaconTemplates(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacon-templates'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_BEACONS_TEMPLATES', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_BEACONS_TEMPLATES_FAILURE', payload: error });
        }
    };
};

export function fetchReferenceNodeTemplates(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/reference-node-templates'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_REFERENCE_NODE_TEMPLATES', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_REFERENCE_NODE_TEMPLATES_FAILURE', payload: error });
        }
    };
};


export function fetchBeacons(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacons'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_BEACONS', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_BEACONS_FAILURE', payload: error });
        }
    };
};

export function postBeacon(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacons/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_BEACONS', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_BEACONS_FAILURE', payload: error });
        }
    }
};

export function patchBeacon(projectId, gatewayId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacons/'+gatewayId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_BEACON', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'PATCH_BEACON_FAILURE', payload: error });
        }
    };
}

export function deleteBeacons(projectId, beaconId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/beacons/'+beaconId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_BEACONS', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_BEACONS_FAILURE', payload: error });
        }
    }
};

export function fetchGateways(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateways'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_GATEWAYS', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_GATEWAYS_FAILURE', payload: error });
        }
    };
};

export function patchGateway(projectId, gatewayId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateways/'+gatewayId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_GATEWAY', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'PATCH_GATEWAY_FAILURE', payload: error });
        }
    };
}

export function fetchGateway(projectId, gatewayId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/locations/'+gatewayId
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_GATEWAY', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_GATEWAY_FAILURE', payload: error });
        }
    };
};

export function postGateway(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateways/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_GATEWAYS', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_GATEWAYS_FAILURE', payload: error });
        }
    }
};

export function deleteGateway(projectId, gatewayId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateways/'+gatewayId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_GATEWAY', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_GATEWAY_FAILURE', payload: error });
        }
    }
};

// export const getGatewayStatus = async () =>{
//     try {
//         let url = process.env.REACT_APP_API_URL+'projects/gateways/gateway-status'
//         const res = await axios.get(url)
//         // return dispatch({type: 'GATEWAY_STATUS_SEND', res});
//     }
//     catch (error){
//         console.log(error)
//         // return dispatch({ type: 'GATEWAY_STATUS_SEND_FAILURE', payload: error})
//     }
// }

export function getGatewayStatus(){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/gateways/gateway-status'
        try {
            const res = await axios.get(url)
            return dispatch({type: 'GATEWAY_STATUS_SEND', res});
        }
        catch (error){
            return dispatch({ type: 'GATEWAY_STATUS_SEND_FAILURE', payload: error})
        }
    }
};

export function fetchGatewayTemplates(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/gateway-templates'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_GATEWAY_TEMPLATES', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_GATEWAY_TEMPLATES_FAILURE', payload: error });
        }
    };
};

export function fetchLocations(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/locations'
        try {
            const res = await axios.get(url);
            dispatch({ type: 'FETCH_LOCATIONS', payload: res.data })
            dispatch({ type: 'FETCH_LOCATIONS_META', payload: res.data })
        }
        catch (error) {
            return dispatch({ type: 'FETCH_LOCATIONS_FAILURE', payload: error });
        }
    };
};

export function fetchLocation(projectId, locationId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/locations/'+locationId
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_LOCATION', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_LOCATION_FAILURE', payload: error });
        }
    };
};

export function deleteLocation(projectId, locationId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/locations/'+locationId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_LOCATION', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_LOCATION_FAILURE', payload: error });
        }
    }
};

export function putLocation(projectId, locationId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/locations/'+locationId
        try {
            const res = await axios.put(url, data);
            return dispatch({ type: 'UPDATE_LOCATION', res});
        }
        catch (error) {
            return dispatch({ type: 'UPDATE_LOCATION_FAILURE', payload: error });
        }
    }
};


export function postLocation(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/locations/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_LOCATIONS', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_LOCATIONS_FAILURE', payload: error });
        }
    }
};

export function fetchZones(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/zones'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_ZONES', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_ZONES_FAILURE', payload: error });
        }
    };
};

export function postZone(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/zones/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_ZONES', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_ZONES_FAILURE', payload: error });
        }
    }
};

export function deleteZone(projectId, zoneId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/zones/'+zoneId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_ZONE', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_ZONE_FAILURE', payload: error });
        }
    }
};

export function patchZone(projectId, zoneId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/zones/'+zoneId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_ZONE', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'PATCH_ZONE_FAILURE', payload: error });
        }
    };
}

export function fetchPolicies(projectId) {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/policies'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_POLICIES', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_POLICIES_FAILURE', payload: error });
        }
    };
};

export function postPolicy(projectId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/policies/'
        try {
            const res = await axios.post(url, data);
            return dispatch({ type: 'POST_POLICIES', res});
        }
        catch (error) {
            return dispatch({ type: 'POST_POLICIES_FAILURE', payload: error });
        }
    }
};

export function deletePolicy(projectId, policyId){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/policies/'+policyId
        try {
            const res = await axios.delete(url);
            return dispatch({ type: 'DELETE_POLICY', res});
        }
        catch (error) {
            return dispatch({ type: 'DELETE_POLICY_FAILURE', payload: error });
        }
    }
};

export function patchPolicy(projectId, policyId, data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'projects/'+projectId+'/policies/'+policyId
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_POLICY', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'PATCH_POLICY_FAILURE', payload: error });
        }
    };
}

export function fetchUser() {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'users/'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_USER', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_USER_FAILURE', payload: error });
        }
    };
};

export function patchUser(data){
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'users/'
        try {
            const res = await axios.patch(url, data);
            return dispatch({ type: 'PATCH_USER', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'PATCH_USER_FAILURE', payload: error });
        }
    };
}

export function fetchFirmwareVersions() {
    return async dispatch => {
        let url = process.env.REACT_APP_API_URL+'firmwares/'
        try {
            const res = await axios.get(url);
            return dispatch({ type: 'FETCH_FIRMWARE', payload: res.data });
        }
        catch (error) {
            return dispatch({ type: 'FETCH_FIRMWARE_FAILURE', payload: error });
        }
    };
};

export async function refreshToken(token){
    let url = process.env.REACT_APP_API_URL+'token/' 
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    return await axios.post(url, {token: token})  
}