import React, {Component} from 'react'
import LoginForm from './LoginForm'
import { connect } from 'react-redux'
import axios from 'axios';
import "./Login.css"
import { Typography, Dialog } from '@material-ui/core';
import RegisterForm from '../Register/RegisterForm'
import { withTranslation } from 'react-i18next';
import LanguageForm from '../Language/LanguageForm.js'

class Login extends Component{
    constructor(){
        super()
        this.state = {
            openLoginWindow: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e){
        e.preventDefault()
        let loginValues = this.props.loginForm.values
        this.props.loginUser(loginValues)
    }

    openRegisterWindow = () => {
        this.setState({openLoginWindow: true})    
    };
    closeRegisterWindow = () => {
        this.setState({openLoginWindow: false})
    }

    render(){
        const { t } = this.props;
        return(
            <div>
                <LanguageForm />
                <div className="bg"/>
                <h2 className="appTitle">MPS</h2>
                <Typography variant="h5" className="flavorText">{t('dashboard.slogan')}</Typography>
                <div className="login">
                    <LoginForm handleOpen = {this.openRegisterWindow} handleSubmit = {this.handleSubmit} /*errors = {this.props.errors}*//> 
                </div>
                <Dialog fullWidth maxWidth = "xs" open = {this.state.openLoginWindow} onClose = {this.closeRegisterWindow} aria-labelledby="form-dialog-title" >
                <RegisterForm handleClose = {this.closeRegisterWindow} />
                </Dialog>
            </div>

    
      
        )
    }
}
const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.userLoggedIn,
        loginForm: state.form.login,
        errors: state.login.errors
    } 
}

const mapDispatchToProps = (dispatch) => {
    let url = process.env.REACT_APP_API_URL+'login'
    let projectUrl = process.env.REACT_APP_API_URL+'projects'
    return {
            loginUser: (userValues) => {
                axios.post(url, userValues)
                .then(res => dispatch({type: 'LOGIN_USER', payload: res }))
                .catch(err => dispatch({type: 'ERROR_LOGIN_USER', payload: err.response}))
                .then(res => axios.get(projectUrl).then(res => dispatch({type: 'FETCH_PROJECTS_FIRST', payload: res })))
                .catch(err => console.log("Error login"))
            },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login))