class RotatableOverlay{
    constructor(bounds, image, map, rot) {
        this.bounds_ = bounds
        this.image_ = image
        this.map_ = map
        this.rot_ = rot
        this.div_ = null
        this.setMap(map)

        this.boundsNE = null
        this.boundsSW = null
    }

    onAdd = () => {
        let div = document.createElement('div');
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';    
        div.style.transform = 'rotate(' + this.rot_ + 'deg)';
        let img = document.createElement('img');
        img.src = this.image_;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        div.appendChild(img);
        this.div_ = div;
        let panes = this.getPanes();
        panes.overlayLayer.appendChild(div);
    }

    draw = () => {
        let overlayProjection = this.getProjection()

        let sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest())
        let ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast())

        let div = this.div_
        
        div.style.left = sw.x + 'px'
        div.style.top = ne.y + 'px'
        div.style.width = (ne.x - sw.x) + 'px'
        div.style.height = (sw.y - ne.y) + 'px'
    }

    onRemove = () => {
        this.div_.parentNode.removeChild(this.div_)
        this.div_ = null
    }
}

export default RotatableOverlay;


// TO MOZE DZIAŁAC DO SPRAWDZENIA : P
// // class RotatableOverlay extends window.google.maps.OverlayView{

// const createRotatabOverlay = ({ OverlayView = window.google.maps.OverlayView,  ...args }) => {
//     class RotatableOverlay extends OverlayView{
//         constructor(bounds, image, map, rot) {
//             super()
//             this.bounds_ = bounds
//             this.image_ = image
//             this.map_ = map
//             this.rot_ = rot
//             this.div_ = null
//             this.setMap(map)

//             this.boundsNE = null
//             this.boundsSW = null
//         }

//         onAdd = () => {
//             let div = document.createElement('div');
//             div.style.borderStyle = 'none';
//             div.style.borderWidth = '0px';
//             div.style.position = 'absolute';    
//             div.style.transform = 'rotate(' + this.rot_ + 'deg)';
//             let img = document.createElement('img');
//             img.src = this.image_;
//             img.style.width = '100%';
//             img.style.height = '100%';
//             img.style.position = 'absolute';
//             div.appendChild(img);
//             this.div_ = div;
//             let panes = this.getPanes();
//             panes.overlayLayer.appendChild(div);
//         }

//         draw = () => {
//             let overlayProjection = this.getProjection()

//             let sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest())
//             let ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast())

//             let div = this.div_
            
//             div.style.left = sw.x + 'px'
//             div.style.top = ne.y + 'px'
//             div.style.width = (ne.x - sw.x) + 'px'
//             div.style.height = (sw.y - ne.y) + 'px'
//         }

//         onRemove = () => {
//             this.div_.parentNode.removeChild(this.div_)
//             this.div_ = null
//         }
//     }
//     //To see
//     return new RotatableOverlay(
//             args.bounds,
//             args.img,
//             args.map,
//             args.rot
//     );
// }

// export default createRotatabOverlay;