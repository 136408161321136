import React, { Component } from "react";
import { connect } from 'react-redux';
import CreateReferenceNode from "./CreateReferenceNode";
import EditReferenceNode from "./EditReferenceNode";
import { fetchReferenceNodes, deleteReferenceNode } from "../../actions/apiCalls";
import { withTranslation } from 'react-i18next';
import ReferenceNodesPanel from './ReferenceNodesPanel.js'
import { Route, Switch,  } from "react-router-dom";
class ReferenceNodes extends Component{

    constructor(){
        super()
        this.state = {
            referenceNodeId: ""
        };
    };

    componentDidMount(){
        if(this.props.projectId){
            this.props.fetchProjectReferenceNodes(this.props.projectId)
        }
        else
            this.props.history.push('/')
    };
    addNewReferenceNode = () => {
        this.props.history.push(this.props.match.path + '/create')
    };

    deleteReferenceNode = (e, referenceNodeId) => {
        e.stopPropagation();
        if(this.props.projectId)
            this.props.deleteReferenceNode(this.props.projectId, referenceNodeId);
    }

    editReferenceNode = (e, referenceNodeId) => {
        this.setState({
            referenceNodeId: referenceNodeId
        })
        this.props.history.push(this.props.match.path + '/edit')
    };

    closeForm = () => {
        this.props.history.push('/reference-nodes')
    };

    render(){
        const { t } = this.props
        const attributes = ["name", "MAC"]
        const headers = [t("referenceNodes.name"), t("referenceNodes.mac")]
        const referenceNodes = this.props.referenceNodes
        const data = [];
        referenceNodes.map(rn => {
            rn.attributes.type = "Reference Node Template"
            return data.push(rn)
        })

        return(
            <div>
                <Switch>
                    <Route exact path={"/reference-nodes"}>
                        <ReferenceNodesPanel data={data} name={t('refNode', {count: 5})}
                                onItemDelete={this.deleteReferenceNode}
                                onItemClick={this.editReferenceNode}
                                addNewReferenceNode={this.addNewReferenceNode}
                                attributes={attributes}
                                headers={headers}
                        />
                    </Route>
                    <Route path={this.props.match.url + "/create"}>
                        <CreateReferenceNode onClose={this.closeForm}/>
                    </Route>
                    <Route path={this.props.match.url + "/edit"}>
                        <EditReferenceNode onClose={this.closeForm} referenceNodeId={this.state.referenceNodeId}/>
                    </Route>
                </Switch>             
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        referenceNodes: state.referenceNodes.referenceNodes,
        gateways: state.gateways.gateways,
        errors: state.referenceNodes.errors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectReferenceNodes(projectId) {
            dispatch(fetchReferenceNodes(projectId))
        },
        deleteReferenceNode(projectId, referenceNodeId) {
            dispatch(deleteReferenceNode(projectId, referenceNodeId)).then(() => dispatch(fetchReferenceNodes(projectId)));
        },
        clearReferenceNodeErrors()
        {
            dispatch({type: 'REFERENCE_NODE_CLEAR_ERRORS'})
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReferenceNodes));
