import React from "react";
import { useSelector } from 'react-redux'
import PublishIcon from '@material-ui/icons/Publish';
import { Fab } from "@material-ui/core";
import { isCorrectRole, uploadFab } from "../../utils";
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const UploadMacFilters = (props) => {

    const { t } = useTranslation();
    const role = useSelector(state => state.projects.currentRole)
    const isAllowed = isCorrectRole(role)

    return(
        isAllowed ?
        <Tooltip title={t('gateways.updateWithMac')}>
            <Fab style={uploadFab} color="secondary" aria-label="add" onClick={props.onClick}>
                <PublishIcon />
            </Fab> 
        </Tooltip>: <div />
    )
}

export default UploadMacFilters