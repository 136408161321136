import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { connect } from 'react-redux';
import CancelButton from '../Gui/CancelButton';
import SaveButton from '../Gui/SaveButton';
import ZoneMap from '../Map/ZoneMap';
import IconTextField from "../Gui/IconTextField";
import RoomIcon from '@material-ui/icons/Room';
import PhotoSizeSelectIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import IconSelector from "../Gui/IconSelector";
import { fetchLocations, fetchZones, patchZone } from '../../actions/apiCalls';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

class EditZone extends Component{
    constructor(){
        super()
        this.state = {
            name: "",
            floorId: "",
            mapCenter: { lat: 54.370765, lng: 18.613733},
            floor: null,
            points: [],
            polygon: null
        };
    }

    onMapMounted = () => {
        this._mapMounted = true;
        if(this.props.projectId && this.props.zoneId){
            let url = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/zones/'+this.props.zoneId
            axios.get(url).then(res => this.setState({
                name: res.data.data[0].attributes.name,
                floorId: res.data.data[0].relationships.floor.data.id,
                points: res.data.data[0].attributes.points
            }, this.setFloor(res.data.data[0].relationships.floor.data.id))).catch(e => {
                this.props.onFetchError(e);
                this.props.onClose();
            })
        }else{
            this.props.onClose();
        }      
    }

    componentDidMount = () => {
        if(this.props.projectId){
            this.props.fetchProjectLocations(this.props.projectId);
        };
    }  

    setPolygon = (polygon) => {
        // console.log(polygon)
        this.setState({polygon: polygon, refreshMap: false})
    }

    
    submitZone = (e) => {
        e.preventDefault();
        if(this.state.polygon){
            let coords = this.state.polygon.getPath().getArray()
            let last = coords.length - 1
            if (coords[last].lat() !== coords[0].lat())
                if(coords[last].lng() !== coords[0].lng())
                    coords.push(coords[0])
            let points = []
            coords.map(coord =>
                points.push({ latitude: coord.lat(), longitude: coord.lng() })
            )
            let data = {
                name: this.state.name,
                floorId: this.state.floorId,
                points: points
            }
            if(this.props.projectId && this.props.zoneId)
                this.props.patchZone(this.props.projectId, this.props.zoneId, data);
            this.props.onClose();
        }
    };

    onNameChange = (e) => {
        this.setState({name: e.target.value});
    }

    setFloor = (floorId) => {
        let location = this.props.locations.find(location =>
            location.buildings.find(building =>
                building.floors.find(floor =>
                    floor.id === floorId
                )
            )
        )
        if(location){
            let floor = null
            this.props.locations.map(l => l.buildings.map(b => b.floors.map(f=> f.id === floorId ? (floor = f):(null))))
            
            if(floor && floor.planUrl && floor.boundsNE && floor.boundsSW){
                this.setState({
                    floorId: floorId,
                    mapCenter:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                    position:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                    floor: floor,
                    refreshMap: true,
                    points: []
                })
            }
            else{
                this.setState({
                    floorId: floorId,
                    mapCenter:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                    position:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                    floor: null, 
                    refreshMap: true,
                    points: []
                })
            }
            
        }
    }

    onFloorSelect = (e) => {
        let floorId = e.target.value
        this.setFloor(floorId)
    }

    render(){
        const { t } = this.props
        return(
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitZone} 
                                    disabled={this.state ? false: true} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("zones.name")} icon={<PhotoSizeSelectIcon />}/>
                            <IconSelector value={this.state.floorId} data={this.props.locations} labelWidth={65} label={t("zones.location")}
                                icon={<RoomIcon />} onChange={this.onFloorSelect} floors={true}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <ZoneMap mapCenter={this.state.mapCenter} 
                                refreshMap={this.state.refreshMap} 
                                setPolygon={this.setPolygon}
                                floor={this.state.floor}
                                onChange={this.onPositionChange}
                                onMapMounted={this.onMapMounted}
                                points={this.state.points} />
                    </Grid>
                </Grid>
            </form>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        locations: state.locations.locations
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectLocations(projectId) {
            dispatch(fetchLocations(projectId))
        },
        patchZone(projectId, zoneId, data){
            dispatch(patchZone(projectId, zoneId, data)).then(() => dispatch(fetchZones(projectId)));
        },
        onFetchError(e) {
            dispatch({type: 'FETCH_ZONE_FAILURE'})
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditZone));