import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import uuidv4 from "uuid/v4";
import { Grid, Button  } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import SubjectIcon from "@material-ui/icons/Subject";
import IconTextField from "../Gui/IconTextField";
import GpsNotFixed from "@material-ui/icons/GpsNotFixed"
import BuildingFloorsEdit from "./BuildingFloorsEdit";
import LocationMap from "../Map/LocationMap";
import SaveButton from "../Gui/SaveButton";
import { fetchLocations, putLocation } from '../../actions/apiCalls';
import NewBuildingForm from './NewBuildingForm';
import CancelButton from "../Gui/CancelButton";
import { withTranslation } from 'react-i18next';

class EditLocation extends Component{
    
    constructor(){
        super();
        this.state = {
            location: null,
            lName: "",
            lDescription: "",
            lAddress: "",
            lBuildings: [],
            newBuildingOpen: false,
            mapCenter: { lat: 54.370765, lng: 18.613733},
            selectedFloor: null,
            selectedBuildingId : "",
            purge: true,
            centerChanged: true,
            toDelete: []
        };
        this._isMounted = true;
    };

    addNewBuilding = (name) => {
        this.setState({lBuildings: this.state.lBuildings.concat({"id": uuidv4(), "name": name, floors: []}), centerChanged: false});
    };

    openBuildingForm = () => {
        this.setState({newBuildingOpen: true, centerChanged: false})
    };

    closeBuildingForm = () => {
        this.setState({newBuildingOpen: false, centerChanged: false})
    };

    resetPurge = () => {
        this.setState({purge: false})
    }

    changeLocationName = (e) => {
        this.setState({lName: e.target.value, centerChanged: false})
    }

    changeLocationDescription = (e) => {
        this.setState({lDescription: e.target.value, centerChanged: false})
    }

    changeLocationAddress = (e) => {
        this.setState({lAddress: e.target.value, centerChanged: false})
    }

    selectFloor = (bId, f) => {
        this.setState({selectedFloor: f, selectedBuildingId: bId, purge: true, centerChanged: false})
    };

    selectDefaultFloor = (floorId) => {
        let buildings = [...this.state.lBuildings]
        buildings.forEach(b => {
            b.floors.forEach(f => {
                if(f.id === floorId && f.defaultFloor === true){
                    f.defaultFloor = false
                    return;
                }
                f.id === floorId ? f.defaultFloor = true : f.defaultFloor = false
            })
        });
        this.setState({
            lBuildings : buildings
        });
    }

    changeRotation = (rot) => {
        if(this.state.selectedFloor && this.state.selectedBuildingId){
            let i = this.state.lBuildings.findIndex(b=>b.id === this.state.selectedBuildingId)
            let j = this.state.lBuildings[i].floors.findIndex(f=> f.id === this.state.selectedFloor.id)
            let newBuildings = this.state.lBuildings;
            newBuildings[i].floors[j].rotation = rot;
            if (this._isMounted){
                this.setState({lBuildings: newBuildings});
                this.selectFloor(this.state.selectedBuildingId, this.state.lBuildings[i].floors[j]);
            }
        }
    }

    changeBounds = (neLat, neLng, swLat, swLng) => {
        if(this.state.selectedFloor && this.state.selectedBuildingId){
            let i = this.state.lBuildings.findIndex(b=>b.id === this.state.selectedBuildingId)
            let j = this.state.lBuildings[i].floors.findIndex(f=> f.id === this.state.selectedFloor.id)
            let newBuildings = this.state.lBuildings;
            newBuildings[i].floors[j].boundsSW = {'lat': swLat, 'lng': swLng}
            newBuildings[i].floors[j].boundsNE = {'lat': neLat, 'lng': neLng}
            if (this._isMounted){
                this.setState({lBuildings: newBuildings});
                this.selectFloor(this.state.selectedBuildingId, this.state.lBuildings[i].floors[j]);
            }
        }
    }

    findGeoAdress = () => {
        let address = this.state.lAddress
        let url = "https://maps.googleapis.com/maps/api/geocode/json?address="+address.replace(/\s+/g, '+')+"&key="+process.env.REACT_APP_GOOGLE_KEY
        let xmlHttp = new XMLHttpRequest()

        xmlHttp.onreadystatechange = function() { 
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200){
                let res = JSON.parse(xmlHttp.responseText)
                if(res.results.length > 0){
                    let lat = res.results[0].geometry.location.lat
                    let lng = res.results[0].geometry.location.lng
                    this.setState({mapCenter: { lat: lat, lng: lng}, centerChanged: true})
                }
            }
        }.bind(this)
        
        xmlHttp.open("GET", url, true)
        xmlHttp.send(null)
    }

    componentDidMount = () => {
        if (this.props.projectId){
            let url = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/locations/'+this.props.locationId
            try {
                axios.get(url).then(res => this.setState({
                    location: res.data.data[0],
                    lName: res.data.data[0].attributes.name,
                    lDescription: res.data.data[0].attributes.description,
                    lAddress: res.data.data[0].attributes.address,
                    lBuildings: res.data.data[0].buildings,
                    mapCenter: {lat: res.data.data[0].attributes.latitude, lng: res.data.data[0].attributes.longitude}
                })).catch(e => {
                        this.props.onFetchFailed(e)
                        this.props.onClose();
                    }
                );
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    
    submitEditLocation = () => {
        let data = {
            "name": this.state.lName,
            "address": this.state.lAddress,
            "description": this.state.lDescription,
            "position": { "lat": this.state.mapCenter.lat, "lng": this.state.mapCenter.lng },
            "buildings": this.state.lBuildings,
            "events": this.state.toDelete
        };
        if(this.props.projectId && this.props.locationId){
            this.props.editLocation(this.props.projectId, this.props.locationId, data)
            this.props.onClose()
        }
    };

    addFloorToBuilding = (bId, floor) => {
        let i = this.state.lBuildings.findIndex(b=>b.id === bId)
        if (i >= 0){
            this.state.lBuildings[i].floors.push(floor)
            this.forceUpdate();
            this.selectFloor(bId, floor)
        }
    };

    editBuildingFloor = (bId, floor) => {
        let i = this.state.lBuildings.findIndex(b=>b.id === bId)
        let j = this.state.lBuildings[i].floors.findIndex(f=> f.id === floor.id)
        let newBuildings = this.state.lBuildings;
        newBuildings[i].floors[j].name = floor.name
        newBuildings[i].floors[j].nr = floor.nr
        if(floor.plan && floor.planUrl){
            newBuildings[i].floors[j].plan = floor.plan
            newBuildings[i].floors[j].planUrl = floor.planUrl
            if(!newBuildings[i].floors[j].rotation)
                newBuildings[i].floors[j].rotation = 0   
        }
        if (this._isMounted){
            this.setState({
                lBuildings: newBuildings
            }, this.selectFloor(bId, this.state.lBuildings[i].floors[j]))
        }
    }

    deleteFloorOrBuilding = (type, floorId, buildingId) => {
        if(type === "floor"){
            let i = this.state.lBuildings.findIndex(b => b.id === buildingId)
            let j = this.state.lBuildings[i].floors.findIndex(f => f.id === floorId)
            if (i !== -1 && j !== -1){
                let newBuildings = this.state.lBuildings;
                newBuildings[i].floors.splice(j, 1);

                let newDeleteData = this.state.toDelete;
                newDeleteData.push({type: type, floorId: floorId});

                this.setState({lBuildings: newBuildings, selectedFloor: null, toDelete: newDeleteData});       
            }
        }
        if(type === "building"){
            let i = this.state.lBuildings.findIndex(b => b.id === buildingId)
            if(i !== -1){
                let newBuildings = this.state.lBuildings;
                newBuildings.splice(i, 1);
                
                let newDeleteData = this.state.toDelete;
                newDeleteData.push({type: type, buildingId: buildingId});

                this.setState({lBuildings: newBuildings, selectedFloor: null, toDelete: newDeleteData});       
            }
        }
    };

    render(){
        const { t } = this.props
        return(
            <form>
                {this.state.location ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose} />
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitEditLocation} disabled={this.state.lBuildings.length > 0 ? false: true}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField onChange={this.changeLocationName} value={this.state.lName} label={t('locations.name')} icon={<PlaceIcon/>}/>
                            <IconTextField onChange={this.changeLocationAddress} value={this.state.lAddress}  label={t('locations.address')} onEnd={this.findGeoAdress} icon={<GpsNotFixed/>}/>
                            <IconTextField onChange={this.changeLocationDescription} value={this.state.lDescription}  label={t('locations.description')} icon={<SubjectIcon/>}/>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={1}/>
                                    <Grid item xs={11}>
                                        <Button variant="contained" color="secondary" onClick={this.openBuildingForm}>{t('locations.newBuildingButton')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {this.state.newBuildingOpen ? (
                                <NewBuildingForm submit={this.addNewBuilding} closeForm={this.closeBuildingForm} />
                            )
                            : (
                               <div/> 
                            )}
                            {this.state.lBuildings ? (
                                this.state.lBuildings.map(building =>
                                    <BuildingFloorsEdit key={building.id} 
                                    building={building} 
                                    addFloorToBuilding={this.addFloorToBuilding}
                                    editBuildingFloor={this.editBuildingFloor} 
                                    selectFloor={this.selectFloor}
                                    selectedFloor={this.state.selectedFloor}
                                    removeFloorBuilding={this.deleteFloorOrBuilding}
                                    selectDefaultFloor={this.selectDefaultFloor} />
                                )
                            ):(<div/>)}
                        </Grid> 
                    </Grid>
                    <Grid item xs={8}>
                        <Grid item xs={12}>
                            <LocationMap mapCenter={this.state.mapCenter} 
                                        floor={this.state.selectedFloor} 
                                        purge={this.state.purge} 
                                        resetPurge={this.resetPurge} 
                                        changeRotation={this.changeRotation}
                                        changeBounds={this.changeBounds}
                                        centerChanged={this.state.centerChanged} />
                        </Grid>
                    </Grid>
                </Grid>
                )
                :(<div />)}
            </form>
        );
    };
};


const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        editLocation(projectId, locationId, data){
            dispatch(putLocation(projectId, locationId, data)).then(() => dispatch(fetchLocations(projectId)));
        },
        onFetchFailed(e){
			dispatch({type: 'FETCH_LOCATION_FAILURE'});
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditLocation));