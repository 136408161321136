import React, { Component } from "react";
import { connect } from 'react-redux';
import IconTextField from "../Gui/IconTextField";
import { fetchProjects, postProject, fetchFirmwareVersions } from '../../actions/apiCalls';
import { Grid, Button, TextField, IconButton, MuiThemeProvider } from "@material-ui/core";
import CancelButton from "../Gui/CancelButton";
import SaveButton from "../Gui/SaveButton";
import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconSelector from "../Gui/IconSelector";
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import PersonIcon from '@material-ui/icons/Person';
import OutlinedSelect from "../Gui/OutlinedSelect";
import { IstokTheme } from "../../themes/style";
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';

class CreateProject extends Component{

    constructor(){
        super();
        this.state = {
            name: "",
            description: "",
            algorithm: "",
            firmwareId: "",
            users: []
        }
    };

    componentDidMount = () => {
        this.props.fetchFirmware();
    }

    onNameChange = (e) => {
        this.setState({name: e.target.value})
    }

    onDescriptionChange = (e) => {
        this.setState({description: e.target.value})
    }

    submitProject = () => {
        let data = {
            name: this.state.name,
            description: this.state.description,
            algorithm: this.state.algorithm,
            firmwareId: this.state.firmwareId,
            events: this.state.users
        };
        this.props.postProject(data);
        this.props.onClose();
    }

    onAlgChange = (e) => {
        this.setState({algorithm: e.target.value})
    }

    onUserAdd = (e) => {
        this.setState({users: [...this.state.users, {"type": "add", "email": "", "role": "user"}]});
    }

    clearUser = (e, ind)=> {
        let newUsers = [...this.state.users];
        // Od indeksu ind usuń jeden obiekt
        newUsers.splice(ind, 1);
        this.setState({users: newUsers});
    }

    onUserEmailChange = (e, ind) => {
        let newUsers = [...this.state.users];
        newUsers[ind].email = e.target.value;
        this.setState({users: newUsers});
    }

    onUserRoleChange = (e, ind) => {
        let newUsers = [...this.state.users];
        newUsers[ind].role = e.target.value;
        this.setState({users: newUsers});
    }

    onFirmwareChange = (e) => {
        this.setState({firmwareId: e.target.value})
    }

    isProjectValid = () => { 
        if (this.state.name && this.state.description && this.state.algorithm && this.state.firmwareId)
            return false;
        return true;
    }

    render(){
        const { t } = this.props;
        let height = 55;
        const algorithmData = [
            {id:"esparOnly", attributes:{name: t("algorithm.esparOnly")}},
            {id:"referenceNodes", attributes:{name: t("algorithm.refNodes")}}
        ]
        const userRoles = [
            {id:"user", attributes:{name: t("settings.role.regularUser")}},
            {id:"admin", attributes:{name: t("settings.role.admin")}}
        ]
        return(
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitProject} 
                                    disabled={this.isProjectValid()} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField value={this.state.name} onChange={this.onNameChange} label= {t("createProject.projName")} icon={<DescriptionIcon />}/>
                            <IconTextField value={this.state.description} onChange={this.onDescriptionChange} label={t("createProject.projDescription")} icon={<SubjectIcon />} />
                            <IconSelector value={this.state.algorithm} data={algorithmData} labelWidth={140} 
                                label={t("createProject.locAlgorithm")} icon={<GpsFixedIcon />} onChange={this.onAlgChange} />
                            <IconSelector value={this.state.firmwareId} data={this.props.firmwares}
                                label={t("createProject.firmwareVersion")} icon={<CloudUploadIcon />} onChange={this.onFirmwareChange} />
                            {this.state.users.map((user, i) => 
                                <Grid item xs={12} key={i}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <IconButton style={{pointerEvents: 'none'}}>
                                                <PersonIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiThemeProvider theme={IstokTheme}>
                                                <TextField
                                                    InputProps={{style:{height}}}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }} label={"Email"}
                                                    value={user.email} variant="outlined" fullWidth
                                                    onChange={e => this.onUserEmailChange(e, i)}/>
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <OutlinedSelect label={t("settings.role.role")} value={user.role}
                                                data={userRoles} 
                                                variant="outlined" 
                                                onChange={e => this.onUserRoleChange(e, i)}
                                                fullWidth labelWidth={40} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="medium" onClick={e => this.clearUser(e, i)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={8}/>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" color="secondary" onClick={this.onUserAdd}>{t("createProject.addUser")}</Button>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        projects: state.projects.userProjects,
        firmwares: state.projects.firmwares
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        postProject(data){
            dispatch(postProject(data)).then(() => dispatch(fetchProjects()))
        },
        fetchFirmware(){
            dispatch(fetchFirmwareVersions())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateProject));