import React from 'react';
import { Button } from "@material-ui/core";
import {  useTranslation } from 'react-i18next';

let cancelStyle = {
    width: 80,
    background: '#7a7a7a',
    color: 'white',
};


function CancelButton (props){
    const { t } = useTranslation();
    return(
        <Button variant="contained" style={cancelStyle} onClick={props.onClick}>
            {t("common.cancelButton")}
        </Button>
    )
}

export default CancelButton;