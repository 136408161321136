import React from 'react'
import {Field, reduxForm } from 'redux-form'
import Button from '@material-ui/core/Button';
import { Typography, TextField, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const renderTextField = ({
    input,
    label,
    meta: { touched, error },
    ...custom
  }) => (
    <TextField
      variant="standard"
      {...input}
      {...custom}
    />
  )

const LoginForm = (props) => {

    const openRegister = (e) => {
      e.preventDefault();
      props.handleOpen();
    } 

    const { handleSubmit, pristine, submitting } = props
    const { t } = useTranslation();
    return(
        <form onSubmit={handleSubmit}>

            <Field name="username" component={renderTextField} type="text" placeholder={t("login.username")}/>
          
            <Field name="password" component={renderTextField} type="password" placeholder={t("login.password")}/>
            <div>
                <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>{t('login.signIn')}</Button>
            </div>
            {/* {errors.map(error => <Typography color="error" key={error}>{error}</Typography>)} */}
            <Typography variant="body2">{t("login.noAccount")} ?
            <Link href="/" onClick={e => openRegister(e)} color="secondary" underline="none"> {t("login.signUp")}</Link>
            </Typography>
            <Typography variant="body2"><Link href="/" onClick={e => e.preventDefault} color="secondary" underline="none"> {t("login.forgotPassword")} ? </Link></Typography>
        </form>
    )
}

export default reduxForm({
    form: 'login',
})(LoginForm)