import React, { Component } from "react"
import scriptLoad from '../../scriptLoader';

export default class Map extends Component {

    constructor(){
        super();
        this.map = null;
    }

    componentDidMount(){
        if(!(typeof window.google === 'object' && typeof window.google.maps === 'object')){
            scriptLoad("https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_KEY+"&v=3.exp&libraries=geometry,drawing,places").then(res => this.initMap())
        }
        else{
            this.initMap()
        }
    }

    initMap = () => {
        this.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 19,
              center: this.props.mapCenter
            }
        )
    }

    render(){
        return(
            <div>
                <div id="googleMap" style={{width: "100%", height: '80vh'}}/>
            </div>
        )
    }
}