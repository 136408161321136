import React, {Component} from 'react'
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import   eng_flag   from  '../../images/eng_flag.png';
import   pl_flag   from  '../../images/pl_flag.png';

class LanguageForm extends Component{
   
    handleLanguageChange = (lng) => {

        this.props.i18n.changeLanguage(lng)
    }

    render(){

        const button_style = {
            maxWidth: '45px',
            maxHeight: '45px',
            minWidth: '45px', 
            minHeight: '45px',
            float: 'right',
            margin: '5px'
        };

        return(
            <div>
                <Button style={button_style} onClick = {e => this.handleLanguageChange('en')}><img src = { eng_flag } alt="eng img" /></Button>
                <Button style={button_style} onClick = {e => this.handleLanguageChange('pl')}><img src = { pl_flag } alt="pl img"  /></Button>
            </div>


        )
    }
}
export default withTranslation()(LanguageForm);