import React, { Component } from 'react';
import IconTextField from '../Gui/IconTextField';
import SaveButton from '../Gui/SaveButton';
import { Grid } from '@material-ui/core';
import CancelButton from '../Gui/CancelButton';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import IconSelector from '../Gui/IconSelector';
import PlaceIcon from "@material-ui/icons/Place";
import LanguageIcon from '@material-ui/icons/Language';
import Icon from '@mdi/react';
import { mdiFileDocument } from '@mdi/js';
import { gray } from '../../themes/style';
import { connect } from 'react-redux';
import { patchGateway, fetchGateways, fetchGatewayTemplates } from '../../actions/apiCalls';
import { fetchLocations, fetchReferenceNodes } from '../../actions/apiCalls';
import DevicesMap from '../Map/DevicesMap';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

class EditGateway extends Component {

    constructor(){
        super();
        this.state={
            mapCenter: { lat: 54.370765, lng: 18.613733},
            currentTemplateId: "",
            currentFloorId: "",
            gatewayName: "",
            shownGateways: [],
            shownReferenceNodes: [],
            currentFloor: null,
            position: { lat: 54.370765, lng: 18.613733},
        };
        this._mounted = false;
        this._first = true;
    }


    componentDidMount = () => {
        try{
            if(this.props.gatewayId){
                if(this.props.projectId){
                    this.props.fetchProjectReferenceNodes(this.props.projectId);
                    this.props.fetchProjectGateways(this.props.projectId)
                    this.props.fetchProjectGatewayTemplates(this.props.projectId);
                    this.props.fetchProjectLocations(this.props.projectId);
                    this._mounted = true;
                }
            }
        }catch(e){
            console.log(e)
        }
    }

    onMapMounted = () => {
        this._mapMounted = true;
        if(this.props.projectId && this.props.gatewayId){
            let url = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/gateways/'+this.props.gatewayId
            axios.get(url).then(res => this.setState({
                gatewayName: res.data.data[0].attributes.name,
                mapCenter: {lat: res.data.data[0].attributes.latitude, lng:res.data.data[0].attributes.longitude},
                position: {lat: res.data.data[0].attributes.latitude, lng:res.data.data[0].attributes.longitude},
                currentFloorId: res.data.data[0].relationships.floor.data.id,
                currentTemplateId: res.data.data[0].relationships.gatewayTemplate.data.id
            }, this.setFloor(res.data.data[0].relationships.floor.data.id)))
            .catch(e => {
                this.props.onFetchFailed(e)
                this.props.onClose();
            })
        }else{
            this.props.onClose();
        }      
    }
    
    submitGateway = () => {
        let data = {
            name: this.state.gatewayName,
            latitude: this.state.position.lat,
            longitude: this.state.position.lng,
            templateId: this.state.currentTemplateId,
            floorId: this.state.currentFloorId
        };

        if(this.props.projectId && this.props.gatewayId){
            this.props.updateGateway(this.props.projectId, this.props.gatewayId, data);
            this.props.onClose();
        }
    }

    setFloor = (floorId) => {
        let location = this.props.locations.find(location =>
            location.buildings.find(building =>
                building.floors.find(floor =>
                    floor.id === floorId
                )
            )
        )

        let shownGateways = this.props.gateways.filter(function(gateway){
            return gateway.relationships.floor.data.id === floorId && gateway.id !== this.props.gatewayId
        }.bind(this))

        let shownReferenceNodes = this.props.referenceNodes.filter(function(referenceNode){
            return referenceNode.relationships.floor.data.id === floorId
        })

        let floor = null
        this.props.locations.map(l => l.buildings.map(b => b.floors.map(f=> f.id === floorId ? (floor = f):(null))))
        if(this._mounted){
            if(floor && floor.planUrl && floor.boundsNE && floor.boundsSW){
                this.setState({currentFloorId: floorId,
                    mapCenter:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                    position:{lat: location.attributes.latitude, lng: location.attributes.longitude}, currentFloor: floor,
                    shownGateways: shownGateways,
                    shownReferenceNodes: shownReferenceNodes,
                    refreshMap: true
                })
            }
            else{
                this.setState({currentFloorId: floorId,
                    mapCenter:{lat: location.attributes.latitude, lng: location.attributes.longitude},
                    position:{lat: location.attributes.latitude, lng: location.attributes.longitude}, currentFloor: null,
                    shownGateways: shownGateways,
                    shownReferenceNodes: shownReferenceNodes,
                refreshMap: true
                })
            }
        }
    }

    onFloorSelect = (e) => {
        let floorId = e.target.value
        this.setFloor(floorId)
    }

    onNamechange = (e) => {
        this.setState({gatewayName: e.target.value, refreshMap: false})
    }

    onTemplateChange = (e) => {
        this.setState({currentTemplateId: e.target.value, refreshMap: false})
    }
    
    onPositionChange = (pos) => {
        this.setState({ 
            position: pos,
            latInput: pos.lat,
            lngInput: pos.lng,
            refreshMap: false})
    }

    onTextFieldPostionChange = (e) => {
        
        const { position } = { ...this.state };
        const currentState = position;
        let re  = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
        if(e.target.value === "" || re.test(e.target.value))
            currentState[e.target.name] = parseFloat(e.target.value)
            this.setState({
                postion: currentState,
                mapCenter: currentState
            });
    };
    
    isValidLat = () => {

        if(this.state.position.lat > -90 && this.state.position.lat < 90){
            if(!isNaN(this.state.position.lat))
                return true
        }
        else{

            return false
        } 
    };

    isValidLng = () => {

        if(this.state.position.lng > -180 && this.state.position.lng < 180)
            if(!isNaN(this.state.position.lng))
                return true
        else
            return false
    };

    render(){
        const { t } = this.props
        let isValidLat = this.isValidLat();
        let isValidLng = this.isValidLng();
        return(
            this._mounted ? (
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitGateway} disabled={this.state.gatewayName && this.state.position !== null && this.state.currentFloorId && this.state.currentTemplateId ? false: true} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField value={this.state.gatewayName} onChange={this.onNamechange} label={t("gateways.name")} icon={<DeviceHubIcon />}/>
                            <IconSelector value={this.state.currentFloorId} data={this.props.locations} labelWidth={65} label={t("gateways.location")}
                                icon={<PlaceIcon />} onChange={this.onFloorSelect} floors={true}/>
                            <IconSelector value={this.state.currentTemplateId} data={this.props.gatewayTemplates} labelWidth={165} label={t("gateways.configurationTemplate")}
                                icon={<Icon path={mdiFileDocument}
                                size={1} color={gray} />} onChange={this.onTemplateChange}/>
                            <IconTextField type = "number" name = "lat" value = {isValidLat ? (this.state.position.lat) : ""}  onChange = {this.onTextFieldPostionChange} label={t("gateways.latitude")} icon={<LanguageIcon />}/>
                            <IconTextField type = "number" name = "lng" value = {isValidLng ? this.state.position.lng : ""} onChange = {this.onTextFieldPostionChange} label={t("gateways.longitude")} icon={<LanguageIcon />}/>
                        </Grid> 
                    </Grid>
                    <Grid item xs={8}>
                        <Grid item xs={12}>
                            {/* <DevicesFloorsMap mapCenter={this.state.mapCenter} onChange={this.onPositionChange}
                                floor={this.state.currentFloor} gateways={this.props.gateways} position={this.state.position}
                                shownGateways={this.state.currentGateways} /> */}
                            <DevicesMap mapCenter={this.state.mapCenter} 
                                refreshMap={this.state.refreshMap} 
                                floor={this.state.currentFloor}
                                position={(this.isValidLat && this.isValidLng) ? this.state.position : " "} 
                                gateways={this.state.shownGateways}
                                referenceNodes={this.state.shownReferenceNodes}
                                onChange={this.onPositionChange}
                                onMapMounted={this.onMapMounted}
                                isValidLat={isValidLat}
                                isValidLng={isValidLng} 
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            ):(
                <form onSubmit={e => e.preventDefault()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                                <Grid item>
                                    <CancelButton onClick={this.props.onClose}/>
                                </Grid>
                                <Grid item>
                                    <SaveButton disabled={true} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        referenceNodes: state.referenceNodes.referenceNodes,
        gateways: state.gateways.gateways,
        locations: state.locations.locations,
        gatewayTemplates: state.gateways.gatewayTemplates
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectLocations(projectId) {
            dispatch(fetchLocations(projectId))            
        },
        fetchProjectReferenceNodes(projectId){
            dispatch(fetchReferenceNodes(projectId))
        },
        fetchProjectGatewayTemplates(projectId){
            dispatch(fetchGatewayTemplates(projectId))
        },
        fetchProjectGateways(projectId){
            dispatch(fetchGateways(projectId))
        },
        updateGateway(projectId, gatewayId, data){
            dispatch(patchGateway(projectId, gatewayId, data)).then(() => dispatch(fetchGateways(projectId)));
        },
        onFetchFailed(e){
			dispatch({type: 'FETCH_GATEWAY_FAILURE'});
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditGateway));