import React, { Component } from "react";
import { connect } from 'react-redux';
import CreateLocation from './CreateLocation'
import EditLocation from './EditLocation';
import { fetchLocations, deleteLocation } from '../../actions/apiCalls';
import { withTranslation } from 'react-i18next';
import LocationsPanel from './LocationsPanel.js'
import { Route, Switch,  } from "react-router-dom";
class Locations extends Component{

    constructor(){
        super()
        this.state = {
            currLocId: null,
        };
    };

    componentDidMount(){
        if (this.props.projectId === null)
            this.props.history.push('/');
        if (this.props.projectId !== null)
            this.props.fetchProjectLocations(this.props.projectId);
    };
  
    deleteLocation = (e, locationId) => {
        e.stopPropagation();
        if (this.props.projectId !== null)
            this.props.deleteLocation(this.props.projectId, locationId)
    };

    clickLocation = (e, locationId) => {
        this.setState({
            currLocId: locationId,
        });
        this.props.history.push(this.props.match.path + '/edit')
    };

    closeForm = () => {
        this.setState({
            currLocId: null
        })
        this.props.history.push('/locations')
    }

    addNewLocation = () => {
        this.props.history.push(this.props.match.path + '/create')
    };

    render(){
        const { t } = this.props;
        const attributes = ["name", "floorNr", "deviceNr"]
        const headers = [t("locations.name"), t("locations.floors"), t("locations.devices")]
        return(
            <div>
                <Switch>
                    <Route exact path={'/locations'}>
                        <LocationsPanel data={this.props.locations} name={t("location", {count: 5})}
                                    onItemDelete={this.deleteLocation}
                                    onItemClick={this.clickLocation}
                                    attributes={attributes}
                                    addNewLocation={this.addNewLocation}
                                    headers={headers}
                                    deleteWarningMessage={"common.removeLocationWarning"} />
                    </Route>
                    <Route path={this.props.match.url + "/create"}>
                        <CreateLocation onClose={this.closeForm}/>
                    </Route>
                    <Route path={this.props.match.url + "/edit"}>
                        <EditLocation locationId ={this.state.currLocId} onClose={this.closeForm} /> 
                    </Route>
                </Switch>
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        locations: state.locations.locations,
        errors: state.locations.errors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectLocations(projectId) {
            dispatch(fetchLocations(projectId)).then(() => console.log("Fetched Locations"));
        },
        deleteLocation(projectId, locationId) {
            dispatch(deleteLocation(projectId, locationId)).then(() => dispatch(fetchLocations(projectId)));
        },
        clearLocationErrors()
        {
            dispatch({type: 'LOCATION_CLEAR_ERRORS'})
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Locations));
