import React, { Component } from "react";
import SaveButton from '../Gui/SaveButton';
import { Grid, TextField, MuiThemeProvider, Button } from '@material-ui/core';
import CancelButton from '../Gui/CancelButton';
import IconSelector from '../Gui/IconSelector';
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import IconTextField from '../Gui/IconTextField';
import InformationVariantIcon from 'mdi-react/InformationVariantIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import { connect } from 'react-redux';
import { postGatewayTemplate, postReferenceNodeTemplate, postBeaconTemplate } from '../../actions/apiCalls';
import { fetchBeaconTemplates, fetchGatewayTemplates, fetchReferenceNodeTemplates } from '../../actions/apiCalls';
import { withTranslation } from 'react-i18next';
import { IstokTheme } from "../../themes/style";
import { withSnackbar } from "notistack";

class CreateTemplate extends Component{
    
    constructor(){
        super();
        this.state = {
            name: "",
            interval: 0,
            type: "Gateway",
            security: "",
            ssid: "",
            password: "",
            icon: "",
            iconUrl: null
        };
    };

    submitTemplate = () => {
        if(this.props.projectId){
            switch(this.state.type){
                case "Gateway":
                    let dataGT = {
                        name: this.state.name,
                        description: "",
                        ssid: this.state.ssid,
                        password: this.state.password
                    }
                    this.props.postGatewayTemplate(this.props.projectId, dataGT);
                    break;
                case "Beacon":
                    let dataBT = {
                        name: this.state.name,
                        defaultIcon: this.state.icon,
                        defaultIconUrl: this.state.iconUrl
                    }
                    this.props.postBeaconTemplate(this.props.projectId, dataBT);
                    break;
                case "Reference Node":
                    let dataRNT = {
                        name: this.state.name,
                        description: "",
                        interval: this.state.interval
                    }
                    this.props.postReferenceNodeTemplate(this.props.projectId, dataRNT);
                    break;
                default:
                    break;
            }
            this.props.onClose();
        }
    };

    validateData = () => {
        if(this.state.type === "Gateway"){
            return this.state.name && this.state.ssid && this.state.password ? false: true;
        }
        else{
            return this.state.name ? false: true
        }
    }

    onTypeChange = (e) => {
        if (e.target.value !== this.state.type){
            this.setState({
                type: e.target.value,
                name: "",
                interval: 0,
                security: "",
                ssid: "",
                password: "",
                icon: "",
                iconUrl: null
            })
        }
    }

    onSecChange = (e) => {
        this.setState({security: e.target.value})
    };

    onNameChange = (e) => {
        this.setState({name: e.target.value})
    };

    onIntervalChange = (e) => {
        this.setState({interval: e.target.value})
    };

    onSSIDChange = (e) => {
        this.setState({ssid: e.target.value})
    };

    onPasswordChange = (e) => {
        this.setState({password: e.target.value})
    };
    
    fileSelectedHandler = (event) => {
        let image = event.target.files[0]
        let reader = new FileReader()
        
        reader.onload = (e) => {
            if(image.size < 100000){
                this.setState({icon: image.name, iconUrl: e.target.result})
            }
            else{
                const { t } = this.props;
                this.props.enqueueSnackbar(t("snackbar.imageTooLarge"),  { 
                    variant: 'error',
                });
            }
        }
        reader.readAsDataURL(event.target.files[0])
    }

    render(){
        const { t } = this.props
        let height = 36;
        const typeData = [
            {id:"Beacon", attributes:{name: t("templates.beaconType")}},
            {id:"Reference Node", attributes:{name: t("templates.referenceNodeType")}},
            {id:"Gateway", attributes:{name: t("templates.gatewayType")}}
        ];
        const securityData = [
            {id:"WPA2 Personal/PSK", attributes:{name: "WPA2 Personal/PSK"}}
        ];
        return(
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitTemplate} disabled={ this.validateData() } />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        {this.state.type === "Gateway" ?
                        (
                            <Grid container spacing={3}>
                                <IconSelector value={this.state.type} data={typeData}label={t("templates.type")}
                                icon={<FileDocumentIcon />} onChange={this.onTypeChange}/>
                                <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("templates.name")} icon={<InformationVariantIcon />} />
                                <IconSelector value={this.state.security} data={securityData}  label={t("templates.securityType")}
                                    icon={<SettingsIcon />} onChange={this.onSecChange}/>
                                <IconTextField value={this.state.ssid} onChange={this.onSSIDChange} label={t("templates.ssid")} icon={null}/>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1} />
                                        <Grid item xs={11}>
                                            <TextField onChange={this.onPasswordChange} value={this.state.password} type="password" label={t("templates.password")} variant="outlined" fullWidth/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        )
                        :(
                            this.state.type === "Reference Node" ? (
                                <Grid container spacing={3}>   
                                    <IconSelector value={this.state.type} data={typeData} label={t("templates.type")}
                                        icon={<FileDocumentIcon />} onChange={this.onTypeChange}/>
                                    <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("templates.name")} icon={<InformationVariantIcon />}/>
                                </Grid>):(
                                <Grid container spacing={3}>   
                                    <IconSelector value={this.state.type} data={typeData} label={t("templates.type")}
                                        icon={<FileDocumentIcon />} onChange={this.onTypeChange}/>
                                    <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("templates.name")} icon={<InformationVariantIcon />}/>
                                    <Grid item xs={1}/>
                                    <Grid item xs={8}>
                                        <MuiThemeProvider theme={IstokTheme}>
                                            <TextField  InputProps={{style:{height}, readOnly: true}}
                                                label={t("templates.defaultIcon")}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={this.state.icon}
                                                fullWidth disabled variant ="outlined"/>
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="raised-button-file"
                                            multiple
                                            type="file"
                                            onChange={this.fileSelectedHandler}
                                        />
                                        <label htmlFor="raised-button-file">
                                            <Button fullWidth variant="contained" color="secondary" component="span">
                                                {t("beacons.uploadBeaconButton")}
                                            </Button>
                                        </label>
                                    </Grid>
                                </Grid>
                            )
                        )}
                    </Grid>
                    <Grid item xs={8} />
                </Grid>
            </form>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        postGatewayTemplate(projectId, data) {
            dispatch(postGatewayTemplate(projectId, data)).then(() => dispatch(fetchGatewayTemplates(projectId)));
        },
        postReferenceNodeTemplate(projectId, data) {
            dispatch(postReferenceNodeTemplate(projectId, data)).then(() => dispatch(fetchReferenceNodeTemplates(projectId)));
        },
        postBeaconTemplate(projectId, data) {
            dispatch(postBeaconTemplate(projectId, data)).then(() => dispatch(fetchBeaconTemplates(projectId)));
        }
    }
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateTemplate)));