import React, { Component} from "react"
import { Grid, Typography, DialogContent, TextField, Button, IconButton } from "@material-ui/core";
import OutlinedSelect from "../Gui/OutlinedSelect";
import SaveButton from "../Gui/SaveButton";
import CancelButton from "../Gui/CancelButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withTranslation } from 'react-i18next';

class ConfigurationDialog extends Component{

    constructor(){
        super();
        this.state = {
            url: "",
            contentType: "",
            headers: [], 
            body: "{}",
            error: ""
        }
    }

    componentDidMount = () => {
        // console.log(this.props)
        if (this.props.data){
            try{
                let parsedHeaders = []
                this.props.data.headers.forEach(header =>{
                    let d = {};
                    let key = Object.keys(header)[0]
                    d["header"] = key
                    d["val"] = header[key]
                    parsedHeaders.push(d)    
                });
                this.setState({
                    url: this.props.data.url ? this.props.data.url : "",
                    headers: parsedHeaders,
                    contentType: this.props.data["content-type"] ?  this.props.data["content-type"] : "",
                    body: this.props.data.body ? JSON.stringify(this.props.data.body) : "{}"
                })
            }
            catch(e){

            }
        }
    }

    onCTChange = (e) => {
        this.setState({
            contentType: e.target.value
        });
    }

    onURLChange = (e) => {
        this.setState({
            url: e.target.value
        });
    }


    onBodyChange = (e) => {
        let error = "";
        try{
            let body = e.target.value
            JSON.parse(body);
        }
        catch(e){
            error = "Invalid String"
        }
        this.setState({
            body: e.target.value,
            error: error
        });
    }

    addNewHeader = () => {
        this.setState({headers: [...this.state.headers, {"header": "", "val": ""}]})
    }

    clearHeader = (e, i) => {
        let newHeaders = [...this.state.headers];
        newHeaders.splice(i, 1);
        this.setState({headers: newHeaders});
    }

    submitWebhook = () => {
        let newHeaders = [];
        this.state.headers.forEach(header => {
            let d = {}
            if(header.header){
                d[header.header] = header.val
                newHeaders.push(d)
            }
        })
        let data = {
            url: this.state.url,
            headers: newHeaders,
            "content-type": this.state.contentType,
            body: JSON.parse(this.state.body)
        }
        this.props.submitWebhook(this.props.ind, data)
        // console.log(data);
    }

    onHeaderChange = (e, i) => {
        let newHeaders = [...this.state.headers];
        newHeaders[i].header = e.target.value;
        this.setState({actions: newHeaders});
    }

    onHeaderValChange = (e, i) => {
        let newHeaders = [...this.state.headers];
        newHeaders[i].val = e.target.value;
        this.setState({actions: newHeaders});;
    }

    render(){
        const { t } = this.props
        let leftPadding = 20;
        const contentTypeData = [
            {id:"application/json", attributes:{name: "application/json"}},
        ];
        return(
            <Grid container>
                <Grid item xs={12} className="dialogTitle">
                    <Typography variant="h6" style={{paddingLeft: leftPadding}}>{t('policies.webhook.newWebhook')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DialogContent>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <TextField label="URL" onChange={this.onURLChange} variant="outlined" fullWidth value={this.state.url} />
                            </Grid>
                            <Grid item xs={5}>
                                <OutlinedSelect labelWidth={90} onChange={this.onCTChange} label={t('policies.webhook.contType')} data={contentTypeData} value={this.state.contentType}/>
                            </Grid>
                            <Grid item xs={7}/>
                            {this.state.headers.map((header, i) => 
                                <Grid item xs={12} key={i}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <TextField label={t('policies.webhook.header')} onChange={e => this.onHeaderChange(e, i)} variant="outlined" fullWidth value={this.state.headers[i].header}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label={t('policies.webhook.headerText')} onChange={e => this.onHeaderValChange(e, i)} variant="outlined" fullWidth value={this.state.headers[i].val} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={e => this.clearHeader(e, i)}>
                                                <DeleteIcon color="secondary" />
                                            </IconButton>
                                        </Grid> 
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={2}>
                                <Button variant="contained" color="secondary" onClick={this.addNewHeader}>{t('policies.webhook.addHeader')}</Button> 
                            </Grid>
                            <Grid item xs={10}/>
                            <Grid item xs={12}>
                                <TextField label="Body" error={this.state.error ? (true): (false)} helperText={this.state.error} onChange={this.onBodyChange} multiline rows={8} variant="outlined" fullWidth value={this.state.body} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} />
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <CancelButton onClick={this.props.onClose} />
                                            </Grid>
                                            <Grid item >
                                                <SaveButton disabled={
                                                        this.state.url && this.state.contentType && !this.state.error? (false):(true)
                                                    }  
                                                    onClick={this.submitWebhook} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} />
                        </Grid>
                    </DialogContent>
                </Grid>
            </Grid>
        )
    }
}


export default withTranslation()(ConfigurationDialog);