import React, { Component } from "react";
import { connect } from 'react-redux';
import IconTextField from "../Gui/IconTextField";
import { fetchProjects, patchProject, fetchFirmwareVersions } from '../../actions/apiCalls';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { Grid, Button, IconButton, TextField, MuiThemeProvider } from "@material-ui/core";
import CancelButton from "../Gui/CancelButton";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveButton from "../Gui/SaveButton";
import axios from "axios";
import IconSelector from "../Gui/IconSelector";
import PersonIcon from '@material-ui/icons/Person';
import OutlinedSelect from "../Gui/OutlinedSelect";
import { IstokTheme } from "../../themes/style";
import ClearIcon from '@material-ui/icons/Clear';
import { withTranslation } from 'react-i18next';


class EditProject extends Component{

    constructor(){
        super();
        this.state = {
            name: "",
            description: "",
            algorithm: "",
            firmwareId: "",
            members: [],
            newMembers: [],
            events: []
        }
    };

    componentDidMount = () => {
        this.props.fetchFirmware()
        if(this.props.projectId && this.props.role === "superadmin"){
            let url = process.env.REACT_APP_API_URL+'projects/'+this.props.currentProjectId
            axios.get(url).then(res => {
                this.setState({
                    name: res.data.data[0].attributes.name,
                    description: res.data.data[0].attributes.description,
                    algorithm: res.data.data[0].attributes.algorithm,
                    members: res.data.data[0].attributes.members,
                    firmwareId: res.data.data[0].relationships.firmwareVersion.data.id
                })
            }
            ).catch(e => {
                this.props.onFetchError(e);
                this.props.onClose();
            })
        }
        else{
            this.props.onClose();
        }
    };

    onNameChange = (e) => {
        this.setState({name: e.target.value})
    };

    onAlgChange = (e) => {
        this.setState({algorithm: e.target.value})
    }

    onDescriptionChange = (e) => {
        this.setState({description: e.target.value})
    };

    submitProject = () => {
        let events = [...this.state.events];
        events = events.concat(this.state.newMembers);
        let data = {
            name: this.state.name,
            description: this.state.description,
            algorithm: this.state.algorithm,
            firmwareId: this.state.firmwareId,
            events: events
        };
        this.props.patchProject(this.props.currentProjectId, data);
        this.props.onClose();
    };

    onMemberProjectRemoval = (e, ind) => {
        let usr = [...this.state.members];
        let email = this.state.members[ind].email;
        let role = this.state.members[ind].role;
        usr.splice(ind, 1);
        this.setState({
            events: [...this.state.events, {"type": "del", "email": email, "role": role}]
            ,members: usr});
    }

    onFirmwareChange = (e) => {
        this.setState({firmwareId: e.target.value})
    }

    onUserAdd = (e) => {
        this.setState({
            newMembers: [...this.state.newMembers, {"type": "add", "email": "", "role": "user"}]}
        );
    }

    clearUser = (e, ind)=> {
        let usr = [...this.state.newMembers];
        usr.splice(ind, 1);
        this.setState({newMembers: usr});
    }

    newMemberEmailChange = (e, ind)=> {
        let usr = [...this.state.newMembers];
        usr[ind].email = e.target.value
        this.setState({newMembers: usr});
    }

    newMemberRoleChange = (e, ind)=> {
        let usr = [...this.state.newMembers];
        usr[ind].role = e.target.value
        this.setState({newMembers: usr});
    }

    render(){
        const { t } = this.props
        let height = 55;
        const algorithmData = [
            {id:"esparOnly", attributes:{name: t("algorithm.esparOnly")}},
            {id:"referenceNodes", attributes:{name: t("algorithm.refNodes")}}
        ]
        const userRoles = [
            {id:"user", attributes:{name: t("settings.role.regularUser")}},
            {id:"admin", attributes:{name: t("settings.role.admin")}}
        ]
        
        return(
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.submitProject} 
                                    disabled={this.state.name && this.state.description && this.state.algorithm && this.state.firmwareId ? false: true} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField value={this.state.name} onChange={this.onNameChange} label={t("createProject.projName")} />
                            <IconTextField value={this.state.description} onChange={this.onDescriptionChange} label={t("createProject.projDescription")} />
                            <IconSelector value={this.state.algorithm} data={algorithmData} labelWidth={140} 
                               label={t("createProject.locAlgorithm")} icon={<GpsFixedIcon />} onChange={this.onAlgChange} />
                            <IconSelector value={this.state.firmwareId} data={this.props.firmwares}
                                label={t("createProject.firmwareVersion")} icon={<CloudUploadIcon />} onChange={this.onFirmwareChange} />
                            {this.state.members.map((member, i) => 
                            <Grid item xs={12} key={i}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <IconButton style={{pointerEvents: 'none'}}>
                                            <PersonIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MuiThemeProvider theme={IstokTheme}>
                                            <TextField
                                                InputProps={{style:{height}}}
                                                disabled={true}
                                                InputLabelProps={{
                                                    shrink: true
                                                }} label={"Email"}
                                                value={member.email} variant="outlined" fullWidth />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <OutlinedSelect label={t("settings.role.role")} value={member.role}
                                            disabled
                                            data={userRoles} 
                                            variant="outlined" 
                                            fullWidth labelWidth={40} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton size="medium" onClick={e => this.onMemberProjectRemoval(e, i)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            )}
                            
                            {this.state.newMembers.map((user, i) =>
                                <Grid item xs={12} key={i}>
                                  <Grid container spacing={1}>
                                        <Grid item xs={1}>
                                            <IconButton style={{pointerEvents: 'none'}}>
                                                <PersonIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiThemeProvider theme={IstokTheme}>
                                                <TextField
                                                    InputProps={{style:{height}}}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }} label={"Email"}
                                                    onChange = {e => this.newMemberEmailChange(e, i)}
                                                    value={user.email} variant="outlined" fullWidth />
                                            </MuiThemeProvider>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <OutlinedSelect label={t("settings.role.role")} value={user.role}
                                                data={userRoles} 
                                                variant="outlined" 
                                                onChange = {e => this.newMemberRoleChange(e, i)}
                                                fullWidth labelWidth={40} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton size="medium" onClick={e => this.clearUser(e, i)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Grid>
                                  </Grid>
                                </Grid>
                            )}

                            <Grid item xs={8}/>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" color="secondary" onClick={this.onUserAdd}> {t("createProject.addUser")}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        projects: state.projects.userProjects,
        firmwares: state.projects.firmwares
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        patchProject(projectId, data){
            dispatch(patchProject(projectId, data)).then(() => dispatch(fetchProjects()))
        },
        onFetchError(e){
            dispatch({type: 'FETCH_PROJECT_FAILURE'});
        },
        fetchFirmware(){
            dispatch(fetchFirmwareVersions())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditProject));