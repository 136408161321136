import React from 'react';
import { Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

function SaveButton(props) {
    const { t } = useTranslation();
    return(
        <Button disabled={props.disabled} variant="contained" color="primary" style={{width: 80}} onClick={props.onClick}>
            {t('common.saveButton')}
        </Button>
    );
};

export default SaveButton;