const initialState = {
    policies: [],
    notices: [],
}

export default function PoliciesReduccer(state = initialState, action) {

    if(action.type === 'FETCH_POLICIES'){
        return {
            ...state,
            policies: action.payload.data,
            notices: []
        }
    }
    if (action.type === 'POLICIES_CLEAR_NOTICES')
    {
        return{
            ...state,
            notices: []
        }
    }
    if (action.type === 'FETCH_POLICIES_FAILURE'){
        return {
            ...state,
            policies: [],
            notices: [{type: "error", notifyKey: "snackbar.policiesFetchError"}]
        }
    }

    if (action.type === 'FETCH_POLICY_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.policyFetchError"}]
        }
    }

    if (action.type === 'POST_POLICIES'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.policyPostSuccess"}]
        }
    }
    if (action.type === 'POST_POLICIES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.policyPostError"}]
        }
    }

    if (action.type === 'DELETE_POLICY'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.policyDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_POLICY_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.policyDeleteError"}]
        }
    }

    if (action.type === 'PATCH_POLICY'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.policyPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_POLICY_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.policyPatchError"}]
        }
    }


    return state
}
  