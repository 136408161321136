const initialState = {
    zones: [],
    notices: [],
}

export default function ReferenceNodesReducer(state = initialState, action) {

    if(action.type === 'ZONES_CLEAR_NOTICES')
    {   
        return {
            ...state,
            notices: []

        }
    }

    if(action.type === 'FETCH_ZONES'){
        return {
            ...state,
            zones: action.payload.data,
            notices: []
        }
    }
    if (action.type === 'FETCH_ZONES_FAILURE'){
        return {
            ...state,
            zones: [],
            notices: [{type: "error", notifyKey: "snackbar.zonesFetchError"}]
        }
    }

    if (action.type === 'FETCH_ZONE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.zoneFetchError"}]
        }
    }

    if (action.type === 'POST_ZONES'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.zonesPostSuccess"}]
        }
    }
    if (action.type === 'POST_ZONES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.zonesPostError"}]
        }
    }

    if (action.type === 'DELETE_ZONE'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.zoneDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_ZONE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.zoneDeleteError"}]
        }
    }

    if (action.type === 'PATCH_ZONE'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.zonePatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_ZONE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.zonePatchError"}]
        }
    }


    return state
}
  