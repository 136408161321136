import React, { Component } from "react";
import { Grid, TextField, InputAdornment, IconButton, Dialog } from "@material-ui/core";
import DomainIcon from '@material-ui/icons/Domain';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import MoreIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import "./Locations.css";
import FloorDialog from "./FloorDialog";
import uuidv4 from "uuid/v4";
import Icon from '@mdi/react';
import { mdiStairs, mdiFloorPlan } from '@mdi/js';
import { gray, green } from "../../themes/style";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'

class BuildingFloors extends Component{

    constructor(){
        super();
        this.state ={
            open: false,
        };
    }

    openDialog = () => {
        this.setState({open: true});
    }

    submitFloor = (nr, name, planName, planUrl) => {
        let floor = {
            "id": uuidv4(),
            "nr": Number.parseInt(nr),
            "name": name,
            "plan": planName,
            "planUrl": planUrl,
            "rotation": 0,
            "boundsNE": {},
            "boundsSW": {},
            "defaultFloor": false
        };
        this.props.addFloorToBuilding(this.props.building.id, floor)
        this.props.selectFloor(this.props.building.id, floor);
        this.closeDialog();
    }

    closeDialog = () => {
        this.setState({open: false});
    }

    selectFloor = (bId, floor) => {
        this.props.selectFloor(bId, floor);
    };

    render(){
        return(
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <TextField disabled defaultValue={this.props.building.name} fullWidth
                        InputProps={{
                            startAdornment : (
                                <InputAdornment position="start">
                                    <DomainIcon color="action" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton onClick={this.openDialog}>
                                        <AddCircleIcon color="action"/>
                                    </IconButton>
                                    <IconButton>
                                        <MoreIcon color="action"/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        classes={{root: 'GrayTextField'}} />
                    </Grid>
                    <MuiThemeProvider theme={IstokTheme}>
                    {this.props.building.floors.map(f=>
                        <Grid container key={f.id}>
                            <Grid item xs={1} />
                            <Grid item xs={11}>
                            <TextField onClick={e => this.selectFloor(this.props.building.id, f)} defaultValue={"Nr: "+f.nr +" - "+f.name} fullWidth
                                InputProps={{
                                    startAdornment : (
                                        <InputAdornment position="start">
                                            <Icon path={mdiStairs}
                                                size={1} color={gray} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton onClick = {e => {this.props.selectDefaultFloor(f.id)}}>
                                                <CheckIcon color = {f.defaultFloor ? "primary" : "inherit" }/>
                                            </IconButton>
                                            <IconButton style={{pointerEvents: 'none'}}>
                                                <Icon path={mdiFloorPlan}
                                                    size={1} color={f.plan ? green: gray} />
                                            </IconButton>
                                            <IconButton>
                                                {/* Tutaj na klikniecie trzeba stoppropagation */}
                                                <MoreIcon color="action"/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    readOnly: true,
                                }}
                                variant="outlined"
                                classes={this.props.selectedFloor ? (this.props.selectedFloor.id === f.id ? ({root: 'SelectedTextField'}):(null)): (null)} />
                            </Grid>
                        </Grid>
                    )}
                    </MuiThemeProvider>
                </Grid>
                <Dialog fullWidth maxWidth="md" onClose={this.closeDialog} open={this.state.open}>
                    <FloorDialog buildingName={this.props.building.name} onClose={this.closeDialog} open={this.state.open}
                        submitFloor={this.submitFloor}/>
                </Dialog>
            </Grid>
        );
    };
};

export default BuildingFloors;
