import React, { Component } from 'react';
import IconTextField from '../Gui/IconTextField';
import SaveButton from '../Gui/SaveButton';
import { Grid, MuiThemeProvider, TextField, Button } from '@material-ui/core';
import CancelButton from '../Gui/CancelButton';
import { connect } from 'react-redux';
import { fetchBeaconTemplates, fetchBeacons, patchBeacon } from '../../actions/apiCalls'
import { gray, IstokTheme } from '../../themes/style';
import IconSelector from '../Gui/IconSelector';
import { mdiFileDocument } from '@mdi/js';
import Icon from '@mdi/react';
import { mdiTagTextOutline } from '@mdi/js';
import BluetoothIcon from '@material-ui/icons/Bluetooth'
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

class EditBeacon extends Component{

    constructor(){
        super();
        this.state={
            name: "",
            mac: "",
            templateId: "",
            icon: "",
            iconUrl: null
        }
        this._mounted = false;
    };
    
    componentDidMount = () => {
        try{
            if(this.props.projectId && this.props.beaconId){
                this.props.fetchProjectBeaconTemplates(this.props.projectId)
                let url = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/beacons/'+this.props.beaconId
                axios.get(url).then(res => this.setState({
                    name: res.data.data[0].attributes.name,
                    mac: res.data.data[0].attributes.MAC,
                    icon: res.data.data[0].attributes.icon == null ? "": res.data.data[0].attributes.icon,
                    iconUrl: res.data.data[0].attributes.iconUrl,
                    templateId: res.data.data[0].relationships.beaconTemplate.data.id
                })).catch(e => {
                    this.props.onFetchFailed(e);
                    this.props.onClose();
                })
                this._mounted = true;
            }
        }catch(e){
            console.log(e)
        }
    };

    fileSelectedHandler = (event) => {
        let image = event.target.files[0]
        let reader = new FileReader()
        
        reader.onload = (e) => {
            if(image.size < 100000){
                this.setState({icon: image.name, iconUrl: e.target.result})
            }
            else{
                this.notify("Image is too large should be below 100kb");
            }
        }
        reader.readAsDataURL(event.target.files[0])
    }

    editBeacon = () => {
        let data = {}
        if (this.state.icon !== "" && this.state.iconUrl){
            data = {
                name: this.state.name,
                MAC: this.state.mac,
                templateId: this.state.templateId,
                icon: this.state.icon,
                iconUrl: this.state.iconUrl
            }
        }
        else{
            data = {
                name: this.state.name,
                MAC: this.state.mac,
                templateId: this.state.templateId
            }
        }
        if(this.props.projectId && this.props.beaconId)
            this.props.editBeacon(this.props.projectId, this.props.beaconId, data);
        this.props.onClose();
    }

    onNamechange = (e) => {
        this.setState({name: e.target.value})
    };

    onMACchange = (e) => {
        this.setState({mac: e.target.value})
    };

    onTemplateChange = (e) => {
        this.setState({templateId: e.target.value})
    };

    notify = (message) => {
        this.props.enqueueSnackbar(message,  { 
            variant: 'error',
        });
    }

    render(){
        let height = 36;
        const { t } = this.props
        return(
            this._mounted ? (
            <form onSubmit={e => e.preventDefault()}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.editBeacon} disabled={this.state.mac.length === 12 && this.state.templateId
                                    && this.state.name ? false:true} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField onChange={this.onNamechange} value={this.state.name}  label={t('beacons.name')} icon={<BluetoothIcon />}/>
                            <IconTextField onChange={this.onMACchange} value={this.state.mac} helperText={t('beacons.macInfo')} label={t('beacons.mac')} icon={<Icon path={mdiTagTextOutline}
                                size={1} color={gray} />}/>
                            <IconSelector value={this.state.templateId} data={this.props.beaconTemplates} labelWidth={165} label={t('beacons.configurationTemplate')}
                                icon={<Icon path={mdiFileDocument}
                                size={1} color={gray} />} onChange={this.onTemplateChange}/>
                            <Grid item xs={1}/>
                            <Grid item xs={8}>
                                <MuiThemeProvider theme={IstokTheme}>
                                    <TextField  InputProps={{style:{height}, readOnly: true}}
                                        label="Icon"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={this.state.icon}
                                        fullWidth disabled variant ="outlined"/>
                                </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                    onChange={this.fileSelectedHandler}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button fullWidth variant="contained" color="secondary" component="span">
                                        {t('beacons.uploadBeaconButton')}
                                    </Button>
                                </label>
                            </Grid>
                        </Grid> 
                    </Grid>
                    <Grid item xs={8} />
                </Grid>
            </form>
            ):(
                <form onSubmit={e => e.preventDefault()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                                <Grid item>
                                    <CancelButton onClick={this.props.onClose}/>
                                </Grid>
                                <Grid item>
                                    <SaveButton disabled={true} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        beaconTemplates: state.beacons.beaconTemplates
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectBeaconTemplates(projectId) {
            dispatch(fetchBeaconTemplates(projectId)).then(() => console.log("Fetched Beacon Templates"));
        },
        editBeacon(projectId, beaconId, data){
            dispatch(patchBeacon(projectId, beaconId, data)).then(() => dispatch(fetchBeacons(projectId)));
        },
        onFetchFailed(e){
            dispatch({type: 'FETCH_BEACON_FAILURE'});
        }
    }
};

export default  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditBeacon)));