import React, {Component} from "react";
import { Grid, Button, Dialog } from "@material-ui/core";
import IconTextField from "../Gui/IconTextField";
import { connect } from "react-redux";
import SaveButton from "../Gui/SaveButton";
import CancelButton from "../Gui/CancelButton";
import { ChangePassDialog } from "./ChangePassDialog";
import { fetchUser, patchUser } from '../../actions/apiCalls';
import { withTranslation } from 'react-i18next';

class Personal extends Component{

    constructor(){
        super();
        this.state = {
            passwordChanged: false,
            oldPass: '',
            newPass: '',
            copyPass: '',
            open: false
        }
    }

    onSubmit = () => {
        let data = {
            passwordChanged: this.state.passwordChanged,
            oldPassword: this.state.oldPass,
            newPassword: this.state.newPass
        }            
        this.props.patchUser(data);
        this.props.onClose();
    }

    onOldPassChange = (e) => { 
        this.setState({oldPass: e.target.value});
    }

    onNewPassChange = (e) => { 
        this.setState({newPass: e.target.value});
    }

    onCopyPassChange= (e) => {
        this.setState({copyPass: e.target.value});
    }

    onLangChange = (e) => {
        this.setState({newLanguage: e.target.value});
    }

    handleOpen = () => {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({open: false})
    }

    passChangeSubmit = () => {
        this.handleClose();
        this.setState({passwordChanged: true});
    }

    render(){
        const { t } = this.props;
        const username = this.props.username ? this.props.username : '';
        const mail = this.props.email ? this.props.email : '';
       
        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose}/>
                            </Grid>
                            <Grid item>
                                <SaveButton onClick={this.onSubmit} 
                                    disabled={!this.state.passwordChanged} />
                            </Grid>
                        </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <IconTextField value={mail} label="Email" disabled={true} />
                        <IconTextField value={username} label={t("settings.personal.username")} disabled={true} />
                    </Grid>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={7} />
                        <Grid item xs={5}>
                            <Button variant='contained' color='secondary' fullWidth onClick={this.handleOpen}>{t('settings.personal.changePassword')}</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog fullWidth maxWidth="sm" open={this.state.open} onClose={this.handleClose}>
                    <ChangePassDialog onClose={this.handleClose}
                    oldPassword={this.state.oldPass}
                    onOldPassChange={this.onOldPassChange}
                    newPassword={this.state.newPass} 
                    onNewPassChange={this.onNewPassChange}
                    copyPassword={this.state.copyPass}
                    onCopyPassChange={this.onCopyPassChange}
                    onPassChangeSubmit={this.passChangeSubmit} />
                </Dialog>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.login.username,
        email: state.login.email,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        patchUser(data){
            dispatch(patchUser(data)).then(() => dispatch(fetchUser()))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Personal));

