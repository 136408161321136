const initialState = {
    beacons: [],
    beaconTemplates: [],
    notices: [],
}

export default function Beacons(state = initialState, action) {

    if(action.type === 'FETCH_BEACONS'){
        return {
            ...state,
            beacons: action.payload.data,
            notices: []
        }
    }   
    if (action.type === 'FETCH_BEACONS_FAILURE'){
        return {
            ...state,
            beacons: [],
            notices: [{type: "error", notifyKey: "snackbar.beaconsFetchError"}]
        }
    }

    if (action.type === 'FETCH_BEACON_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconFetchError"}]
        }
    }

    if (action.type === 'BEACONS_CLEAR_NOTICES'){
        return {
            ...state,
            notices: []
        }
    }

    if(action.type === 'FETCH_BEACONS_TEMPLATES'){
        return {
            ...state,
            beaconTemplates: action.payload.data,
        }
    }

    if (action.type === 'FETCH_BEACONS_TEMPLATES_FAILURE'){
        return {
            ...state,
            beaconTemplates: [],
            notices: [{type: "error", notifyKey: "snackbar.beaconsTemFetchError"}]
        }
    }

    if (action.type === 'POST_BEACONS'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.beaconsPostSuccess"}]
        }
    }
    if (action.type === 'POST_BEACONS_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconsPostError"}]
        }
    }

    if (action.type === 'DELETE_BEACONS'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.beaconsDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_BEACONS_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconsDeleteError"}]
        }
    }

    if (action.type === 'PATCH_BEACON'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.beaconsPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_BEACON_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconsPatchError"}]
        }
    }

    // Beacon Templates
    if (action.type === 'POST_BEACON_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "success", notifyKey: "snackbar.beaconTemPostSuccess"}]
        }
    }
    if (action.type === 'POST_BEACON_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconTemPostError"}]
        }
    }
    if (action.type === 'PATCH_BEACON_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.beaconTemPatchSuccess"}]
        }
    }
    if (action.type === 'PATCH_BEACON_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconTemPatchError"}]
        }
    }
    if (action.type === 'DELETE_BEACON_TEMPLATES'){
        return {
            ...state,
            notices: [{type: "info", notifyKey: "snackbar.beaconTemDeleteSuccess"}]
        }
    }
    if (action.type === 'DELETE_BEACON_TEMPLATES_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconTemDeleteError"}]
        }
    }

    if (action.type === 'FETCH_BEACON_TEMPLATE_FAILURE'){
        return {
            ...state,
            notices: [{type: "error", notifyKey: "snackbar.beaconTemFetchError"}]
        }
    }

    return state
}
  