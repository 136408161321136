import React, { Component } from "react";
import { DialogContent, Grid, TextField, Typography } from "@material-ui/core";
import FloorPlanUpload from "./FloorPlanUpload";
import SaveButton from "../Gui/SaveButton";
import CancelButton from "../Gui/CancelButton";
import Icon from '@mdi/react';
import { mdiStairs } from '@mdi/js';
import { gray } from "../../themes/style";
import './Locations.css'
import { withSnackbar } from "notistack";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'
import { withTranslation } from 'react-i18next';

class FloorDialog extends Component{

    state = {
        floorPlan: this.props.floor ? this.props.floor.floorPlan : "",
        floorName: this.props.floor ? this.props.floor.floorName : "",
        floorNr: this.props.floor ? this.props.floor.floorNr : 0,
        floorUrl: this.props.floor ? this.props.floor.floorUrl :"",
        disabled: false
    }

    floorPlanUpdload = (name ,url) => {
        this.setState({floorPlan: name, floorUrl: url})
    };

    onNumberChange = (e) => {
        this.setState({floorNr: e.target.value})
    };

    onNameChange = (e) => {
        this.setState({floorName: e.target.value})
    };

    submitFloor = () => {
        this.setState({disabled: true})
        this.props.submitFloor(
            this.state.floorNr, 
            this.state.floorName,
            this.state.floorPlan,
            this.state.floorUrl
        );
    }

    notify = (message) => {
        this.props.enqueueSnackbar(message,  { 
            variant: 'error',
        });
    }

    render(){
        const { t } = this.props
        let height = 36;
        let leftPadding = 20;
        return(
            <Grid container>
                <Grid item xs={12} className="dialogTitle">
                    <Typography variant="h6" style={{paddingLeft: leftPadding}}>{t("locations.updateFloorTitle")}{this.props.buildingName}</Typography>
                </Grid>
                <Grid item>
                    <DialogContent>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <MuiThemeProvider theme={IstokTheme}>
                                    <Grid container spacing={2}>
                                        <Grid item sx={1}>
                                            <Icon path={mdiStairs}
                                                size={1} color={gray} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField label={t("locations.floorNr")}
                                                    InputProps={{style:{height}}}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    value={this.state.floorNr}
                                                    onChange={this.onNumberChange}
                                                    type="number"
                                                    fullWidth variant="outlined" />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField label={t("locations.name")} 
                                                    InputProps={{style:{height}}} 
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    value={this.state.floorName}
                                                    onChange={this.onNameChange}
                                                    fullWidth variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <FloorPlanUpload setFloorPlan={this.floorPlanUpdload} floorName={this.state.floorPlan} notify={this.notify}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={9} />
                                    <Grid item xs={3}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <CancelButton onClick={this.props.onClose} />
                                            </Grid>
                                            <Grid item >
                                                <SaveButton disabled={this.state.disabled || 
                                                    !(Number.isInteger(parseInt(this.state.floorNr))
                                                    && this.state.floorName.length > 0)
                                                } onClick={this.submitFloor} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} />
                        </Grid>
                    </DialogContent>
                </Grid>
            </Grid>
        );
    };
};

export default withSnackbar(withTranslation()(FloorDialog));