import React, { Component } from 'react';
import scriptLoad from '../../scriptLoader';
import RotatableOverlay from './RotatableOverlay';
import { green } from '../../themes/style';

class ZoneCreationMap extends Component{
    
    constructor(){
        super();
        this.map = null;
        this.overlay = null;
        this.polygon = null;
        this.drawingManager = null;
    }
    
    componentDidMount(){
        if(!(typeof window.google === 'object' && typeof window.google.maps === 'object')){
            scriptLoad("https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_KEY+"&v=3.exp&libraries=geometry,drawing,places").then(res => this.initMap())
        }
        else{
            this.initMap()
        }

    }

    initMap = () => {
        this.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 19,
              defaultCenter: this.props.mapCenter,
              center: this.props.mapCenter
            }
        )
        if(this.map){
            new window.google.maps.event.addListenerOnce(this.map, 'idle', function(){
                if(this.props.onMapMounted)
                    this.props.onMapMounted(); 
            }.bind(this));
        }
    }

    placeDM = () => {
        if(!this.drawingManager){
            this.drawingManager = new window.google.maps.drawing.DrawingManager({
                drawingControl: true,
                drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['polygon']
                },
                polygonOptions: {
                    editable: true,
                    strokeColor: green,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: green,
                    fillOpacity: 0.35
                }
            })

            window.google.maps.event.addListener(this.drawingManager, 'polygoncomplete', function(polygon) {
                this.polygon = polygon
                this.props.setPolygon(this.polygon)
                this.closeDM();
            }.bind(this));

            this.drawingManager.setMap(this.map)
        }
    }

    closeDM = () => {
        if(this.drawingManager){
            this.drawingManager.setMap(null)
            this.drawingManager = null
        }
    }

    placeFloor = () => {
        if(this.props.floor && this.map){
            RotatableOverlay.prototype = new window.google.maps.OverlayView()

            if(this.props.floor.boundsNE && this.props.floor.boundsSW){
                let ne = {lat: this.props.floor.boundsNE.lat, lng: this.props.floor.boundsNE.lng}
                let sw = {lat: this.props.floor.boundsSW.lat, lng: this.props.floor.boundsSW.lng}

                let imageBounds = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(sw.lat, sw.lng),
                    new window.google.maps.LatLng(ne.lat, ne.lng)
                )
                this.overlay = new RotatableOverlay(imageBounds, this.props.floor.planUrl, this.map, this.props.floor.rotation);
            } 
        }
        this.placeDM();
    }

    purge = () => {
        if(this.overlay){
            this.overlay.setMap(null);
            this.overlay = null;
        }
        if(this.polygon && this.props.purgePolygon){
            this.polygon.setMap(null);
            this.polygon = null;
        }
        if(this.drawingManager){
            this.closeDM();
        }
    }

    placeZone = () => {
        if(this.props.points){
            if (this.props.points.length > 3){
                let array = [];
                this.props.points.map(point => 
                    array.push({
                       lat: point.latitude, lng: point.longitude
                    })
                );
                this.polygon = new window.google.maps.Polygon({
                    paths: array,
                    editable: true,
                    strokeColor: green,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: green,
                    fillOpacity: 0.35
                });
                this.polygon.setMap(this.map);
            }
        }       
    };

    componentDidUpdate(){
        if(this.props.refreshMap){
            if(this.map){
                this.map.setCenter(this.props.mapCenter);
                this.purge();
                this.placeFloor();
                this.placeZone();
            }
        }
    }

    render(){
        return(
            <div>
                <div id="googleMap" style={{width: "100%", height: '750px'}}/>
            </div>
        )
    }
}

export default ZoneCreationMap


