import React, { useEffect, useState } from "react"
import TrackingHistoryMap from '../Map/TrackingHistoryMap.js'
import { useSelector, useDispatch } from 'react-redux';
import { fetchBeacons } from '../../actions/apiCalls';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TextField } from "@material-ui/core";
import DatePicker from '../Gui/DatePicker.js'
import Grid from "@material-ui/core/Grid";
import axios from 'axios'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(1),
      },
    }));
    
const TrackingHistory = () => {


      const [startTime, setStart] = useState(null);
      const [endTime, setEnd] = useState(null);
      const { enqueueSnackbar } = useSnackbar();
      const [availableBeacon , setAvailableBeacon] = useState(false);
      const [values, setValues] = useState({
            project: '',
            beacon: '',
            filter: '',
            period: '',
            number: 1,
      })

      const classes = useStyles();
      const currentProjectId = useSelector(state => state.projects.currentProjectId)
      const beaconList = useSelector(state => state.beacons.beacons) 
      const dispatch = useDispatch()
      

      let filterBy = {
            
            "Time": "",
            "Last N Records": ""
      };

      let filterTime = {

            "Last 1 Hour": '1h',
            "Last 1 Day": "1d",
            "Last 5 Days": "5d",
            "Custom": "Custom"
      }


      useEffect(() => {

            dispatch(fetchBeacons(currentProjectId))

      }, [dispatch, currentProjectId]);

      const handleChange = (e) => {

            setValues({
                  ...values,
                  [e.target.name]: e.target.value
            });
            
            if (e.target.name === "beacon")
                  {
                        fetchTime(e.target.value);
                  }
      };

      const handleChangeStartTime = (event) => {
            setStart(event.target.value);
      };

      const handleChangeEndTime = (event) => {

            setEnd(event.target.value);
      };


      const fetchTime = (beaconId) => {
            let url = process.env.REACT_APP_POSITION_PERSISTENCE_URL;
            axios.get(url + 'time_period/single/' + beaconId)
            .then((response) => {
                  let time = response.data;
                  setEnd(time.data.attributes.newest)
                  setStart(time.data.attributes.oldest)
                  setAvailableBeacon(true);
                  
            })
                  
            .catch(function (error) {
                  // handle error
                  setAvailableBeacon(false)
                  notify("No data for chosen beacon")
                  return
                  });
      };

      const queryData = () => {

            if(values.filter === "Time" && values.period === "Custom"){
                  if(isDate){
                        return {
                              data: {
                                    id: values.beacon,
                                    attributes : {
                                          startTime: startTime,
                                          endTime: endTime,
                                          lastTime: null,
                                          n_values: null
                                    }
                              }
                        }
                  }
            }

            else if(values.filter === "Time"){
                  return {
                        data: {
                              id: values.beacon,
                              attributes : {
                                    startTime: null,
                                    endTime: null,
                                    lastTime: values.period,
                                    n_values: null
                              }
                        }
                  }
            }

            else if(values.filter === "Last N Records"){
                  if(isNumber)
                  {
                        return {
                              data: {
                                    id: values.beacon,
                                    attributes : {
                                          startTime: null,
                                          endTime: null,
                                          lastTime: null,
                                          n_values: values.number
                                    }
                              }
                        }
                  }
            }
      };

      const validateNumber = () => {

            if(values.number >= 1)

                  return true;
            

            return false;

      };

      const validateDate = () => {

            if(startTime && endTime){

                  let start = Date.parse(startTime);
                  let end = Date.parse(endTime)
                  if(end - start >= 0)
                        return true;
                  
            }
            return false;
      };

      const notify = (message) => {
            enqueueSnackbar(message,  { 
                variant: 'error',
            });
        }

      let isNumber = validateNumber();
      let isDate = validateDate();

      return(
          <div>
                  <Grid container>
                        <Grid item xs={12}>
                              <Grid container spacing={1}>
                                          <Grid item>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                      <InputLabel id="demo-simple-select-outlined-label">Beacon</InputLabel>
                                                      <Select
                                                            name = "beacon"
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-olangust australiautlined"
                                                            value={values.beacon}
                                                            onChange={handleChange}
                                                            label="Beacon"
                                                      >
                                                      {
                                                            beaconList.map((k,v) => 
                                                            <MenuItem key={v} value={k.id}>{k.attributes.name}</MenuItem>)
                                                      }
                                                            
                                                      </Select>
                                                </FormControl>
                                          </Grid>

                                    {values.beacon && availableBeacon? 
                                          <Grid item>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                      <InputLabel id="demo-simple-select-outlined-label">Filter by</InputLabel>
                                                      <Select
                                                            name = "filter"
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={values.filter}
                                                            onChange={handleChange}
                                                            label="Filter by"
                                                      >
                                                      {
                                                            Object.entries(filterBy).map((k,v) =>
                                                            <MenuItem key={v} value={k[0]}>{k[0]}</MenuItem>)
                                                      }
                                                      </Select>
                                                </FormControl>
                                          </Grid>: <Grid />}

                                    {values.filter === "Time" && availableBeacon ? 
                                          <Grid item>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                      <InputLabel id="demo-simple-select-outlined-label">Period</InputLabel>
                                                      <Select
                                                            name = "period"
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={values.period}
                                                            onChange={handleChange}
                                                            label="Period"
                                                      >
                                                      {
                                                            Object.entries(filterTime).map((k,v) =>
                                                            <MenuItem key={v} value={k[1]}>{k[0]}</MenuItem>)
                                                      }
                                                      </Select>
                                                </FormControl>
                                          </Grid>: <Grid />}

                                    {values.filter === "Time" && values.period === "Custom" && availableBeacon? 
                                          <Grid item>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                      <DatePicker isDate = {isDate} handleChangeEndTime = {handleChangeEndTime} handleChangeStartTime = {handleChangeStartTime} startTime = {startTime} endTime = {endTime}/>
                                                </FormControl>
                                          </Grid>: <Grid />}

                                    {values.filter === "Last N Records" && availableBeacon ?
                                          <Grid item xs = {3}>
                                                <FormControl variant="outlined" className={classes.formControl}>
                                                      <TextField
                                                            name = "number"
                                                            error = {isNumber ? false : true }
                                                            id="device-number"
                                                            label="Number"
                                                            type="number"
                                                            variant="outlined"
                                                            defaultValue = {values.number}
                                                            onChange = {handleChange}
                                                            helperText = {isNumber ? "": "Minimum value must be at least 1" }
                                                      />
                                                </FormControl>
                                          </Grid>: <Grid />}

                        </Grid>
                        <TrackingHistoryMap queryData = {queryData}/>  
                  </Grid>
            </Grid>
      </div>
      )
};
export default TrackingHistory