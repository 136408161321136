import { combineReducers } from 'redux'
import {reducer as form } from 'redux-form'
import LoginReducer from './LoginReducer'
import LocationsReducer from './LocationsReducer'
import ProjectsReducer from './ProjectsReducer'
import GatewayReducer from './GatewaysReducer'
import BeaconReducer from './BeaconsReducer'
import ReferenceNodesReducer from './RnReducer'
import ZonesReducer from './ZonesReducer'
import PoliciesReducer from './PoliciesReducer'
import DataFetchReducer from './DataFetch'

export default combineReducers({
    beacons: BeaconReducer,
    gateways: GatewayReducer,
    locations: LocationsReducer,
    login: LoginReducer,
    projects: ProjectsReducer,
    referenceNodes: ReferenceNodesReducer,
    zones: ZonesReducer,
    policies: PoliciesReducer,
    devices: DataFetchReducer,
    form: form
})