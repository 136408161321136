import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import SubjectIcon from "@material-ui/icons/Subject";
import IconTextField from "../Gui/IconTextField";
import GpsNotFixed from "@material-ui/icons/GpsNotFixed"
import BuildingFloors from "./BuildingFloors";
import uuidv4 from "uuid/v4"
import NewBuildingForm from "./NewBuildingForm";
import LocationMap from "../Map/LocationMap";
import SaveButton from "../Gui/SaveButton";
import { connect } from "react-redux";
import { fetchLocations, postLocation } from '../../actions/apiCalls';
import CancelButton from "../Gui/CancelButton";
import { withTranslation } from 'react-i18next';

class CreateLocation extends Component{

    constructor() {
        super();
        this.state = {
            lName: "",
            lDescription: "",
            lAddress: "",
            lBuildings: [],
            newBuildingOpen: false,
            mapCenter: { lat: 54.370765, lng: 18.613733},
            selectedFloor: null,
            selectedBuildingId : "",
            purge: true,
            centerChanged: true
        };
        this._isMounted = true;
    };

    resetPurge = () => {
        this.setState({purge: false})
    }

    changeLocationName = (e) => {
        this.setState({lName: e.target.value, centerChanged: false})
    }

    changeLocationDescription = (e) => {
        this.setState({lDescription: e.target.value, centerChanged: false})
    }

    changeLocationAddress = (e) => {
        this.setState({lAddress: e.target.value, centerChanged: false})
    }

    findGeoAdress = () => {
        let address = this.state.lAddress
        let url = "https://maps.googleapis.com/maps/api/geocode/json?address="+address.replace(/\s+/g, '+')+"&key="+process.env.REACT_APP_GOOGLE_KEY
        let xmlHttp = new XMLHttpRequest()

        xmlHttp.onreadystatechange = function() { 
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200){
                let res = JSON.parse(xmlHttp.responseText)
                if(res.results.length > 0){
                    let lat = res.results[0].geometry.location.lat
                    let lng = res.results[0].geometry.location.lng
                    this.setState({mapCenter: { lat: lat, lng: lng}, centerChanged: true})
                }
            }
        }.bind(this)

        xmlHttp.open("GET", url, true)
        xmlHttp.send(null)
    }

    addNewBuilding = (name) => {
        this.setState({lBuildings: this.state.lBuildings.concat({"id": uuidv4(), "name": name, floors: []}), centerChanged: false});
    };

    openBuildingForm = () => {
        this.setState({newBuildingOpen: true, centerChanged: false})
    };

    closeBuildingForm = () => {
        this.setState({newBuildingOpen: false, centerChanged: false})
    };

    addFloorToBuilding = (bId, floor) => {
        let i = this.state.lBuildings.findIndex(b=>b.id === bId)
        if (i >= 0){
            this.state.lBuildings[i].floors.push(floor)
            this.forceUpdate();
        }
    };

    selectFloor = (bId, f) => {
        this.setState({selectedFloor: f, selectedBuildingId: bId, purge: true, centerChanged: false})
    };

    selectDefaultFloor = (floorId) => {   
        let buildings = [...this.state.lBuildings]
        buildings.forEach(b => {
            b.floors.forEach(f => {
                if(f.id === floorId && f.defaultFloor === true){
                    f.defaultFloor = false
                    return;
                }
                f.id === floorId ? f.defaultFloor = true : f.defaultFloor = false
            })
        });
        this.setState({
            lBuildings: buildings
        });
    }

    changeRotation = (rot) => {
        if(this.state.selectedFloor && this.state.selectedBuildingId){
            let i = this.state.lBuildings.findIndex(b=>b.id === this.state.selectedBuildingId)
            let j = this.state.lBuildings[i].floors.findIndex(f=> f.id === this.state.selectedFloor.id)
            let newBuildings = this.state.lBuildings;
            newBuildings[i].floors[j].rotation = rot;
            if (this._isMounted){
                this.setState({lBuildings: newBuildings});
                this.selectFloor(this.state.selectedBuildingId, this.state.lBuildings[i].floors[j]);
            }
        }
    }
 
    changeBounds = (neLat, neLng, swLat, swLng) => {
        if(this.state.selectedFloor && this.state.selectedBuildingId){
            let i = this.state.lBuildings.findIndex(b=>b.id === this.state.selectedBuildingId)
            let j = this.state.lBuildings[i].floors.findIndex(f=> f.id === this.state.selectedFloor.id)
            let newBuildings = this.state.lBuildings;
            newBuildings[i].floors[j].boundsSW = {'lat': swLat, 'lng': swLng}
            newBuildings[i].floors[j].boundsNE = {'lat': neLat, 'lng': neLng}
            if (this._isMounted){
                this.setState({lBuildings: newBuildings});
                this.selectFloor(this.state.selectedBuildingId, this.state.lBuildings[i].floors[j]);
            }
        }
    }

    submitLocation = () => {
        let data = {
            "name": this.state.lName,
            "address": this.state.lAddress,
            "description": this.state.lDescription,
            "position": { "lat": this.state.mapCenter.lat, "lng": this.state.mapCenter.lng },
            "buildings": this.state.lBuildings
        }
        if(this.props.projectId){
            this.props.postLocation(this.props.projectId, data)
            this.props.onClose()

        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }
    
    render(){
        const { t } = this.props;
        return(
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                            <Grid item>
                                <CancelButton onClick={this.props.onClose} />
                            </Grid>
                            <Grid item>
                                <SaveButton disabled={!(this.state.lBuildings.length > 0 
                                    && this.state.lName !== ""
                                    && this.state.lDescription !== "")} onClick={this.submitLocation} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <IconTextField onChange={this.changeLocationName} label={t("locations.name")} icon={<PlaceIcon/>}/>
                            <IconTextField onChange={this.changeLocationAddress} label={t("locations.address")} onEnd={this.findGeoAdress} icon={<GpsNotFixed/>}/>
                            <IconTextField onChange={this.changeLocationDescription} label={t("locations.description")} icon={<SubjectIcon/>}/>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={1}/>
                                    <Grid item xs={11}>
                                        <Button variant="contained" color="secondary" onClick={this.openBuildingForm}>{t("locations.newBuildingButton")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {this.state.newBuildingOpen ? (
                                <NewBuildingForm submit={this.addNewBuilding} closeForm={this.closeBuildingForm} />
                            )
                            : (
                               <div/> 
                            )}
                            {this.state.lBuildings.map(building =>
                                <BuildingFloors key={building.id} 
                                    building={building} 
                                    addFloorToBuilding={this.addFloorToBuilding} 
                                    selectFloor={this.selectFloor}
                                    selectedFloor={this.state.selectedFloor}
                                    selectDefaultFloor={this.selectDefaultFloor} />
                            )}
                        </Grid> 
                    </Grid>
                    <Grid item xs={8}>
                        <Grid item xs={12}>
                            <LocationMap mapCenter={this.state.mapCenter} 
                                floor={this.state.selectedFloor} 
                                purge={this.state.purge} 
                                resetPurge={this.resetPurge} 
                                changeRotation={this.changeRotation}
                                changeBounds={this.changeBounds}
                                centerChanged={this.state.centerChanged} />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        postLocation(projectId, data){
            dispatch(postLocation(projectId, data)).then(() => dispatch(fetchLocations(projectId)));
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateLocation));