import axios from "axios"

const initialState = {
    userLoggedIn: false,
    token: null,
    username: null,
    isSuperAdmin: false,
    email: null,
    language: null,
    errors: []
}

export default function Login(state = initialState, action) {
    if(action.type === 'LOGIN_USER'){
        // Authorization.setToken(action.payload.data.data.token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload.data.data.token;
        sessionStorage.setItem('token', action.payload.data.data.token);
        return{
            ...state,
            errors: [],
            userLoggedIn: true,
            username: action.payload.data.data.username,
            email: action.payload.data.data.email,
            language: action.payload.data.data.language,
            token: action.payload.data.data.token,
            isSuperAdmin: action.payload.data.data.isSuperAdmin
        }
    }
    if(action.type === 'ERROR_LOGIN_USER'){
        let errors = []
        if(action.payload){
            errors.push({type: "error", notifyKey: action.payload.data})
            return{
                ...state,
                errors: errors
            }
        }
        else{
            errors.push({type: "error", notifyKey: "snackbar.loginConnection"})
            return{
                ...state,
                errors: errors
            }
        }
    }
    if(action.type === "LOGOUT_USER"){
        sessionStorage.clear();
        return {
            ...state,
            username: null,
            userLoggedIn: false,
            token: null,
            isSuperAdmin: false
        }
    }
    if(action.type === "FETCH_USER"){
        return {
            ...state,
            username: action.payload.data.username,
            email: action.payload.data.email,
            language: action.payload.data.language
        }
    }
    if(action.type === "FETCH_USER_FAILURE"){
        return {
            ...state
        }
    }
    if(action.type === "LOGINS_CLEAR_ERRORS"){
        return{
            ...state,
            errors: []
        }
    }
    if(action.type === "PATCH_USER"){
        return {
            ...state,
            errors: [{type: "info", notifyKey: "snackbar.updateUserPassword"}]
        }
    }
    if(action.type === "PATCH_USER_FAILURE"){
        return {
            ...state,
            errors: [{type: "error", notifyKey: "snackbar.updateUserPasswordError"}]
        }
    }

    return state
}
  