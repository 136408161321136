import React, { Component } from "react";
import {Popper, Paper, MenuList, MenuItem, TextField } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withTranslation } from 'react-i18next';

class FilterTextFiled extends Component{
    
    state = {
        open:false,
        anchorEl: null
    };

    handleChange = (e) => {
        
        this.props.onNameChange(e.target.value);
        if(this.props.beaconList.length === 1){
            this.setState({
                anchorEl:  e.currentTarget,   
            })
            this.props.onNameChange(e.target.value);
        }
        else{
            
            this.props.onChooseBeacon(e.target.value)
            this.props.onNameChange(e.target.value);
            this.setState({
                anchorEl:  e.currentTarget,   
            })
        }
    };

    handleClose = (e, id, name) =>{
        this.setState({
            anchorEl: this.state.anchorEl ? null : e.currentTarget,
        })
        this.props.onChooseBeacon(name);
        this.props.onBeaconSelect(id);
    };

    handleClickAway = (e) =>{
        this.setState({
            anchorEl: this.state.anchorEl ? null : e.currentTarget,
        })
    };

    render(){
        const open = Boolean(this.state.anchorEl);  
        const id = open ? 'simple-popper' : null;
        const { t } = this.props
        
        return(
            <div>
                <TextField value={this.props.value} variant="outlined" id={id} label ={t("tracking.beacon")} onChange={this.handleChange}/>
                <Popper open={open} placement = 'bottom' anchorEl={this.state.anchorEl} >
                    <ClickAwayListener  onClickAway={this.handleClickAway}>
                        <Paper>
                        <MenuList>
                        { 
                            this.props.beaconList.map((d, k) =>
                            <MenuItem key={d.id} onClick={ e => {this.handleClose(e, d.id, d.attributes.name);}}>{d.attributes.name}</MenuItem>
                            )
                        }                   
                        </MenuList>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </div>
        )
    }
}export default withTranslation()(FilterTextFiled);