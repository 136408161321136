import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";

export const Loader = (props) => {
    return(
        <div>
            <Grid container spacing={3} justify="center">
                <Grid item>
                    <CircularProgress color="secondary"/>
                </Grid>
            </Grid>
        </div>
    )
}