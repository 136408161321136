function loadScript(src) {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.src = src
        script.addEventListener('load', function() {
            resolve()
        })
        script.addEventListener('error', function(e) {
            console.log('error')
            reject(e)
        })
        document.body.appendChild(script);
    })
}

export default loadScript