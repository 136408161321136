import { useTranslation } from "react-i18next";
import React from "react";
import DataTable from '../Gui/DataTable';
import AddFab from "../Gui/AddFab";
import { Grid, Typography } from "@material-ui/core";
const ReferenceNodesPanel = (props) => {

    const { t } = useTranslation();
    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">{t('referenceNodes.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable data={props.data} name={t('refNode', {count: 5})}
                        onItemDelete={props.onItemDelete}
                        onItemClick={props.onItemClick}
                        attributes={props.attributes}
                        headers={props.headers}
                        deleteWarningMessage={t("common.removeTemplateWarning")}    
                    />
                </Grid>
            </Grid>
            <AddFab onClick={props.addNewReferenceNode}/>
        </div>
    )
};
export default ReferenceNodesPanel