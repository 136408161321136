import React, { Component } from "react";
import { Typography, IconButton, Table, TableHead, TableCell, TableBody, TableRow, TablePagination, Dialog, Checkbox } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from "./DeleteDialog";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'
import { connect } from "react-redux";
import { isCorrectRole } from "../../utils"
import { withTranslation } from 'react-i18next';

class DataTable extends Component {

    constructor(){
        super()
        this.state = {
            page: 0,
            rowsPerPage: 10,
            open: false,
            openDialog: false,
            currentVal: null
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (e) => {
        this.setState({ rowsPerPage: e.target.value });
    }

    openDialog = (e, val) => {
        e.stopPropagation();
        this.setState({openDialog: true, currentVal: val});
    }

    onDialogClose = () => {
        this.setState({openDialog: false, currentVal: null});
    }

    submitDelete = (event) => {
        if (this.state.currentVal){
            if(this.state.currentVal.type)
                this.props.onItemDelete(event, this.state.currentVal.id, this.state.currentVal.attributes.type)
            else
                this.props.onItemDelete(event, this.state.currentVal.id)
        }
        this.onDialogClose();
    }

    sendEmail = (e, val) => {
        e.stopPropagation();
        this.props.sendMessage(e, val)
    };

    colorBasedOnTypeAndStatus = (key, val) => {
        if (key === "status"){
            if (val === "online")
                return "primary"
            if (val === "offline")
                return "secondary"
        }
        return "initial"
    }

    render(){
        const { rowsPerPage, page } = this.state;
        const isAllowed = isCorrectRole(this.props.role);
        // const attr = this.props.attributes;
        const detectedLng = this.props.i18n.language;
        const contentInfo = detectedLng === 'en' ? (<Typography>No {this.props.name} available</Typography>) 
        : (<Typography>Brak dostępnych {this.props.name} </Typography>)
        const content = this.props.data.length === 0 ? (contentInfo):
            (
                <div>
                    <MuiThemeProvider theme={IstokTheme}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {this.props.headers.map(header => 
                                        <TableCell key={header} align={'left'} style={{minWidth: 170}}>
                                            <Typography variant="body2"> <b>{header}</b></Typography>
                                        </TableCell>
                                    )}
                                    <TableCell/>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(val => {
                                    return(
                                        <TableRow key={val.id} hover onClick={event => val.attributes.type ? 
                                        this.props.onItemClick(event, val.id, val.attributes.type): 
                                        this.props.onItemClick(event, val.id)}
                                        style={{
                                            pointerEvents: isAllowed ? "auto": "none"
                                        }}>
                                            {
                                                this.props.attributes.map((key, i) => (
                                                    Object.keys(val.attributes).includes(key) ? (
                                                        <TableCell so key={i}>
                                                            <Typography variant="body2" color={this.colorBasedOnTypeAndStatus(key, val.attributes[key])}>{val.attributes[key]}</Typography>
                                                        </TableCell>
                                                    )
                                                    :(null)
                                                ))
                                            }

                                            {
                                                val.type === "gateway" ?
                                                    <TableCell>
                                                        <Checkbox 
                                                            onClick = {event => this.sendEmail(event, val)}
                                                            checked={val.attributes.send_status}
                                                        />
                                                    </TableCell>
                                                : null
                                            }
                                            <TableCell align="right">
                                                {/* <IconButton aria-label="Delete" onClick={event => val.attributes.type ?
                                                this.props.onItemDelete(event, val.id, val.attributes.type):
                                                this.props.onItemDelete(event, val.id)
                                                }> */}
                                                {isAllowed ? 
                                                <IconButton size="small" aria-label="Delete" onClick={event => this.openDialog(event, val)}>
                                                    <DeleteIcon color="secondary" />
                                                </IconButton>
                                                : <div />
                                                }
                                            </TableCell>
                                        </TableRow>
                                )})
                                }
                            </TableBody>
                        </Table>
                    </MuiThemeProvider>
                    <TablePagination count={this.props.data.length}
                        component="div"
                        rowsPerPage={this.state.rowsPerPage} page={this.state.page}
                        labelRowsPerPage = 'Rows per page:'
                        rowsPerPageOptions = {[5, 10]}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                    <Dialog fullWidth maxWidth="sm" open={this.state.openDialog} onClose={this.onDialogClose}>
                        <DeleteDialog onDialogClose={this.onDialogClose} submitDelete={this.submitDelete} deleteWarningMessage={this.props.deleteWarningMessage} />
                    </Dialog>
                </div>);
        return(
            <div>
                {content}
            </div>
        );
    };
};

const mapStateToProps = (state) => {
    return {
        role: state.projects.currentRole
    }
};

export default connect(mapStateToProps)(withTranslation()(DataTable));