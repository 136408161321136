import React, { Component } from "react";
import { Select, InputLabel, MenuItem, FormControl, Grid, IconButton } from "@material-ui/core";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'

class IconSelector extends Component{
    render(){
        return(
            <Grid item xs={12}>
                <MuiThemeProvider theme={IstokTheme}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}>
                            <IconButton style={{pointerEvents: 'none'}}>
                                {this.props.icon}
                            </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-simple">
                                    {this.props.label}
                                </InputLabel>
                                <Select disabled={this.props.disabled} value={this.props.value} onChange={this.props.onChange} label={this.props.label}>
                                    {
                                        !this.props.floors ? (this.props.data.map(item => 
                                            <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>
                                        )):(
                                            (
                                                this.props.data.map(l =>
                                                    l.buildings.map(b=>
                                                        b.floors.map(f =>
                                                            <MenuItem key={f.id} value={f.id}>{l.attributes.name}, {b.name}, {f.nr}.{f.name}</MenuItem>
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Grid>
        );
    };
};

export default IconSelector;