import React from "react";
import DataTable from '../Gui/DataTable';
import AddFab from "../Gui/AddFab";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import UploadMacFilters from "./../Gui/UploadMacFilters.js"

const GatewayPanel = (props) => {

    const { t } = useTranslation();
    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4">{t("gateways.title")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DataTable data={props.data} name={t("gateway", {count: 5})}
                        onItemDelete={props.onItemDelete}
                        onItemClick={props.onItemClick}
                        attributes={props.attributes}
                        headers={props.headers}
                        sendMessage={props.sendMessage}
                        />
                </Grid>
            </Grid>
            <UploadMacFilters onClick={props.uploadMac}/>
            <AddFab onClick={props.addNewGateway}/>
        </div>
    )
};

export default GatewayPanel;