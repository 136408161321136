import { createMuiTheme } from '@material-ui/core/styles';


export const green = "#4ac42d";
export const gray = "#7a7a7a";

export const Theme = createMuiTheme({
    palette:{
        primary: {
            main: green,
            light: '#90f89e',
            contrastText: '#fff'
        },
        secondary: {
            light: '#ff3a2c',
            main: '#ff6450',
            dark: '#f85b51',
            contrastText: '#fff'
        },
        selected:{
            main: '#e7fef8'
        }
    },
    typography: {
        useNextVariants: true,
        button: {
            textTransform: 'none'
        },
        fontFamily: [
            'Montserrat-Light',
            'Montserrat-Regular',
            'Audiowide-Regular'
          ].join(','),
    },
    // shadows: Array(25).fill('none')   
});

export const IstokTheme = createMuiTheme({
    palette:{
        primary: {
            main: green,
            light: '#90f89e',
            contrastText: '#fff'
        },
        secondary: {
            light: '#ff3a2c',
            main: '#ff6450',
            dark: '#f85b51',
            contrastText: '#fff'
        },
        selected:{
            main: '#e7fef8'
        }
    },
    typography: {
        useNextVariants: true,
        button: {
            textTransform: 'none'
        },
        fontFamily: [
            'IstokWeb-Regular',
            // 'Audiowide-Regular'
          ].join(','),
    },
    // shadows: Array(25).fill('none')   
});

export const MontserratBoldTheme = createMuiTheme({
    palette:{
        primary: {
            main: green,
            light: '#90f89e',
            contrastText: '#fff'
        },
        secondary: {
            light: '#ff3a2c',
            main: '#ff6450',
            dark: '#f85b51',
            contrastText: '#fff'
        },
        selected:{
            main: '#e7fef8'
        }
    },
    typography: {
        useNextVariants: true,
        button: {
            textTransform: 'none'
        },
        fontFamily: [
            'Montserrat-Bold',
            // 'Audiowide-Regular'
          ].join(','),
    },
    // shadows: Array(25).fill('none')   
});

