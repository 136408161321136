import React, {Component} from 'react'
import { connect } from 'react-redux';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TablePagination, TableBody, IconButton, Dialog, Tabs, Tab, MuiThemeProvider, Fab } from "@material-ui/core";
import { fetchProjects, deleteProject } from '../../actions/apiCalls';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import "./Settings.css"
import { withSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import { fab } from "../../utils";
import CreateProject from './CreateProject';
import EditProject from './EditProject';
import DeleteDialog from '../Gui/DeleteDialog';
import { IstokTheme } from '../../themes/style';
import Personal from './Personal';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from "react-router-dom";
class Settings extends Component{

    constructor(){
        super();
        this.state = {
            projectId: "",
            rowsPerPage: 5,
            page: 0,
            currentProjectId: "",
            roleInCurrentProject: "",
            openDeleteDialog: false,
            projectToDeleteId: "",
            tabValue: 1
        };
    };

    openDeleteDialog = () =>{
        this.setState({openDeleteDialog: true})
    }

    closeDeleteDialog = () =>{
        this.setState({openDeleteDialog: false, projectToDeleteId: ""})
    }

    componentDidMount = () => {
        this.props.fetchProjects();
    };

    onProjectChange = (e, projectId, role) => {
        this.props.selectCurrentProject(projectId, role)
    };

    onProjectDelete = (e, projectId) => {
        e.stopPropagation();
        this.setState({projectToDeleteId: projectId}, this.openDeleteDialog());
    };

    submitDeleteProject = () => {
        this.props.deleteProject(this.state.projectToDeleteId);
        if(this.state.projectToDeleteId === this.props.projectId){
            this.props.selectCurrentProject(null, "");
        }
        this.closeDeleteDialog();
    };

    onProjectEdit = (e, projectId, role) => {
        e.stopPropagation();
        this.setState({currentProjectId: projectId, roleInCurrentProject: role})
        this.props.history.push(this.props.match.url + '/edit')
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    onTabChange = (e, newValue) => {
        this.setState({tabValue: newValue})
    }
    
    // pass it form the parent Component to Children 
    closeForm = () => {
        this.setState({roleInCurrentProject: ""});
        this.props.history.push('/settings')
    };

    handleChangeRowsPerPage = (e) => {
        this.setState({ rowsPerPage: e.target.value });
    };

    addNewProject = () => {
        this.props.history.push(this.props.match.url + '/create')
    };
    
    render(){
        const { t } = this.props;
        const data = this.props.projects
        const { rowsPerPage, page } = this.state;

        return(
            <div>
                <Switch>
                    <Route exact path={'/settings'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{t("sidebar.Settings")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Tabs value={this.state.tabValue} style={{borderBottom: '1px solid #e8e8e8'}} textColor="secondary" onChange={this.onTabChange}>
                                <Tab label={t('settings.personal.personal')}/>
                                <Tab label={t('settings.projects.projects')}/>
                                </Tabs>
                            </Grid>
                            {this.state.tabValue === 0 ? <Personal onClose={e => this.setState({tabValue: 1})} /> : <div/>}
                            {this.state.tabValue === 1 ? (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <MuiThemeProvider theme={IstokTheme}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="body2"><b>{t("settings.projects.projectName")}</b></Typography> 
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2"><b>{t("settings.role.role")}</b></Typography> 
                                                    </TableCell>
                                                    {this.props.isAllowed ? 
                                                        <TableCell>
                                                            <Typography variant="body2"><b>{t("settings.firmwareVersion.firmwareVersion")}</b></Typography> 
                                                        </TableCell> 
                                                        : <TableCell />}
                                                        <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(project =>
                                                    <TableRow hover className={this.props.projectId === project.id ? ("selectedProject"): ("")} key={project.id}
                                                    onClick={e => this.onProjectChange(e, project.id, project.attributes.role)}>
                                                        <TableCell>
                                                            <Typography variant="body2">{project.attributes.name}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body2">{project.attributes.role}</Typography>
                                                        </TableCell>
                                                        {this.props.isAllowed && project.attributes.firmwareVersion ? (
                                                            <TableCell>
                                                                <Typography variant="body2">{project.attributes.firmwareVersion}</Typography>
                                                            </TableCell>
                                                        ): <TableCell/>}
                                                        <TableCell align="right">
                                                            <Grid container spacing={2} direction="row" justify="flex-end">
                                                                {project.attributes.role === "superadmin" ? (
                                                                    <Grid item>
                                                                        <IconButton size="small" aria-label="Edit" onClick={e => this.onProjectEdit(e, project.id, project.attributes.role)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                ): null}
                                                                <Grid item>
                                                                    <IconButton size="small" aria-label="Delete" onClick={e => this.onProjectDelete(e, project.id)}>
                                                                        <DeleteIcon color="secondary" />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>    
                                        )}
                                            </TableBody>
                                        </Table>
                                    </MuiThemeProvider>
                                    <TablePagination 
                                        count={ data.length}
                                        component="div"
                                        rowsPerPage={this.state.rowsPerPage} 
                                        page={this.state.page}
                                        labelRowsPerPage = 'Rows per page:'
                                        rowsPerPageOptions = {[5, 10]}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </Grid>
                                <Dialog fullWidth maxWidth="sm" onClose={this.closeDeleteDialog} open={this.state.openDeleteDialog}>
                                    <DeleteDialog onDialogClose={this.closeDeleteDialog} submitDelete={this.submitDeleteProject} 
                                        deleteWarningMessage={this.props.isAllowed ? "common.deleteProjectPrompt": "common.disconnectProjectPrompt"}/>
                                </Dialog>
                            
                                {this.props.isAllowed ? (
                                    <Fab style={fab} onClick={this.addNewProject}>
                                        <AddIcon />
                                    </Fab>): (<div/>)
                                }
                            </Grid>
                            ) : <div/>}
                        </Grid>
                    </Route>
                    <Route path={this.props.match.url + '/create'}>
                        <CreateProject onClose={this.closeForm}/>    
                    </Route>
                    <Route path={this.props.match.url + '/edit'}>
                        <EditProject onClose={this.closeForm} currentProjectId={this.state.currentProjectId} role={this.state.roleInCurrentProject}/>
                    </Route>
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        projects: state.projects.userProjects,
        role: state.projects.currentRole,
        isAllowed: state.login.isSuperAdmin
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjects() {
            dispatch(fetchProjects())
        },
        deleteProject(projectId){
            dispatch(deleteProject(projectId)).then(() => dispatch(fetchProjects()))
        },
        selectCurrentProject(projectId, role) {
            dispatch({type: 'SELECT_PROJECT', payload: {projectId: projectId, role: role }})
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withSnackbar(Settings)));