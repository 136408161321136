import React, { Component } from "react";
import { connect } from 'react-redux';
import { fetchZones, deleteZone } from '../../actions/apiCalls';
import EditZone from './EditZone';
import CreateZone from './CreateZone';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from "react-router-dom";
import ZonesPanel from './ZonesPanel.js'

class Zones extends Component{
    constructor(){
        super()
        this.state = {
            currentZoneId: ""
        }
    }

    componentDidMount(){
        if (this.props.projectId === null)
            this.props.history.push('/');
        if (this.props.projectId !== null)
            this.props.fetchProjectZones(this.props.projectId);
    };
    closeForm = () => {
        this.props.history.push('/zones')
    };

    openCreate = () => {
        this.props.history.push(this.props.match.url + '/create')
    };

    openEdit = (e, zoneId) => {
        this.setState({ currentZoneId: zoneId});
        this.props.history.push(this.props.match.url + '/edit')
    };

    deleteZone = (e, zoneId) => {
        e.stopPropagation();

        if(this.props.projectId)
            this.props.deleteZone(this.props.projectId, zoneId);
    }

    render(){
        const { t } = this.props
        const attributes = ["name"]
        const headers = [t("zones.name")]
        return(
            <div>
                <Switch>
                    <Route exact path={"/zones"}>
                        <ZonesPanel data={this.props.zones} name={t("zone", {count: 5})}
                            onItemDelete={this.deleteZone}
                            onItemClick={this.openEdit}
                            openCreate={this.openCreate}
                            attributes={attributes}
                            headers={headers}
                        />
                    </Route>
                    <Route path={this.props.match.url + '/create'}>
                        <CreateZone onClose={this.closeForm}/>
                    </Route>
                    <Route path={this.props.match.url + '/edit'}>
                        <EditZone zoneId={this.state.currentZoneId} onClose={this.closeForm} /> 
                    </Route>
                </Switch>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        zones: state.zones.zones,
        errors: state.zones.errors
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectZones(projectId) {
            dispatch(fetchZones(projectId))
        },
        deleteZone(projectId, zoneId){
            dispatch(deleteZone(projectId, zoneId)).then(
                () =>  dispatch(fetchZones(projectId)
            ));
        },
        clearZonesErros(){
            dispatch({type: 'ZONES_CLEAR_ERRORS'})
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Zones));