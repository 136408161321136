import React from "react";
import { Button, Typography, Grid, DialogContent } from "@material-ui/core";
import CancelButton from "../Gui/CancelButton";
import { useTranslation } from 'react-i18next';

export const RefreshDialog = (props) => {
    let leftPadding = 20;
    const { t } = useTranslation();
    return(
        <Grid container>
            <Grid item xs={12} className="dialogTitle">
                <Typography variant="h6" style={{paddingLeft: leftPadding}}>{t('refresh.header')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('refresh.flavorText')}</Typography>
                        </Grid>
                        <Grid item xs={7}/>
                        <Grid item xs={2}>
                            <CancelButton onClick={props.onClose}></CancelButton>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" onClick={e => document.location.reload()}>{t('refresh.token')}</Button>
                        </Grid> 
                        <Grid item xs={12} />
                    </Grid>
                </DialogContent>
            </Grid>
        </Grid>
    )
}