import Map from './Map';
import RotatableOverlay from './RotatableOverlay';
import IconMarker from './IconMarker';
import { green } from '../../themes/style';
import './TrackingMap.css';

const blue = "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
const yellow = "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"


function gatewayInfoContent(id, name, MAC) {
    if(MAC)
        return '<p><b>Name: </b>' + name +'</p> <p><b>ID: </b>' + id + '</p><b>MAC: </b>' + MAC
    else
        return '<p><b>Name: </b>' + name +'</p> <p><b>ID: </b>' + id + '</p>'

}

class TrackingMap extends Map {
    
    constructor(){
        super();
        this.overlay = null;
        this.beaconMarkers = [];
        this.currentBeacons = [];
        this.rnMarkers = [];
        this.gatewayMarkers = [];
        this.polygons = [];
        this.deviceVisible = false;
        this.zoneVisible = false;
    }

    // InitMap Override
    initMap = () => {
        this.map = new window.google.maps.Map(
            document.getElementById('googleMap'),
            {
              zoom: 19,
              center: this.props.mapCenter,
              streetViewControl: false,
            }
        )
        if(this.map){
            let div = document.createElement('div');
            div.style.background = '#fff';
            div.style.width = 'auto';
            div.style.padding = '10px';
            div.style.margin = '10px';
            div.style.border= '1px solid #000';
            
            let divDevices = document.createElement('div');
            let checkboxDevices = document.createElement('input');
            checkboxDevices.type = 'checkbox';
            checkboxDevices.onclick = this.toggleDevicesVisibility;
            let labelDevices = document.createElement('label');
            labelDevices.innerHTML = "Devices"
            labelDevices.className = "label"
            divDevices.appendChild(checkboxDevices)
            divDevices.appendChild(labelDevices)
            div.appendChild(divDevices)

            let divZones = document.createElement('div');
            let checkboxZones = document.createElement('input');
            checkboxZones.type = 'checkbox';
            checkboxZones.onclick = this.toggleZonesVisibility;
            let labelZones = document.createElement('label');
            labelZones.innerHTML = "Zones"
            labelZones.className = "label"
            divZones.appendChild(checkboxZones)
            divZones.appendChild(labelZones)
            div.appendChild(divZones)

            this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(div);

            new window.google.maps.event.addListenerOnce(this.map, 'idle', function(){
                if(this.props.onMapMounted)
                    this.props.onMapMounted()
            
            }.bind(this));       
        }
    }

    placeFloor = () => {
        if(this.props.floor && this.map){
            RotatableOverlay.prototype = new window.google.maps.OverlayView()

            if(this.props.floor.boundsNE && this.props.floor.boundsSW && !this.overlay){
                let ne = {lat: this.props.floor.boundsNE.lat, lng: this.props.floor.boundsNE.lng}
                let sw = {lat: this.props.floor.boundsSW.lat, lng: this.props.floor.boundsSW.lng}

                let imageBounds = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(sw.lat, sw.lng),
                    new window.google.maps.LatLng(ne.lat, ne.lng)
                )
                this.overlay = new RotatableOverlay(imageBounds, this.props.floor.planUrl, this.map, this.props.floor.rotation);
            }
        }
    }

    toggleDevicesVisibility = () => {
        this.deviceVisible = !this.deviceVisible
        this.purgeDevices();
        this.placeDevices();
    }

    toggleZonesVisibility = () => {
        this.zoneVisible = !this.zoneVisible
        this.purgeZones();
        this.placeZones();
    }

    purgeOverlay = () => {
        if(this.overlay){
            this.overlay.setMap(null);
            this.overlay = null;
        }
    };

    purgeBeacons = () => {
        while(this.beaconMarkers[0]){
            this.beaconMarkers.pop().setMap(null)
        }    
    }

    placeOrUpdateBeacons = () => {
        this.props.bea.forEach(beacon => {
            let placed = false
            if(beacon){
                this.beaconMarkers.forEach(marker => {
                    if(marker.get('id') === beacon.id){
                        marker.setPosition({ lat: beacon.attributes.latitude, lng: beacon.attributes.longitude })
                        placed = true
                    }
                })
                if(!placed){
                    // NEWBLOCK
                    if(beacon.attributes.iconUrl){
                        IconMarker.prototype = new window.google.maps.OverlayView()
                        // If we create a new ProtlessComponent we will recreate it with default marker and
                        let marker = new IconMarker(
                            new window.google.maps.LatLng(
                                beacon.attributes.latitude,
                                beacon.attributes.longitude
                            ),
                            beacon.attributes.iconUrl,
                            this.map,
                            new window.google.maps.Size(0, -50),
                            beacon.attributes.MAC
                        )
                        marker.set('id', beacon.id)
                        this.beaconMarkers.push(marker)
                    }
                    // END NEWBLOCK
                    else{
                        let content = '<p><b>ID : </b>' + beacon.id + ' <b>MAC : </b>' + beacon.attributes.MAC +' <b>Name : </b>' + beacon.attributes.name + '</p>'
                        let infowindow = new window.google.maps.InfoWindow({
                            content: content
                        })

                        let marker =  new window.google.maps.Marker({
                            position: { lat: beacon.attributes.latitude, lng: beacon.attributes.longitude },
                            map: this.map
                        })

                        marker.set('id', beacon.id)
                        marker.addListener('click', function() {
                            infowindow.open(this.map, marker);
                        })    
                        this.beaconMarkers.push(marker)
                    }
                }
            }
        })
    }

    purgeDevices = () => {
        while(this.rnMarkers[0]){
            this.rnMarkers.pop().setMap(null)
        }    
        while(this.gatewayMarkers[0]){
            this.gatewayMarkers.pop().setMap(null)
        }    
    }

    placeDevices = () => {
        for(let i = 0; i < this.props.rn.length; i++){
                
            let content = gatewayInfoContent(this.props.rn[i].id, this.props.rn[i].attributes.name, this.props.rn[i].attributes.MAC)
            let infowindow = new window.google.maps.InfoWindow({
                content: content
            })
            
            let marker =  new window.google.maps.Marker({
                position: { lat: this.props.rn[i].attributes.latitude, lng: this.props.rn[i].attributes.longitude },
                map: this.map,
                visible: this.deviceVisible,
                icon: {url: blue}
            })

            marker.addListener('click', function() {
                infowindow.open(this.map, marker);
            })
            this.rnMarkers.push(marker)
        }

        for(let i = 0; i < this.props.gt.length; i++){
                
            let content = gatewayInfoContent(this.props.gt[i].id, this.props.gt[i].attributes.name, this.props.gt[i].attributes.MAC)
            
            let infowindow = new window.google.maps.InfoWindow({
                content: content
            })
        
            let marker =  new window.google.maps.Marker({
                position: { lat: this.props.gt[i].attributes.latitude, lng: this.props.gt[i].attributes.longitude },
                map: this.map,
                visible: this.deviceVisible,
                icon: {url: yellow}
            })

            marker.addListener('click', function() {
                infowindow.open(this.map, marker);
            })

            this.gatewayMarkers.push(marker)
        }
    }

    placeZones = () => {
        if(this.props.zones){
            for(let i = 0; i < this.props.zones.length; i++){
                if (this.props.zones[i].attributes.points.length > 3){
                    let array = [];
                    this.props.zones[i].attributes.points.map(point => 
                        array.push({
                           lat: point.latitude, lng: point.longitude
                        })
                    );
    
                    let polygon = new window.google.maps.Polygon({
                        visible: this.zoneVisible,
                        paths: array,
                        strokeColor: green,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: green,
                        fillOpacity: 0.35
                    });
                    polygon.setMap(this.map);
                    this.polygons.push(polygon)
                }
            }            
        }       
    };

    purgeZones = () =>{
        while(this.polygons[0]){
            this.polygons.pop().setMap(null)
        }
    };

    setMapZoom = (zoom) => {
        if(this.map){
            this.map.setZoom(zoom)
        }
    }

    // Map componentbehaviour
    componentDidUpdate = () => {
        if(this.props.purgeOverlay){
            this.purgeOverlay();
            this.purgeZones();
        }
        this.placeFloor();
        if (this.props.purgeDevices){
            this.purgeDevices()  
        }
        if(this.props.mapCenter && this.map && this.props.mapSetCenter) // TODO Ustawienie bliżej ekranu 
            this.map.setCenter(this.props.mapCenter)
        if (this.props.purgeBeacons)
            this.purgeBeacons() 
        
        this.placeZones();
        this.placeDevices();
        this.placeOrUpdateBeacons();
    }
}

export default TrackingMap


