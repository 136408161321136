import React, { Component } from "react";
import AddIcon from '@material-ui/icons/Add';
import { Fab } from "@material-ui/core";
import { connect } from "react-redux";
import { isCorrectRole, fab } from "../../utils";

class AddFab extends Component {
    
    render(){
        const isAllowed = isCorrectRole(this.props.role);
        return(
            isAllowed ?
            <Fab style={fab} onClick={this.props.onClick}>
                <AddIcon />
            </Fab> : <div/>

        );
    };
};

const mapStateToProps = (state) => {
    return {
        role: state.projects.currentRole
    }
};

export default connect(mapStateToProps)(AddFab);