const en = {
    "location": "location",
    "location_plural": "locations",
    "gateway": "gateway" ,
    "gateway_plural": "gateways",
    "beacon": "beacon",
    "beacon_plural": "beacons",
    "refNode": "reference node",
    "refNode_plural": "reference nodes",
    "template": "template",
    "template_plural": "templates",
    "zone": "zone",
    "zone_plural": "zones",
    "policy": "policy",
    "policy_plural": "policies",
    "common": {
        "Welcome to React": "Welcome to React and react-i18next",
        "title": "MPS",
        "logout": "Logout",
        "saveButton": "SAVE",
        "cancelButton": "CANCEL",
        "deleteButton": "DELETE",
        "warrning": "Warrning",
        "deletePrompt": "Are you sure you want to delete this item?",
        "deleteProjectPrompt": "Removing a project will remove all associated objects and devices. Are you sure you want to delete it?",
        "disconnectProjectPrompt": "Are you sure you want to disconnect from a project?",
        "removeTemplateWarning": "Removing a template will also remove all associated devices. Are you sure you want to delete it?",
        "removeLocationWarning": "Removing location will also remove all buildings, floord and devices in it. Are you sure you want to delete it?",
        "removeBuildingFloorWarning": "Removing floors or buildings will also remove all devices placed inside. Are you sure you want to delete it?",
        "rpp": "Rows per page"
    },
    "sidebar": {
        "Dashboard": "Dashboard",
        "Locations": "Locations",
        "Gateways": "Gateways",
        "Beacons": "Beacons",
        "Nodes": "Nodes",
        "Templates": "Templates",
        "Tracking": "Tracking",
        "Zones": "Zones",
        "Policies": "Policies",
        "Settings": "Settings",
        "TrackingHistory": "History"
     
    },
    "settings":{
        "projects":{
            "projects": "Projects",
            "projectName": "Project Name",
        },
        "role":{
            "role": "Role",
            "regularUser": "Regular user",
            "admin": "Admin"
        },
        "personal":{
            "personal": "Personal",
            "language": "Language",
            "username": "Username",
            "changePassword": "Change password",
            "password": "Password",
            "oldPassword": "Old password",
            "newPassword": "New password",
            "rePassword": "Retype password"
        },
        "firmwareVersion":{
            "firmwareVersion": "Firmware version"
        },
        "errors":{
            "errorMsg": "New password does not match the retyped password."
        }
    },
    "createProject":{
        "projName": "Project name",
        "projDescription": "Project description",
        "locAlgorithm": "Localization algorithm",
        "addUser": "Add a user",
        "firmwareVersion": "Firmware version"
    },
    "algorithm":{
        "esparOnly": "Espar only",
        "refNodes": "Reference nodes"

    },
    "zones":{
        "title": "Zones",
        "name": "Name",
        "location": "Location",
        "type": "Type",
        "temperature": "Temperature"
    },
    "policies": {
        "title": "Policies",
        "name": "Name",
        "location": "Location",
        "type": "Type",
        "temperature": "Temperature",
        "above": "Above",
        "below": "Below",
        "enters": "Enters",
        "leaves": "Leaves",
        "sendEmail": "Send email",
        "soundAlert": "Sound alert",
        "celsius": "Celsius",
        "fahrenheit": "Fahrenheit",
        "warning": "Could not load proper data.",
        "addBeacon": "Add a beacon",
        "action": "Add an action",
        "config": "Configure",
        "webhook": {
            "newWebhook": "New WebHook",
            "contType": "Content-type",
            "addHeader": "Add a header",
            "header": "Header",
            "headerText": "Header text"
        }
    },
    "beacons": {
        "title": "Beacons",
        "empty": "No Beacons available",
        "name": "Name",
        "mac": "MAC",
        "macInfo": "MAC must be 12 letters long",
        "configurationTemplate": "Beacon template",
        "icon": "Icon",
        "uploadBeaconButton": "UPLOAD",
        "battery": "Battery"
    },
    "dashboard": {
        "title": "Dashboard",
        "prompt": "To proceed with flow create or select your project in Settings",
        "slogan": "Making indoor positioning simple"
    },
    "gateways": {
        "title": "Gateway",
        "empty": "No Gateways available",
        "name": "Name",
        "location": "Location",
        "configurationTemplate": "Configuration template",
        "building": "Building",
        "floor": "Floor",
        "bleFirmware": "BLE firmware",
        "firmware": "Firmware",
        "status": "Status",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "updateWithMac": "Upload Mac Filters To Gateway's"
    },
    "locations": {
        "title": "Locations",
        "empty": "No Locations available",
        "name": "Name",
        "address": "Address",
        "description": "Description",
        "newBuildingButton": "Add a building",
        "uploadPlanButton": "UPLOAD",
        "newFloorTitle": "New Floor for ",
        "updateFloorTitle": "Update Floor for ",
        "floorNr": "Floor nr",
        "floorPlan": "Floor Plan",
        "floors": "Floors",
        "devices": "Devices"
    },
    "login": {
        "title": "MPS",
        "prompt": "Sign in to get started",
        "username": "Username",
        "password": "Password",
        "signIn": "SIGN IN",
        "signUp": "Sign up",
        "forgotPassword": "Forgot your password",
        "noAccount": "Don't have an account"
    },
    "register": {
        "userName": "User Name",
        "emailAdress": "Email Adress",
        "register": "register",
        "errors": {
            "fiveChars": "At least 5 characters",
            "emailFormula": "Missing @ in emial adress"
        }
    },

    "referenceNodes": {
        "title": "Reference Nodes",
        "empty": "No Reference Nodes available",
        "name": "Name",
        "mac": "MAC",
        "macInfo": "MAC must be 12 letters long",
        "location": "Location",
        "configurationTemplate": "Configuration template",
    },

    "templates": {
        "title": "Templates",
        "empty": "No Templates available",
        "type": "Type",
        "name": "Name",
        "securityType": "Security type",
        "ssid": "SSID",
        "password": "Password",
        "interval": "Interval (ms)",
        "gatewayType": "Gateway",
        "beaconType": "Beacon",
        "defaultIcon": "Default Icon",
        "beaconTemplateName": "Beacon type",
        "referenceNodeType": "Reference Node"
    },
    "tracking": {
        "floors": "Floors",
        "singleFilter": "Find single beacon",
        "allFilter": "Find beacons by type",
        "clearFilter": "Clear filter",
        "success": "Succesfull connection to WebSocket server",
        "disconnect": "Disconnected from WebSocket server",
        "warrning": "Could not connect to WebSocket server",
        "error": "An error occured during the connection ",
        "beacon": "Beacon name"
    },
    "snackbar": {
        "imageTooLarge": "Image is too large should be below 100kb",
        // Locations
        "locationFetchError": "Could not load locations please try again later",
        "locationFetchSingleError": "Could not load chosen location",
        "locationPostSuccess": "New location has been created",
        "locationPostError": "Location creation has failed",
        "locationDeleteSuccess": "Location was successfully deleted",
        "locationDeleteError": "Location could not be deleted",
        "locationPutSuccess": "Location was updated",
        "locationPutError": "Location could not be updated",
        // Gateways
        "gatewayFetchError": "Could not load chosen gateway",
        "gatewaysFetchError": "Could not load gateways please try again later",
        "gatewaysTemFetchError": "Could not load gateway templates please try again later",
        "gatewaysPostSuccess": "New gateway has been created",
        "gatewaysPostError": "Gateway creation has failed",
        "gatewayDeleteSuccess": "Gateway was successfully deleted",
        "gatewayDeleteError": "Gateway could not be deleted",
        "gatewayPatchSuccess": "Gateway was updated",
        "gatewayPatchError": "Gateway update has failed",
        "gatewaysUploadMacSuccess": "Gateways were updated with MAC filters",
        "gatewaysUploadMacError": "Could not update gateways with MAC filters",
        // GT
        "gatewaysTemPostSuccess": "New gateway template has been created",
        "gatewaysTemPostError":  "Gateway template creation has failed",
        "gatewaysTemPatchSuccess": "Gateway template was updated",
        "gatewaysTemPatchError": "Gateway template update has failed",
        "gatewaysTemDeleteSuccess": "Gateway template was successfully deleted",
        "gatewaysTemDeleteError": "Gateway template could not be deleted",
        "gatewayTemFetchError": "Could not load chosen gateway template",
        // Beacons
        "beaconsFetchError": "Could not load beacons please try again later",
        "beaconFetchError": "Could not load chosen beacon",
        "beaconsTemFetchError": "Could not load beacon templates please try again later",
        "beaconsPostSuccess": "New beacon has been created",
        "beaconsPostError":  "Beacon creation has failed",
        "beaconsDeleteSuccess": "Beacon was successfully deleted",
        "beaconsDeleteError": "Beacon could not be deleted",
        "beaconsPatchSuccess": "Beacon was updated",
        "beaconsPatchError": "Beacon update has failed",
        // BT
        "beaconTemPostSuccess":  "New beacon template has been created",
        "beaconTemPostError": "Beacon template creation has failed",
        "beaconTemPatchSuccess": "Beacon template was updated",
        "beaconTemPatchError": "Beacon template update has failed",
        "beaconTemDeleteSuccess": "Beacon template was successfully deleted",
        "beaconTemDeleteError": "Beacon template could not be deleted",
        "beaconTemFetchError": "Could not load chosen beacon template",
        // Reference nodes
        "refNodesFetchErrors": "Could not load refernce nodes please try again later",
        "refNodeFetchErrors": "Could not load chosen reference node",
        "refNodesTemFetchErrors":"Could not load node templates please try again later",
        "refNodesPostSuccess": "New reference node has been created",
        "refNodesPostErrors": "Reference node creation has failed",
        "refNodesDeleteSuccess": "Reference node was successfully deleted",
        "refNodesDeleteErrors": "Reference node could not be deleted",
        "refNodesPatchSuccess": "Reference node was updated",
        "refNodesPatchErrors": "Reference node update has failed",
        // RNT
        "refNodeTemPostSuccess": "New reference node template has been created",
        "refNodeTemPostError": "Reference node template creation has failed",
        "refNodeTemPatchSuccess": "Reference node template was updated",
        "refNodeTemPatchError": "Reference node template update has failed",
        "refNodeTemDeleteSuccess": "Reference node template was successfully deleted",
        "refNodeTemDeleteError": "Reference node template could not be deleted",
        "refNodeTemFetchError": "Could not load chosen reference node template",
        // Zones
        "zonesFetchError": "Could not load zones please try again later",
        "zoneFetchError":  "Could not load chosen zone",
        "zonesPostSuccess": "New zone has been created",
        "zonesPostError": "Zone creation has failed",
        "zoneDeleteSuccess":"Zone was successfully deleted",
        "zoneDeleteError": "Zone could not be deleted",
        "zonePatchSuccess": "Zone was updated",
        "zonePatchError": "Zone update has failed",
        // Policies
        "policiesFetchError": "Could not load policies please try again later",
        "policyFetchError":  "Could not load chosen policy",
        "policyPostSuccess": "New policy has been created",
        "policyPostError": "Policy creation has failed",
        "policyDeleteSuccess": "Policy was successfully deleted",
        "policyDeleteError": "Policy could not be deleted",
        "policyPatchSuccess": "Policy was updated",
        "policyPatchError": "Policy update has failed",
        // Projects
        "selectActiveProject": "New project was selected",
        "projectsFetchError": "Could not load user projects please try again later",
        "projectFetchError":  "Could not load chosen project",
        "projectPostSuccess": "New project has been created",
        "projectPostError": "Project creation has failed",
        "projectDeleteSuccess": "You have been removed from the project",
        "projectDeleteError": "Project could not be deleted",
        "projectPatchSuccess": "Project was updated",
        "projectPatchError": "Project update has failed",
        // Login Patch
        "loginConnection": "Connection Error",
        "updateUserPassword": "Updated user password",
        "updateUserPasswordError": "Password update failed"
    },
    "refresh": {
        "token": "REFRESH",
        "header": "Your session will soon expire",
        "flavorText": "Do you wish to refresh your session?"
    }
};

export default en;