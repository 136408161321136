import React, { Component } from "react";
import { Grid, Typography, IconButton, MuiThemeProvider, TextField } from "@material-ui/core";
import axios from "axios";
import CancelButton from "../Gui/CancelButton";
import SaveButton from "../Gui/SaveButton";
import Selector from "../Gui/Selector";
import GavelIcon from '@material-ui/icons/Gavel';
import SettingsIcon from '@material-ui/icons/Settings'
import BeaconsActionsAndRules from "./BeaconsActionsAndRules";
import { fetchZones, fetchBeacons, patchPolicy, fetchPolicies } from "../../actions/apiCalls";
import { connect } from "react-redux";
import { IstokTheme } from "../../themes/style";
import { withTranslation } from 'react-i18next';

class EditPolicy extends Component{

    constructor(){
        super();
        this.state = {
            name: "",
            type: "",
            trigger: "",
            zoneId: "",
            value: 0,
            unit: "",
            chosenBeacons: [""],
            actions: [{actionType: "", reqData: ""}],
            mounted: false
        }
    };

    
    componentDidMount = () => {
        if(this.props.projectId){
            this.props.fetchProjectZones(this.props.projectId)
            this.props.fetchProjectBeacons(this.props.projectId)
            let url = process.env.REACT_APP_API_URL+'projects/'+this.props.projectId+'/policies/'+this.props.policyId
            axios.get(url).then(res => {
                let data = res.data.data[0]
                if(data.attributes.type === "geofencing"){
                    this.setState({
                        name: data.attributes.name,
                        type: data.attributes.type,
                        trigger: data.attributes.trigger,
                        chosenBeacons: data.attributes.beacons,
                        zoneId: data.attributes.zoneId,
                        actions: data.attributes.actions,
                        mounted: true
                    })
                }
                else{
                    this.setState({
                        name: data.attributes.name,
                        type: data.attributes.type,
                        trigger: data.attributes.trigger,
                        unit: data.attributes.unit,
                        value: data.attributes.value,
                        chosenBeacons: data.attributes.beacons,
                        actions: data.attributes.actions,
                        mounted: true
                    })
                }
                
            }).catch(e => {
                this.props.onFetchError(e);
                this.props.onClose();
            });
        };
    };

    onNameChange = (e) => {
        this.setState({name: e.target.value});
    };

    onTypeChange = (e) => {
        this.setState({type: e.target.value, trigger: ""});
    };

    onZoneChange = (e) => {
        this.setState({zoneId: e.target.value});
    };

    onTriggerChange = (e) => {
        this.setState({trigger: e.target.value});
    };

    onUnitChange = (e) => {
        this.setState({unit: e.target.value});
    }
    
    onBeaconChange = (e, i) => {
        let newBeacons = [...this.state.chosenBeacons];
        newBeacons[i] = e.target.value;
        this.setState({chosenBeacons: newBeacons});
    };

    onActionChange = (e, i) => {
        let newActions = [...this.state.actions];
        newActions[i].actionType = e.target.value;
        newActions[i].reqData = "";
        this.setState({actions: newActions});
    }

    addNewBeacon = () => {
        this.setState({chosenBeacons: [...this.state.chosenBeacons, ""]})
    };  

    onValueChange = (e) => {
        this.setState({value: e.target.value});
    }

    addNewAction = () => {
        this.setState({actions: [...this.state.actions, {actionType: "", reqData: ""}]})
    }

    onEmailChange = (e, i) => {
        let newActions = [...this.state.actions];
        newActions[i].reqData = e.target.value;
        this.setState({actions: newActions});
    }

    onWebhookChange = (i, data) => {
        let newActions = [...this.state.actions];
        newActions[i].reqData = data;
        this.setState({actions: newActions});
    }


    clearBeacon = (e, i) => {
        let newBeacons = [...this.state.chosenBeacons];
        newBeacons.splice(i, 1);
        this.setState({chosenBeacons: newBeacons});
    }

    clearAction = (e, i) => {
        let newActions = [...this.state.actions];
        newActions.splice(i, 1);
        this.setState({actions: newActions});
    }

    isPolicyValid = () => {
        if(this.state.name === ""){
            return true;
        }
        if(this.state.type === ""){
            return true
        }
        let emptyFlag = false
        this.state.chosenBeacons.forEach(beacon => {
            if(beacon === "")
                emptyFlag = true;
        })
        if(emptyFlag)
            return true;
        
        if(this.state.type === "geofencing"){
            if(this.state.zoneId === "" || this.state.trigger === "")
                return true;
        }   
        else{
            if (this.state.trigger === "" || this.state.unit === "")
                return true;
            try{
                if(!Number.isInteger(Number.parseInt(this.state.value))){
                    return true;
                }
            }
            catch(e){
                return true;
            }
        }
        
        emptyFlag = false
        this.state.actions.forEach(action => {
            if((action.actionType === "" || action.reqData === "") && action.actionType !== "soundAlert")
                emptyFlag = true;
        })
        if(emptyFlag)
            return true;

        return false;
    }

    submitPolicy = () => {
        let data = {
            name: this.state.name,
            type: this.state.type,
            beacons: this.state.chosenBeacons,
            trigger: this.state.trigger,
            zoneId: this.state.zoneId,
            unit: this.state.unit,
            value: this.state.value,
            actions: this.state.actions
        }

        if(this.props.projectId && this.props.policyId){
            this.props.patchPolicy(this.props.projectId, this.props.policyId, data)
            this.props.onClose();
        }
    }

    render(){
        const { t } = this.props
        const typesData = [
            {id:"geofencing", attributes:{name: "Geofence"}},
            {id:"temperature", attributes:{name: t('policies.temperature')}}
        ];
        const tempTriggerData = [
            {id:"Above", attributes:{name: t("policies.above")}},
            {id:"Below", attributes:{name: t("policies.below")}}
        ];
        const geoTriggerData = [
            {id:"Enters", attributes:{name: t("policies.enters")}},
            {id:"Leaves", attributes:{name: t("policies.leaves")}}
        ];
        const actionData = [
            {id:"sendEmail", attributes:{name: t("policies.sendEmail")}},
            // {id:"sendNotification", attributes:{name: "Send notification"}},
            {id:"webhook", attributes:{name: "Webhook"}},
            {id:"soundAlert", attributes:{name: t("policies.soundAlert")}},
        ];
        const unitData = [
            {id:"Celsius", attributes:{name: t("policies.celsius")}},
            {id:"Fahrenheit", attributes:{name: t("policies.fahrenheit")}}
        ]


        return(
            this.state.mounted ? (
                <form onSubmit={e => e.preventDefault()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                                <Grid item>
                                    <CancelButton onClick={this.props.onClose}/>
                                </Grid>
                                <Grid item>
                                    <SaveButton disabled={this.isPolicyValid()} onClick={this.submitPolicy} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <IconButton style={{pointerEvents: 'none'}}>
                                        <GavelIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiThemeProvider theme={IstokTheme}>
                                        <TextField onChange={this.onNameChange} value={this.state.name} label={"Name"} variant="outlined" fullWidth/>
                                    </MuiThemeProvider>
                                </Grid>
                                <Grid item xs={5}/>
                                <Grid item xs={1}>
                                    <IconButton style={{pointerEvents: 'none'}}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <Selector value={this.state.type} onChange={this.onTypeChange} data={typesData} labelWidth={40} label={"Type"} />
                                </Grid>
                                <Grid item xs={5}/>
                            </Grid>
                                {/* Type */ }
                        </Grid>
                        <Grid item xs={12}>
                            {this.state.type !== "" ? (
                                  <BeaconsActionsAndRules
                                    type={this.state.type}

                                    trigger={this.state.trigger}
                                    tempTriggerData={tempTriggerData} 
                                    geoTriggerData={geoTriggerData}
                                    onTriggerChange={this.onTriggerChange}

                                    zoneId={this.state.zoneId}
                                    zones={this.props.zones}
                                    onZoneChange={this.onZoneChange}

                                    beacons={this.props.beacons}
                                    chosenBeacons={this.state.chosenBeacons}
                                    onBeaconChange={this.onBeaconChange}
                                    addNewBeacon={this.addNewBeacon}
                                    clearBeacon={this.clearBeacon}
                                    
                                    actionData={actionData}
                                    actions={this.state.actions}
                                    onActionChange={this.onActionChange}
                                    addNewAction={this.addNewAction}
                                    clearAction={this.clearAction}

                                    unitData={unitData}
                                    unit={this.state.unit}
                                    onUnitChange={this.onUnitChange}
                                    onValueChange={this.onValueChange}
                                    value={this.state.value}

                                    onEmailChange={this.onEmailChange}
                                    onWebhookChange={this.onWebhookChange}/>
                            ): (<div />)}         
                        </Grid>
                    </Grid>
                </form>
            ) : (
                <form onSubmit={e => e.preventDefault()}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Could not load proper data.</Typography>
                            <Grid container alignItems="flex-start" justify="flex-end" spacing={2}>
                                <Grid item>
                                    <CancelButton onClick={this.props.onClose}/>
                                </Grid>
                                <Grid item>
                                    <SaveButton disabled={true} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )
        );
    };
};

const mapStateToProps = (state) => {
    return {
        projectId: state.projects.currentProjectId,
        zones: state.zones.zones,
        beacons: state.beacons.beacons,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjectZones(projectId) {
            dispatch(fetchZones(projectId));
        },
        fetchProjectBeacons(projectId) {
            dispatch(fetchBeacons(projectId));
        },
        onFetchError(e){
			dispatch({type: 'FETCH_POLICY_FAILURE'});
        },
        patchPolicy(projectId, policyId, data){
            dispatch(patchPolicy(projectId, policyId, data)).then(() => dispatch(fetchPolicies(projectId)));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditPolicy));