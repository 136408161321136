import React, { Component } from "react";
import { Select, InputLabel, MenuItem, FormControl, Grid } from "@material-ui/core";
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IstokTheme } from '../../themes/style'

class OutlinedSelect extends Component{
    render(){
        return(
            <Grid item xs={12}>
                <MuiThemeProvider theme={IstokTheme}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="outlined-label">{this.props.label}</InputLabel>
                                <Select labelId="outlined-label" id="outlined-select"
                                    value={this.props.value} onChange={this.props.onChange} label={this.props.label}>
                                    
                                    {this.props.values ? (
                                        this.props.data.map(item => 
                                            <MenuItem key={item.id} disabled={this.props.values.includes(item.id)} value={item.id}>{item.attributes.name}</MenuItem>
                                        )
                                    ):
                                    (
                                        this.props.data.map(item => 
                                            <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Grid>
        );
    };
};

export default OutlinedSelect;